import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import "./create-type-page-style.css";
import { withRouter } from "react-router-dom";
import { FaCodeBranch } from "react-icons/fa";
import {
  createCodeType,
  updateCodeType,
  getCodeTypeId,
} from "../../server/getCodeTypesCall/code-type-call";

class CreateTypePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: "",
      id: "",
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get("id");
    if (id) {
      this.getCodeType(id);
      this.setState({
        id,
      });
    }
  }

  getCodeType = async (id) => {
    this.setState({
      loading: true,
    });
    try {
      const res = await getCodeTypeId(id);
      const data = res.data;
      this.setState({
        loading: false,
        name: data.Name,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  onSubmit = () => {
    if (!this.state.name) {
      alert("Error: Code type is required./El tipo de código es requerido.");
      return;
    }
    if (this.state.id) {
      this.updateCodeType();
    } else {
      this.createCodeType();
    }
  };

  createCodeType = async () => {
    this.setState({ loading: true });
    try {
      const response = await createCodeType({ Name: this.state.name });
      if (response.status === 201 || response.status === 200) {
        alert(
          "Code type successfully created./Tipo de código creado satisfactoriamente."
        );
        this.props.history.push("/view-types");
      }
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  updateCodeType = async () => {
    this.setState({ loading: true });
    try {
      const response = await updateCodeType({
        Id: this.state.id,
        Name: this.state.name,
      });
      if (response.status === 201 || response.status === 200) {
        alert(
          "Code type successfully updated./Tipo de código actualizado satisfactoriamente."
        );
        this.props.history.push("/view-types");
      }
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  handleInputChange = (event) => {
    this.setState({ name: event.target.value });
  };

  render() {
    return (
      <Container fluid className="card-alt">
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h3>
              <FaCodeBranch />
              <FormattedMessage id="type-code" />
            </h3>
            {this.state.id ? (
              <Button
                variant="success"
                onClick={this.onSubmit}
                disabled={this.state.loading}
              >
                <FormattedMessage id="codetype-button-upload" />
              </Button>
            ) : (
              <Button
                variant="success"
                onClick={this.onSubmit}
                disabled={this.state.loading}
              >
                <FormattedMessage id="codetype-button-create" />
              </Button>
            )}
          </div>
          <div style={{ padding: "10px" }}>
            <div className="card-alt-2">
              <Form>
                <Form.Group>
                  <Row>
                    <Col xs={3} md={2}>
                      <b>
                        <span>
                          <FormattedMessage id="lev-type" />
                        </span>
                      </b>
                    </Col>
                    <Col xs={9} md={10}>
                      <Form.Control
                        type="text"
                        onChange={this.handleInputChange}
                        name="name"
                        value={this.state.name}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
              <p className="text-right">
                <small class="text-muted form-text">
                  <FormattedMessage id="ct-type-instructions" />
                </small>
              </p>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(CreateTypePage);
