import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Form from 'react-bootstrap/Form';
import {Col, Row} from "react-bootstrap";

class ValidatedTextField extends Component{
  constructor(props){
    super(props);
    this.state= {
      validated: this.props.isValidated,
    };
    this.onTextChange = this.onTextChange.bind(this);

  }

  onTextChange(event){
    //getting field name and value
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    
    this.props.onChange(fieldName, fieldValue);
  }

  render() {
    const { textType, textName, labelID, index, content, limitFromRules} = this.props;
    return(
      <Row>
          <Col xs={3} md={2}>
        {
          labelID ? <b><FormattedMessage id={labelID}/> {index >= 0 ? index + 1 : ""}</b> : ''
        }
        </Col>
          <Col xs={9} md={10}>
        <Form validated={this.props.isValidated}>
          <Form.Group controlId={"validation" + textName} >
            <Form.Control
              required
              type={textType} 
              name={textName} 
              onChange={this.onTextChange}
              value={this.props.content}
              maxLength={typeof limitFromRules != 'undefined' && limitFromRules !== null ? limitFromRules : ""}
            />
            {
              limitFromRules ? <small style={content ? content.length > limitFromRules ? {color: "red"} : {} : {}}>{content ? content.length : 0} / {limitFromRules}</small> : ""
            }
          </Form.Group>
        </Form>

        </Col>
      </Row>
    );
  }
}

export default ValidatedTextField;