import axios from "../interceptors";
import { apiGetCommentUsers } from "../../config";

export const getCommentUsers = async function (lang) {
  try {
    const response = await axios.get(apiGetCommentUsers, {
      headers: {
        "Content-Type": "application/json",        
      },
    });
    //This response just returns the rules
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      alert("Something went wrong with getting the file upload rules.");
    }
  } catch (error) {
    console.log(error.response.data.Messages);
  }
};
