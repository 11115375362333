/** src/index.js
 *  This is the main entry point for all views
 *  The file that handles the content being shown is ./routes/AppRouter.js
 *  The ./routes directory holds all of the page routes in different files, 
 *  and will display a page/view component for each route. * 
 */

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppRouter from './routes/AppRouter';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <AppRouter /> ,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
