import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import axios from "../../server/interceptors";
import { onRulesCall } from "../../server/getFileUploadRulesCall/file-upload-rules-call";
import {
  apiLessonGetContent,
  apiLessonUpdate,
  apiGetPdfLink,
  DEFAULT_LANGUAGE,
} from "../../config";
import { getLessonRules } from "../../server/getLessonRulesCall/get-lesson-rules";
import LessonStatusToggle from "../../components/lessonStatusToggle";
import LessonDetailsDisplay from "../../components/lessonDetailsDisplay";
import LessonHTMLViewer from "../../components/lessonHTMLViewer";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSave } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  getDescContent,
  getDescAndNameContent,
  getTaxVerbIds,
  getUnityIds,
  setUnityIds,
  setTaxVerbIds,
} from "./utils";
import { Dropdown } from "react-bootstrap";
import { FaPrint } from "react-icons/fa";
import SessionManager from "../../utils/authHandling/session-manager";
import "./style.css";

class ViewLessonPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingLessonData: true,
      loading: false,
      lessonConcept: "",
      lessonDefinitions: "",
      lessonDescription: "",
      lessonExample: "",
      lessonGrade: "",
      lessonId: "",
      lessonNo: "",
      lessonObjectives: "",
      lessonQuizes: [],
      lessonStatus: "",
      lessonStrategies: "",
      lessonSubject: "",
      lessonTasks: "",
      lessonTaxonomies: [],
      lessonTitle: "",
      lessonTransversalThemes: "",
      lessonUnit: [],
      previewId: "",
      lessonTemplates: [],
      ConstructedCodes: [],
    };
    this.onChange = this.onChange.bind(this);
    this.updateLesson = this.updateLesson.bind(this);
    this.openWindowWithPDF = this.openWindowWithPDF.bind(this);
    this.removePreviewId = this.removePreviewId.bind(this);
    this.superCompareLesson = this.superCompareLesson.bind(this);
  }

  constructedCodesChange = (constructedCodes) => {
    const data = constructedCodes.map((item) => {
      return {
        Id: item.Id,
        MainCode: item.MainCode,
        ConstructedCodeCodeModels: item.ConstructedCodeCodeModels,
      };
    });
    this.setState({ ConstructedCodes: data });
  };

  onLessonCall = () => {
    let self = this;
    axios
      .post(
        apiLessonGetContent,
        {
          LessonId: sessionStorage.lastSearch,
          IsForOutsideSource: true,
        },
        {
          headers: {
            "Content-Type": "application/json",            
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          let lessonData = response.data.LessonModifierRequestModel;
          let quizes = [];
          let exams = [];
          let preExams = [];
          let postExams = [];
          console.log(lessonData.ConstructedCodeHandleModelList);
          lessonData.QuizRequestModelList.forEach((element) => {
            if (element.QuizModel.IsExam === "0") {
              quizes.push(element);
            } else if (element.QuizModel.IsExam === "1") {
              exams.push(element);
            } else if (element.QuizModel.IsExam === "2") {
              preExams.push(element);
            } else if (element.QuizModel.IsExam === "3") {
              postExams.push(element);
            }
          });
          self.setState(
            {
              loadingLessonData: false,
              loading: false,
              lesson: response,
              lessonAddress: lessonData.AddressToLessonModelList[0].AddressId,
              lessonStatus: lessonData.LessonStatusModelList[0].StatusId,
              lessonId: lessonData.LessonModel.Id,
              lessonTitle: lessonData.LessonModel.LessonTitle,
              lessonNo:
                lessonData.LessonModel.LessonNo == null
                  ? "0"
                  : lessonData.LessonModel.LessonNo,
              lessonDescription: lessonData.LessonDetailModel.Description,
              lessonSubject: lessonData.LessonModel.SubjectCode,
              lessonGrade: lessonData.LessonModel.LevelCode,
              lessonConcept: lessonData.LessonDetailModel.Concept,
              lessonObjectives: lessonData.LessonObjectiveModelList
                ? getDescContent(lessonData.LessonObjectiveModelList)
                : [],
              lessonTasks: lessonData.LessonPerformanceTaskModelList
                ? getDescContent(lessonData.LessonPerformanceTaskModelList)
                : [],
              lessonStrategies: lessonData.LessonStrategyModelList
                ? getDescContent(lessonData.LessonStrategyModelList)
                : [],
              lessonTransversalThemes:
                lessonData.LessonTransversalThemeModelList
                  ? getDescContent(lessonData.LessonTransversalThemeModelList)
                  : [],
              lessonDefinitions: getDescAndNameContent(
                lessonData.LessonDefinitionModelList
              ),
              lessonExample: getDescAndNameContent(
                lessonData.LessonExampleModelList
              ),
              lessonQuizes: lessonData.QuizRequestModelList,
              lessonPractices: quizes,
              lessonExams: exams,
              lessonPreExams: preExams,
              lessonPostExams: postExams,
              lessonTaxonomies: lessonData.TaxonomyVerbsToLessonModelList
                ? getTaxVerbIds(lessonData.TaxonomyVerbsToLessonModelList)
                : [],
              lessonUnit: lessonData.LessonCurricularMapModelList
                ? getUnityIds(lessonData.LessonCurricularMapModelList)
                : [],
              lessonHasRecorder:
                lessonData.LessonModel.HasAudioRecorder === "1",
              lessonAudioSrc: lessonData.LessonIntroAudioModel
                ? lessonData.LessonIntroAudioModel.AudioSrc
                : "",
              lessonTemplates: lessonData.LessonTemplateModelList
                ? lessonData.LessonTemplateModelList
                : [],              
              ConstructedCodes: lessonData.ConstructedCodeHandleModelList,
            },
            function () {
              getLessonRules(self.state.lessonAddress).then((data) => {
                self.setState({ lessonRules: data });
              });
            }
          );
        }
      })
      .catch((error) => {
        console.log(error.response.data.Messages);
      });
  };

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  superCompareLesson() {
    this.setState({ loading: true }, function () {
      axios
        .post(
          apiLessonGetContent,
          {
            LessonId: sessionStorage.lastSearch,
            IsForOutsideSource: true,
          },
          {
            headers: {
              "Content-Type": "application/json",              
            },
          }
        )
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            let lessonData = response.data.LessonModifierRequestModel;
            let quizes = [];
            let exams = [];
            lessonData.QuizRequestModelList.forEach((element) => {
              if (element.QuizModel.IsExam === "0") {
                quizes.push(element);
              } else {
                exams.push(element);
              }
            });

            this.setState(
              {
                secondLessonCall: response.data,
              },
              () => {
                let oldLesson =
                  this.state.lesson.data.LessonModifierRequestModel;
                let newLesson =
                  this.state.secondLessonCall.LessonModifierRequestModel;
                let params = {
                  LessonModel: {
                    Id: this.state.lesson.data.LessonModifierRequestModel
                      .LessonModel.Id,
                    LessonNo:
                      oldLesson.LessonModel.LessonNo === this.state.lessonNo
                        ? newLesson.LessonModel.LessonNo
                        : this.state.lessonNo,
                    LevelCode:
                      oldLesson.LessonModel.LevelCode === this.state.lessonGrade
                        ? newLesson.LessonModel.LevelCode
                        : this.state.lessonGrade,
                    SubjectCode:
                      oldLesson.LessonModel.SubjectCode ===
                      this.state.lessonSubject
                        ? newLesson.LessonModel.SubjectCode
                        : this.state.lessonSubject,
                    LessonTitle:
                      oldLesson.LessonModel.LessonTitle ===
                      this.state.lessonTitle
                        ? newLesson.LessonModel.LessonTitle
                        : this.state.lessonTitle,
                    HasAudioRecorder: this.state.lessonHasRecorder ? "1" : "0",
                  },
                  LessonDefinitionModelList:
                    oldLesson.LessonDefinitionModelList ===
                    this.state.lessonDefinitions
                      ? newLesson.LessonDefinitionModelList
                      : this.state.lessonDefinitions,
                  LessonDetailModel: {
                    LessonId:
                      this.state.lesson.data.LessonModifierRequestModel
                        .LessonModel.Id,
                    KnowledgeLevel: "Norman Webb (1-4)",
                    Description:
                      oldLesson.LessonDetailModel.Description ===
                      this.state.lessonDescription
                        ? oldLesson.LessonDetailModel.Description
                        : this.state.lessonDescription,
                    Concept:
                      oldLesson.LessonDetailModel.Concept ===
                      this.state.lessonConcept
                        ? newLesson.LessonDetailModel.Concept
                        : this.state.lessonConcept,
                  },
                  LessonExampleModelList:
                    oldLesson.LessonExampleModelList ===
                    this.state.lessonExample
                      ? newLesson.LessonExampleModelList
                      : this.state.lessonExample,
                  LessonIntroAudioModel: {
                    LessonId:
                      this.state.lesson.data.LessonModifierRequestModel
                        .LessonModel.Id,
                    AudioSrc: oldLesson.LessonIntroAudioModel
                      ? oldLesson.LessonIntroAudioModel.AudioSrc ===
                        this.state.lessonAudioSrc
                        ? newLesson.LessonIntroAudioModel.AudioSrc
                        : this.state.lessonAudioSrc
                      : "",
                  },
                  LessonObjectiveModelList:
                    oldLesson.LessonObjectiveModelList ===
                    this.state.lessonObjectives
                      ? newLesson.LessonObjectiveModelList
                      : this.state.lessonObjectives,
                  LessonPerformanceTaskModelList:
                    oldLesson.LessonPerformanceTaskModelList ===
                    this.state.lessonTasks
                      ? newLesson.LessonObjectiveModelList
                      : this.state.lessonTasks,
                  LessonTransversalThemeModelList:
                    oldLesson.LessonTransversalThemeModelList ===
                    this.state.lessonTransversalThemes
                      ? newLesson.LessonTransversalThemeModelList
                      : this.state.lessonTransversalThemes,
                  ClassicFormat: false,
                  LessonStatusModelList: [
                    {
                      StatusId:
                        oldLesson.LessonStatusModelList[0].StatusId ===
                        this.state.lessonStatus
                          ? newLesson.LessonStatusModelList[0].StatusId
                          : this.state.lessonStatus,
                    },
                  ],
                  LessonStrategyModelList:
                    oldLesson.LessonStrategyModelList ===
                    this.state.lessonStrategies
                      ? newLesson.LessonStrategyModelList
                      : this.state.lessonStrategies,
                  TaxonomyVerbsToLessonModelList: setTaxVerbIds(
                    this.state.lessonTaxonomies
                  ),
                  LessonCurricularMapModelList: setUnityIds(
                    this.state.lessonUnit
                  ),
                  LessonTemplateModelList:
                    oldLesson.LessonTemplateModelList ===
                    this.state.lessonTemplates
                      ? newLesson.LessonTemplateModelList
                      : this.state.lessonTemplates,
                  ConstructedCodeHandleModelList: this.state.ConstructedCodes,
                };
                this.updateLesson(params);
              }
            );
          }
        })
        .catch((error) => {
          console.log(error.response.data.Messages);
        });
    });
  }

  updateLesson(lessson) {
    let self = this;
    this.setState({ loading: true }, function () {
      axios
        .post(apiLessonUpdate, JSON.stringify(lessson), {
          headers: {
            "Content-Type": "application/json",            
          },
        })
        .then(function (response) {
          if (response.status === 200 || response.status === 201) {
            alert(response.data.Messages);
            window.location.reload();
          } else {
            alert(response.data.Messages);
            self.setState({ loading: false });
          }
        })
        .catch((error) => {
          console.log(error.response.data.Messages);
          self.setState({ loading: false });
        });
    });
  }

  openWindowWithPDF() {
    axios
      .get(apiGetPdfLink + sessionStorage.lastSearch, {
        headers: {
          "Content-Type": "application/json",          
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          window.open(response.data, "_blank");
        } else {
          alert(response.data.Messages);
        }
      })
      .catch((error) => {
        console.log(error.response.data.Messages);
      });
  }

  componentDidMount() {
    this.onLessonCall();
    onRulesCall().then((data) => {
      this.setState({
        fileRules: data,
      });
    });
  }

  getAddressDisplay() {
    switch (this.state.lessonAddress) {
      case "1":
        return (
          <p>
            <FormattedMessage id="cl-addressDisplay" /> <b>VR</b>
          </p>
        );
      case "2":
        return (
          <p>
            <FormattedMessage id="cl-addressDisplay" /> <b>Genial Web</b>
          </p>
        );
      case "3":
        return (
          <p>
            <FormattedMessage id="cl-addressDisplay" /> <b>PDF</b>
          </p>
        );
      case "4":
        return (
          <p>
            <FormattedMessage id="cl-addressDisplay" /> <b>AR</b>
          </p>
        );
      default:
        return (
          <p>
            <FormattedMessage id="cl-addressDisplay" /> <b>Loading</b>
          </p>
        );
    }
  }

  removePreviewId() {
    this.setState({ previewId: null });
  }

  render() {
    return (
      <Container fluid className="card-alt">
        <div>
          <Row>
            <Col xs={12}>
              <h2>
                <FormattedMessage id="el-title" />
              </h2>
            </Col>
          </Row>
        </div>
        <Row className="RegionDesc">
          <Col xs={2}>
            {this.state.lessonId ? (
              <p>
                <FormattedMessage id="cl-lesson-id-display" />{" "}
                <b>{this.state.lessonId}</b>
              </p>
            ) : (
              ""
            )}
          </Col>
          <Col xs={10}>{this.getAddressDisplay()}</Col>
          <Col xs={12}>
            <small>
              <i>
                <FormattedMessage id="el-view-pdf-note" />
              </i>
            </small>{" "}
            <Button className="buttonPDF">
              <Link
                className="downloadPdfLink"
                key={0 + "_pdf"}
                to={{
                  pathname: "./pdf-viewer",
                  lessonId: sessionStorage.lastSearch,
                }}
              >
                <FaPrint /> <FormattedMessage id="el-view-pdf" />
              </Link>
            </Button>
          </Col>
        </Row>
        <div className="RegionStatus">
          <span style={{ textAlign: "end", marginRight: "10px" }}>
            <FormattedMessage id="ul-choose-lesson-status" />
          </span>
          <LessonStatusToggle
            currentStatus={this.state.lessonStatus}
            onChange={this.onChange}
            stateName="lessonStatus"
          />
        </div>
        {this.state.loading ? (
          <div style={{ textAlign: "Center", marginTop: "100px" }}>
            <Spinner animation="border" role="status" />
          </div>
        ) : (
          <LessonDetailsDisplay
            onDataChange={this.constructedCodesChange}
            language={this.props.language}
            lessonContent={this.state}
            onChange={this.onChange}
          />
        )}
        <div
          className="wrapper-button-flottingLesson"
          style={{
            bottom: "0",
            right: "0",
            position: "fixed",
            textAlign: "center",
            zIndex: "100",
          }}
        >
          <Dropdown drop="up">
            <Dropdown.Toggle bsPrefix="invisibleMenu">
              <FontAwesomeIcon
                icon={faEye}
                size="4x"
                color="#fff"
                style={{
                  backgroundColor: "#589199",
                  height: "40px",
                  width: "40px",
                  padding: "5px",
                  borderRadius: "100%",
                  margin: "10px",
                }}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.setState({ previewId: 0 })}>
                <FormattedMessage id="p-lesson" />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.setState({ previewId: 1 })}>
                <FormattedMessage id="p-concept" />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.setState({ previewId: 2 })}>
                <FormattedMessage id="p-definition" />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.setState({ previewId: 3 })}>
                <FormattedMessage id="p-example" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <p>
            <FormattedMessage id="preview" />
          </p>
          {this.state.loading ? (
            <FontAwesomeIcon
              icon={faSave}
              color="#fff"
              style={{
                backgroundColor: "#ccc",
                height: "40px",
                width: "40px",
                padding: "10px",
                borderRadius: "100%",
                margin: "10px",
              }}
            />
          ) : (
            <FontAwesomeIcon
              onClick={this.superCompareLesson}
              icon={faSave}
              color="#fff"
              style={{
                backgroundColor: "#4b9b61",
                height: "40px",
                width: "40px",
                padding: "10px",
                borderRadius: "100%",
                margin: "10px",
              }}
            />
          )}
          <p>
            <FormattedMessage id="p-save" />
          </p>
        </div>
        <LessonHTMLViewer
          contentId={this.state.previewId}
          lessonId={this.state.lessonId}
          language={this.props.language}
          removePreviewId={this.removePreviewId}
          savingLesson={this.state.loading}
        />
      </Container>
    );
  }
}

export default ViewLessonPage;
