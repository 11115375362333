import axios from "../interceptors";
import { apiGetLessonRulesUrl } from "../../config";
import { logoutUser } from "../../utils/authHandling/logout-user";

export const getLessonRules = async function (id) {
  try {
    const response = await axios.get(apiGetLessonRulesUrl + "/" + id, {
      headers: {
        "Content-Type": "application/json",        
      },
    });
    if (response.status === 401) {
      logoutUser();
    }
    return response.data.Rules[0];
  } catch (error) {
    console.log(error.response.data.Messages);
  }
};
