import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import {Row, Col, Button} from 'react-bootstrap';
import TextAreaInput from '../textAreaInput';

import './added-simple-field.css';
import {FaTrash} from 'react-icons/fa';

class AddedSimpleField extends Component{
  constructor(props){
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(value){
    this.props.onChange(this.props.index, {"Desc": value});
  }

  render(){
    return(
      <Row>
        <Col xs={8}>
        <TextAreaInput 
          labelID={this.props.labelID} 
          index={this.props.index}
          label={<FieldLabel labelId={this.props.labelID} index={this.props.index+1} />}
          textType="textarea"
          onChange={this.onChange}
          content={this.props.content}
          />
        </Col>
        <Col xs={4}>
            <br/>
        <div className="removeFieldButton">
          <Button variant="danger" onClick={() => this.props.removeField(this.props.index)}>
            <FaTrash/>{' '}
          <FormattedMessage id="remove"/></Button>
        </div>
        </Col>
      </Row>
    );
  }
}

class FieldLabel extends Component{
  render(){
    return(
      <div>
        <b><FormattedMessage id={this.props.labelId}/> {this.props.index}</b>
      </div>
    );
  }
}

export default AddedSimpleField;