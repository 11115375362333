import React, { Component } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { FormattedMessage } from 'react-intl';
import SessionManager from "../../utils/authHandling/session-manager";
import {apiAdditionalMaterialFileUploadUrl, DEFAULT_LANGUAGE} from '../../config';

//bootstrap
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';


class AdditionalMaterialUpload extends Component{
  constructor(props){
    super(props);
    this.state={
      imgSrc: "",
      uploadType: "image",
      reuploadDisplay: false,
      askForReplace: false,
      audioHeaders: []
    };
  }


  getUploadParams =  (headers) => {
    this.setState({dropzoneResponseObject: headers});
    let newState = this.state.audioHeaders;
    newState.push(headers);
    this.setState({audioHeaders: newState});
    return { url : apiAdditionalMaterialFileUploadUrl,
      headers: {
      'Token': SessionManager.getSession(),
        'Accept-Language': localStorage.getItem('i18nextLng') == null ? DEFAULT_LANGUAGE : localStorage.getItem('i18nextLng')
      }}
  };

  handleSubmit = (files, allFiles) => {
    let response = JSON.parse(this.state.dropzoneResponseObject.xhr.responseText);
    this.props.onChange("additionalMaterialURLSrc", response.Messages[0]);
    this.setState({reuploadDisplay: false});
    allFiles.forEach(f => f.remove())
  };

  render(){
    const baseStyle = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out'
    };
    return(
        <div>
          <hr/>
          <b>Material</b>
          {this.props.src ?
              <div>
                <Button
                    style={{marginTop: "10px"}}
                    onClick={() => {this.setState({reuploadDisplay: !this.state.reuploadDisplay, askForReplace: true})}}
                    aria-controls="dropzone-area"
                    aria-expanded={this.state.reuploadDisplay}
                >
                  <span><FormattedMessage id="cl-re-upload"/></span>
                </Button>
                <Collapse in={this.state.reuploadDisplay}>
                  <div id="dropzone-area">
                    {this.state.askForReplace ?
                        <div style={{textAlign: "center"}}>
                          <p><FormattedMessage id="cl-rewrite-warning"/></p>
                          <Button
                              onClick={() => {this.setState({askForReplace: false})}}
                              style={{margin: "5px"}}><FormattedMessage id="cl-yes"/></Button>
                          <Button
                              onClick={() => {this.setState({reuploadDisplay: !this.state.reuploadDisplay})}}
                              style={{margin: "5px"}}
                              aria-controls="dropzone-area"
                              aria-expanded={this.state.reuploadDisplay}><FormattedMessage id="cl-no"/></Button>
                        </div> :
                        <Dropzone
                            accept="image/png,image/jpeg,image/gif,application/msword,text/csv,video/x-msvideo,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/epub+zip,audio/midi,audio/mpeg,video/mpeg,audio/ogg,video/ogg,application/vnd.rar,application/rtf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip"
                            key="Dropzone View"
                            getUploadParams={this.getUploadParams}
                            // onChangeStatus={this.handleChangeStatus}
                            onSubmit={this.handleSubmit}
                            styles={{ dropzone: baseStyle }}
                            inputContent={"png, jpg, zip, word, mp4, ogg, pdfs, epubs"}
                        />
                    }
                  </div>
                </Collapse>
              </div> :
              <div>
                <Dropzone
                    accept="application/pdf,image/png,image/jpeg,image/gif,application/msword,text/csv,video/x-msvideo,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/epub+zip,audio/midi,audio/mpeg,video/mpeg,audio/ogg,video/ogg,application/vnd.rar,application/rtf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip"
                    key="Dropzone View"
                    getUploadParams={this.getUploadParams}
                    // onChangeStatus={this.handleChangeStatus}
                    onSubmit={this.handleSubmit}
                    styles={{ dropzone: baseStyle }}
                    inputContent={"png, jpg, zip, word, mp4, ogg, pdfs, epubs"}
                />
              </div>}
          <div>
          </div>
          <hr/>
        </div>
    );
  }
}

export default AdditionalMaterialUpload;