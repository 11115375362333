import axios from "../interceptors";
import { apiTemplateUrl } from "../../config";
import { logoutUser } from "../../utils/authHandling/logout-user";

export const onTemplateCall = async function (templateCode) {
  try {
    const response = await axios.get(apiTemplateUrl + "/" + templateCode, {
      headers: {
        "Content-Type": "application/json",        
      },
    });
    if (response.status === 200 || response.status === 201) {
      //Session and Transaction is valid, return the values
      return response.data.TemplateModelList;
    } else if (response.status === 401) {
      //Session is not valid, user is sent to login
      alert("Session is not valid");
      logoutUser();
    }
  } catch (error) {
    console.log(error.response.data.Messages);
  }
};
