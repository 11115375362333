import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from "../../server/interceptors";
import { apiRulesUrl, DEFAULT_LANGUAGE} from '../../config';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {FaTasks} from 'react-icons/fa'
import SessionManager from "../../utils/authHandling/session-manager";

class ViewRulesPage extends Component{

    constructor(props){
        super(props);
        this.state={
            loading: false,
            rules:[],
        };
        this.onRulesCall = this.onRulesCall.bind(this);
        this.bytesToSize = this.bytesToSize.bind(this);
    }

    bytesToSize(bytes) {
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    onRulesCall(){
        let self = this;
        this.setState({loading: true, downloadLink: null}, function(){

            axios.get(apiRulesUrl,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            ).then(function (response){
                let rules = [];
                for(let i =0; i < response.data.length;i++)
                {
                    if(response.data[i].Classification === "image") {
                        rules.push(
                            <li>{response.data[i].Format} {', '} {self.bytesToSize(response.data[i].MaxSize)}{' ('}{response.data[i].MaxWidth + 'px x ' + response.data[i].MaxHeight + 'px)'}</li>)
                    }
                    else
                    {
                        rules.push(
                            <li>{response.data[i].Format} {', '} {self.bytesToSize(response.data[i].MaxSize)}</li>)
                    }
                }

                self.setState({
                    loading: false,
                    rules: rules,
                });
            }).catch(error => {
                alert(error.response.statusText);
                this.setState({
                    loading: false,
                });
            });//Refreshing button statuses
        });
    }

    componentDidMount(){
        this.onRulesCall();
    }

    render(){
        return(
            <Container fluid className="card-alt">
                <Row>
                    <Col xs={12}>
                        <h3>
                            <FaTasks/>{' '}
                            <FormattedMessage id="rules"/>{' '}
                        </h3>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col xs={12}>
                        <b><FormattedMessage id="rule-section-1"/></b>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col xs={12}>
                        <ul>
                            <li><FormattedMessage id="rule-1-1"/></li>
                            <li><FormattedMessage id="rule-1-2"/></li>
                            <li><FormattedMessage id="rule-1-3"/></li>
                            <li><FormattedMessage id="rule-1-4"/></li>
                            <li><FormattedMessage id="rule-1-5"/></li>
                            <li><FormattedMessage id="rule-1-6"/></li>
                            <li><FormattedMessage id="rule-1-7"/></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <b><FormattedMessage id="rule-section-2"/></b>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col xs={12}>
                        <ul>
                            <li><FormattedMessage id="rule-2-1"/></li>
                            <li><FormattedMessage id="rule-2-2"/></li>
                            <li><FormattedMessage id="rule-2-3"/></li>
                            <li><FormattedMessage id="rule-2-4"/></li>
                            <li><FormattedMessage id="rule-2-5"/></li>
                            <li><FormattedMessage id="rule-2-6"/></li>
                            <li><FormattedMessage id="rule-2-7"/></li>
                            <li><FormattedMessage id="rule-2-8"/></li>
                        </ul>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <b><FormattedMessage id="rule-section-3"/></b>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col xs={12}>
                        <ul>
                            <li><FormattedMessage id="rule-3-1"/></li>
                            <li>
                                <FormattedMessage id="rule-3-2"/>
                                <ul>
                                    {this.state.rules}
                                </ul>
                            </li>
                            <li><FormattedMessage id="rule-3-3"/></li>
                            <li><FormattedMessage id="rule-3-4"/></li>
                            <li><FormattedMessage id="rule-3-5"/></li>
                            <li><FormattedMessage id="rule-3-6"/></li>
                            <li><FormattedMessage id="rule-3-7"/></li>
                            <li><FormattedMessage id="rule-3-8"/></li>
                        </ul>
                    </Col>
                </Row>

            </Container>
        );
    }
}

export default ViewRulesPage;