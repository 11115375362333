/**
 * Created by Pedro Ortiz
 * Feb 2021
 * This is based on Genial Web project.
 * Several changes were made to fit the original programmer style of this project.
 */
import React from 'react';
import Select from 'react-select';
import {BeatLoader} from "react-spinners";
import axios from "../../server/interceptors";
import {apiGetApiKeys, apiGetPermissions, DEFAULT_LANGUAGE} from "../../config";
import SessionManager from "../../utils/authHandling/session-manager";
import {logoutUser} from "../../utils/authHandling/logout-user";

/**
 * Handles the selection of api keys.
 */
class SelectorApiKeys extends React.Component{

    constructor(props)
    {
        // Super is always called when defining the constructor of a subclass
        // All React component classes that have a constructor, should start it with a super(props) call.
        super(props);
        this.state = {
            disabled: props.disabled,
            loading: false,
            options: [], // The options to fill the selection
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            resetBox: nextProps.resetBox,
        });

        if(nextProps.redoSearch)
        {
            this.callData();
        }
    }

    callData(){
        this.setState({
            loading: true,
        });
        let self = this;
        axios.get(apiGetApiKeys, {
            headers: {
                'Content-Type': 'application/json',
            }}).then(function(response){

            if(response.status === 401)
            {
                logoutUser();
            }
            let arrOptions = [];
            if(response.data !== null) {
                for(let i=0; i < response.data.length;i++) {
                    arrOptions.push({
                        value: response.data[i].Id,
                        label: `${response.data[i].PlatformDescription} ${response.data[i].Key}`,
                        platform: response.data[i].PlatformDescription,
                        key:response.data[i].Key,
                        active: response.data[i].Active,
                        version: response.data[i].Version,
                    });
                }
            }
            self.setState({
                options: arrOptions,
                loading: false,
            });
        }).catch(error => {
            alert(error);
        });
    }

    /**
     * Call the webservices for the users and fill those selects
     * Component did mount is required for selects with API calls.
     */
    componentDidMount() {
        this.callData();
    }

    /**
     * Handles the change event to get the selected value. Do not change this function
     */
    handleChange = (selectedOption) => {
        this.setState({value: selectedOption }, ()=>{
            this.props.updateParent(this.state.value);
        });
    };

    /**
     * Renders the item list
     * @returns {XML} Returns an XML of the selection
     */
    render(){
        if(this.state.loading)
        {
            return(<BeatLoader
                size={10}
                color={"#6bc4ff"}
            />)
        }
        return (
            <Select
                     onChange={this.handleChange}
                     value={this.state.resetBox ? null : this.props.value}
                     options={this.state.options}
            />
        )
    }
}

export default SelectorApiKeys;