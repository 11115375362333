import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { Form } from 'react-bootstrap';
import { Col, Row } from "react-bootstrap";
class TextInput extends Component {

    constructor(props) {
        super(props);
        this.onTextChange = this.onTextChange.bind(this);
    }

    onTextChange(event) {
        //getting field name and value
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        this.props.onChange(fieldName, fieldValue);
    }

    render() {
        const { textType, textName, placeholder, labelID, index, limitFromRules, content, type, readOnly = false } = this.props;
        return (
            <Row>

                {
                    labelID ? <Col xs={3} md={2}><b><FormattedMessage id={labelID} /> {index >= 0 ? index + 1 : ""}</b> </Col> : ''
                }
                <Col xs={9} md={10}>

                    <Form.Control
                        index={index}
                        name={textName}
                        onChange={this.onTextChange}
                        value={content}
                        type={type ? type : textType}
                        readOnly={readOnly}
                        placeholder={placeholder || ""}
                    />
                    {
                        limitFromRules ? <p style={content ? content.length > limitFromRules ? { color: "red" } : {} : {}}>{content ? content.length : 0} / {limitFromRules}</p> : ""
                    }
                </Col>
            </Row>
        );
    }
}

export default TextInput;