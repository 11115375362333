import React, { Component } from 'react';
import { onRulesCall } from '../../server/getFileUploadRulesCall/file-upload-rules-call';
import { getTemplateById } from '../../server/getTemplateById/get-template-by-id';
import { updateTemplateCall } from '../../server/postTemplateUpdate/post-template-update-call';
import { emptyTemplateModelObject, emptyTemplateInstructionsModelList, emptyTemplateCodeModel, validateTemplate } from '../createTemplatepage/create-template-utils';

import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import TemplateDetailsDisplay from '../../components/templateDetailsDisplay';
import Button from 'react-bootstrap/Button';

class EditTemplatePage extends Component{
  
  constructor(props){
    super(props);
    this.state={
      templateModel: emptyTemplateModelObject,
      templateInstructionsModelList: emptyTemplateInstructionsModelList,
      templateCodeModel: emptyTemplateCodeModel
    }
    this.onChange = this.onChange.bind(this);
    this.onChangeInstructions = this.onChangeInstructions.bind(this);
    this.onChangeTemplateModel = this.onChangeTemplateModel.bind(this);
    this.validateBeforePost = this.validateBeforePost.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
  }
  
  onChange(name, value){
    this.setState({[name]: value});
  }

  onChangeTemplateModel(propertyName, value){
    let newTemplateState = this.state.templateModel;
    newTemplateState[propertyName] = value
    this.setState({templateModel: newTemplateState});
  }

  onChangeInstructions(value, lang){
    let newInstructions = this.state.templateInstructionsModelList;
    if(lang === "en"){
      newInstructions[0].InstructionDesc = value;
    } else if(lang === "es"){
      newInstructions[1].InstructionDesc = value;
    } 
    this.setState({templateInstructionsModelList: newInstructions})
  }

  validateBeforePost(){
    if(this.state.templateCodeModel.Id === null){
      alert("Debe seleccionar Template Code");
    } else{
      let isValidated = validateTemplate(
        this.state.templateCodeModel, 
        this.state.templateModel, 
        this.state.templateInstructionsModelList);
      if(isValidated){
        this.updateTemplate();
      }
    }
  }

  updateTemplate(){
    const templateModifierRequestModel = {
      TemplateModel: this.state.templateModel,
      TemplateInstructionModelList: this.state.templateInstructionsModelList
    }

    this.setState({loading: true}, function(){
      updateTemplateCall(this.props.language, templateModifierRequestModel).then((data) => {
        this.setState({loading: data});
      });
    });
  }

  componentDidMount(){
    getTemplateById(this.props.language, sessionStorage.TemplateId, sessionStorage.templateIdType).then((data) => {
      let templateInstructions = [];
      data.TemplateInstructionModelList.map((item) => {
        templateInstructions.push({
          LangCode: item.LangCode,
          InstructionDesc: item.InstructionDesc
        });
        return true; //For warning? arrow functions must return something
      });

      let templateModelEdited = data.TemplateModel;
      delete templateModelEdited.ModifiedBy;
      delete templateModelEdited.CreatedAt;
      delete templateModelEdited.UpdatedAt;

      this.setState({
        templateModel: templateModelEdited,
        templateInstructionsModelList: templateInstructions,
        templateCodeModel: data.TemplateCodeModel
      })
    });
    onRulesCall().then((data) => {
      this.setState({fileUploadRules: data});
    })
  }
  
  render(){
    return(
      <div style={{marginBottom: "50px"}} className="card-alt">
        <h3><FontAwesomeIcon icon={faEdit}/> <FormattedMessage id="et-update"/></h3>
        <Button
          style={{float: "right"}}
          variant="success"
          onClick={this.validateBeforePost}
          disabled={this.state.loading}
        >
          <FormattedMessage id="et-update"/>
        </Button>
        <TemplateDetailsDisplay
          onChange={this.onChange}
          onChangeTemplate={this.onChangeTemplateModel}
          onChangeInstructions={this.onChangeInstructions}
          parentState={this.state}
          language={this.props.language}
        />
      </div>
    );
  }
}

export default EditTemplatePage;