import React, { Component } from 'react';
import CreateSection from './sections/create-section';

class SubjectDisplayView extends Component{
  constructor(props){
    super(props);
    this.state={
      content: this.props.content,
      language: this.props.language,
      infoValidate: false,
      isCreation: typeof this.props.isCreation !== 'undefined' && this.props.isCreation != null ? this.props.isCreation : false,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(field, value){
    //Trick to add to state from multiple inputs with same function
    this.props.onChange(field, value);
  }

  render(){
    return(
      <div style={{padding: "10px"}}>
        <CreateSection
            isCreation={this.state.isCreation}
            infoValidate={this.state.infoValidate}
            parentStateHandle={this.props.onChange}
            state={this.props.additionalMaterialContent}
            language={this.props.language}
        />
      </div>
    );
  }
}

export default SubjectDisplayView;