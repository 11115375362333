import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import AddedTextAndField from '../addedTextAndField';
import { FormattedMessage } from 'react-intl';
import {FaPlusCircle} from 'react-icons/fa';

class TextAndFieldGenerator extends Component{
  constructor(props){
    super(props);
    this.addField = this.addField.bind(this);
    this.removeField = this.removeField.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeDesc = this.onChangeDesc.bind(this);
    this.onChangeFrames = this.onChangeFrames.bind(this);
  }

  addField(){
    let content;
    if(this.props.isDefinitionOrExample){
      content = this.props.state.concat({Name: "", Desc: "", Frames: ""});
    } else{
      content = this.props.state.concat({Name: "", Desc: ""});
    }
    this.props.onChange(content);
  }

  onChangeName(index, value){
    let content = this.props.state;
    content[index].Name = value;
    this.props.onChange(content);
  }
  onChangeDesc(index, value){
    let content = this.props.state;
    content[index].Desc = value;
    this.props.onChange(content);
  }
  onChangeFrames(index, value){
    let content = this.props.state;
    content[index].Frames = value;
    this.props.onChange(content);
  }
  
  removeField(index){
    let content = this.props.state;
    content.splice(index, 1);
    this.props.onChange(content);
  }
  
  render(){
    const { labelId } = this.props;
    return(
      <div>
        {
          this.props.state ? this.props.state.map((item, index) => {
            return(
              <div key={index} className="card-alt-2">
                {/* <h5><FormattedMessage id={labelId}/> {index >= 0 ? index + 1 : ""}</h5>
                <TextInput
                  textName={index}
                  textType="text"
                  index={index}
                  content={item.name}
                  onChange={this.onChangeName}
                />
                <TinyMCE 
                  index={index} 
                  content={item.Desc}
                  onChange={this.onChangeDesc} 
                />
                <Button style={style.removeButton} variant="danger" onClick={() => this.removeField(index)}>remove</Button> */}
                <AddedTextAndField
                    size={typeof this.props.size !== 'undefined' && this.props.size !== null ? this.props.size : 230}
                  isVr={this.props.isVr}
                  index={index}
                  textName={index}
                  name={item.Name} 
                  desc={item.Desc} 
                  onChangeDesc={this.onChangeDesc} 
                  onChangeName={this.onChangeName} 
                  labelId={labelId}
                  style={style}
                  removeField={this.removeField}
                  lessonGrade={this.props.lessonGrade}
                  lessonSubject={this.props.lessonSubject}
                  limitFromRules={this.props.limitFromRules ? this.props.limitFromRules : null}
                  fileRules={this.props.fileRules}
                />
                {this.props.isDefinitionOrExample ?
                    <Row>
                      <Col md={8} xs={12}>
                        <small style={{paddingTop: "5px"}}><i><FormattedMessage id="cl-frames-instruction"/></i></small>
                      </Col>
                      <Col md={3} xs={12}>
                        <input type="number" value={item.Frames} onChange={(e) => this.onChangeFrames(index, e.target.value)}/>
                      </Col>
                    </Row>
                :
                  ""
                }
              </div>
            )
          }) : ""
        }
        <div style={{marginTop: 10, textAlign: 'right'}}>
          <Button style={style.addButton} onClick={this.addField}><FaPlusCircle/>{' '}<FormattedMessage id="gl-add-new-field"/></Button>
        </div>
      </div>
    );
  }
}

const style = {
  removeButton:{
    marginTop: "13px",
    marginBottom: "13px"
  },
  addFieldButtonContainer:{
    display: "flex",
    justifyContent: "center"
  }, 
  addButton:{
    width: "50%",
  }
}

export default TextAndFieldGenerator;