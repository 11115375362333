//Libraries
import React, { Component } from 'react';
import axios from "../../server/interceptors";
import { Redirect } from 'react-router-dom';
//Regular Components
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import ImageComponent from '../imageComponent';
import logoGenialSkills from '../../assets/images/genial-logo.png';
import './login-form.css';

import { navAuth } from '../../utils/authHandling/navigation-auth-handler';
import {apiLoginUrl, DEFAULT_LANGUAGE} from '../../config';
import SessionManager from "../../utils/authHandling/session-manager";

class LoginForm extends Component{
  constructor(props) {
    super(props);
    this.state={
      username: '',
      password: '', 
      Loading: false
    };

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.sendCall = this.sendCall.bind(this);
    this.handleAuth = this.handleAuth.bind(this);
  }

  handleUsernameChange(event) {
    this.setState({ username: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleAuth = (token) => {
    navAuth.authenticate(() => {
      this.setState(() => ({
        redirectToReferrer: true
      }));
    });
  }

  /**
   *  Esta funcion manda el llamado post al api con el payload de username, password y api key 
   *  Por ahora solo tiene un console log para la respuesta.
   *  Los proximos pasos es redirigir al dashboard o manejar cuando los credenciales estan erroneos
   */
  sendCall(event){
    this.setState({Loading: true});
    let self = this;
    const handleAuth = this.handleAuth;
    let apiRoute = apiLoginUrl;
    let payload = {
      "Username": this.state.username,
      "Password": this.state.password
    };
    axios.post(apiRoute, payload, {
      headers: {
        'Content-Type': 'application/json',
      }}).then(function (response) {
      self.setState({Loading: false});

      //If response has token
      if(!response.data.Token){
        //Login is not successful
        alert(response.data.Messages[0]);
      } else{
        SessionManager.setSession(response.data);
        handleAuth(response.data.Token); //call redirect function
      }
    }).catch(function (error) {
      self.setState({Loading: false});
      console.log(error.response.data.Messages);
    });

    //Prevent page reload from form input
    event.preventDefault(); 
  }

  render() {
    const { redirectToReferrer } = this.state;

    if(redirectToReferrer === true){
      return <Redirect to='/' />
    }
    return(
      <div className="loginContent">
        <ImageComponent src={logoGenialSkills} altmsg="Athenas Logo" width="240px"/>
        <hr/>
        {this.state.Loading ? 
          <Spinner animation="grow"/> 
          :
            <div className="card-alt">
          <Form onSubmit={this.sendCall.bind(this)} className="loginForm">
            <Form.Group className="textInput" controlId="formBasicEmail">
              <Form.Control placeholder="Username" onChange={this.handleUsernameChange.bind(this)} />
            </Form.Group>
            <Form.Group className="textInput" controlId="formBasicPassword">
              <Form.Control type="password" placeholder="Password" onChange={this.handlePasswordChange.bind(this)} />
            </Form.Group>
            <Button className="loginButton" variant="primary" type="submit">Login</Button>
          </Form>
            </div>
        }
        <hr/>
      </div>
    );
  }
}

export default LoginForm;