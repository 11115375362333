import React, { Component } from 'react';
import TabNavigator from '../tabNavigator';
import CreateLessonInfoSection from './sections/createLessonInfoSection';
import CreateLessonObjectivesSection from './sections/createLessonObjectivesSection';
import CreateLessonTareasSection from './sections/createLessonTareasSection';
import CreateLessonEstrategiaSection from './sections/createLessonEstrategiaSection';
import CreateLessonTemasSection from './sections/createLessonTemasSection';
import CreateLessonDefinicionesSection from './sections/createLessonDefinicionesSection';
import CreateLessonEjemplosSection from './sections/createLessonEjemplosSection';
import CreateLessonPruebasSection from './sections/createLessonPruebasSection';
import CreateLessonCommentsSection from './sections/createLessonCommentsSection';
import { getComments } from '../../server/getComments/get-comments';
import { getCommentsSent } from '../../server/getCommentsSent/get-comments-sent';
import { getTemplateById } from '../../server/getTemplateById/get-template-by-id';
import { onTemplateCall } from '../../server/getLessonTemplateCall/lesson-template-call';
import LessonAdditionalMaterialSection from "./sections/createLessonAdditionalMaterialsSection";
import SessionManager from "../../utils/authHandling/session-manager";

class LessonDetailsDisplay extends Component{
  constructor(props){
    super(props);
    this.state={
      lesson: this.props.lessonContent,
      infoValidate: false,
      isCreation: typeof this.props.isCreation !== 'undefined' && this.props.isCreation != null ? this.props.isCreation : false,
      objetivosValidate: false,
      tareasValidate: false,
      estrategiaValidate: false,
      temasValidate: false,
      definicionesValidate: false,
      ejemplosValidate: false,
      pruebasValidate: false,
      roles: SessionManager.getSession() !== null ? SessionManager.getSessionDetails().Roles : [],
    };
    this.onChange = this.onChange.bind(this);
    this.loadSelectedDefinition = this.loadSelectedDefinition.bind(this);
    this.selectDefinitionTemplate = this.selectDefinitionTemplate.bind(this);
    this.loadSelectedExample = this.loadSelectedExample.bind(this);
    this.selectExampleTemplate = this.selectExampleTemplate.bind(this);
    this.loadComments = this.loadComments.bind(this);
  }

  onChange(field, value){
    //Trick to add to state from multiple inputs with same function
    this.props.onChange(field, value);
  }

  loadSelectedDefinition(){
    onTemplateCall("DEF", this.props.language).then((data) => {

      if(typeof this.props.lessonContent !== 'undefined' && this.props.lessonContent !== null &&
          typeof this.props.lessonContent.lessonTemplates !== 'undefined'
      ) {
        data.map(item => {
          this.props.lessonContent.lessonTemplates.map((selectedIds) => {
            if (item.Id === selectedIds.TemplateId) {
              getTemplateById(this.props.language, item.Id, "DEF").then((result) => {
                let selectedDefinitionTemplate = {
                  title: result.TemplateModel.Name,
                  description: result.TemplateModel.Desc,
                  img: result.TemplateModel.ImgTemplate
                };
                this.setState({selectedDefinitionTemplate: selectedDefinitionTemplate});
              });
            }
          });
        });
      }
    });
  }

  selectDefinitionTemplate(id){
    if(id !== null){
      this.loadSelectedDefinition();
    } else{
      this.setState({selectedDefinitionTemplate: null});
    }
  }
  
  loadSelectedExample(){
    onTemplateCall("EXA", this.props.language).then((data) => {
      if(typeof this.props.lessonContent !== 'undefined' && this.props.lessonContent !== null &&
          typeof this.props.lessonContent.lessonTemplates !== 'undefined'
      ) {
        data.map((item) => {
          this.props.lessonContent.lessonTemplates.map((selectedIds) => {
            if (item.Id === selectedIds.TemplateId) {
              getTemplateById(this.props.language, item.Id, "EXA").then((result) => {
                let selectedExampleTemplate = {
                  title: result.TemplateModel.Name,
                  description: result.TemplateModel.Desc,
                  img: result.TemplateModel.ImgTemplate
                };
                this.setState({selectedExampleTemplate: selectedExampleTemplate});
              })
            }
          });
        })
      }
    });
  }

  selectExampleTemplate(id){
    if(id !== null){
      this.loadSelectedExample();
    } else{
      this.setState({selectedExampleTemplate: null})
    }
  }

  loadComments(){
    getComments(this.props.language, this.props.lessonContent.lessonId, "LES").then((data) => {
      let messagesReceived = [];
      data.CommentDetailsModelList.forEach((item, index) => {
        messagesReceived.push({to: item.RecipientName ,from: item.SenderName, comment: item.Comment, date: item.CreatedAt, completed: item.Completed, read: item.Read, id: item.Id, userFromId: item.UserId});
      });
      this.setState({MessagesReceived: messagesReceived});
    });

    getCommentsSent(this.props.language, this.props.lessonContent.lessonId, "LES").then((data) => {
      let messagesSent = [];
      data.CommentDetailsModelList.forEach((item, index) => {
        messagesSent.push({to: item.RecipientName, from: item.SenderName, comment: item.Comment, date: item.CreatedAt, completed: item.Completed});
      });
      this.setState({MessagesSent: messagesSent});
    })
  }

  render(){
    const sections = [
      {
        "sectionTitle": "Info",
        "sectionContent": <CreateLessonInfoSection
          parentStateHandle={this.props.onChange}
          state={this.props.lessonContent}
          language={this.props.language}
          lessonRules={this.props.lessonContent.lessonRules}
          fileRules={this.props.lessonContent.fileRules}
          onDataChange={this.props.onDataChange}
        />
      },
      {
        "sectionTitle": "Objetivos",
        "sectionContent": <CreateLessonObjectivesSection
          parentStateHandle={this.onChange}
          state={this.props.lessonContent.lessonObjectives}
          language={this.props.language}
          isValidated={this.props.lessonContent.validateLessonObjectives}
          lessonRules={this.props.lessonContent.lessonRules}
          fileRules={this.props.lessonContent.fileRules}
        />
      },
      {
        "sectionTitle": "Tareas de Desempeño",
        "sectionContent": <CreateLessonTareasSection
          parentStateHandle={this.onChange}
          state={this.props.lessonContent.lessonTasks}
          language={this.props.language}
          isValidated={this.props.lessonContent.validateLessonTasks}
          lessonRules={this.props.lessonContent.lessonRules}
          fileRules={this.props.lessonContent.fileRules}
        />
      },
      {
        "sectionTitle": "Estrategia reformadora",
        "sectionContent": <CreateLessonEstrategiaSection
          parentStateHandle={this.onChange}
          state={this.props.lessonContent.lessonStrategies}
          language={this.props.language}
          isValidated={this.props.lessonContent.validateLessonStrategies}
          lessonRules={this.props.lessonContent.lessonRules}
          fileRules={this.props.lessonContent.fileRules}
        />
      },
      {
        "sectionTitle": "Temas tranversales",
        "sectionContent": <CreateLessonTemasSection
          parentStateHandle={this.onChange}
          state={this.props.lessonContent.lessonTransversalThemes}
          language={this.props.language}
          isValidated={this.props.lessonContent.validateLessonTransversalThemes}
          lessonRules={this.props.lessonContent.lessonRules}
          fileRules={this.props.lessonContent.fileRules}
        />
      }
    ]
    
    if(this.props.lessonContent.lesson !== undefined){
      if(this.props.lessonContent.lesson.data.LessonModifierRequestModel.LessonModel.Id !== null){


        sections.push({
          "sectionTitle": "Definiciones",
          "sectionContent": <CreateLessonDefinicionesSection
              isVr={this.props.lessonContent.lessonAddress === "1"}
              parentStateHandle={this.onChange}
              state={this.props.lessonContent.lessonDefinitions}
              selectedTemplates={this.props.lessonContent.lessonTemplates ? this.props.lessonContent.lessonTemplates : []}
              language={this.props.language}
              isValidated={this.props.lessonContent.validateLessonDefinitions}
              lessonGrade={this.props.lessonContent.lessonGrade}
              lessonSubject={this.props.lessonContent.lessonSubject}
              lessonRules={this.props.lessonContent.lessonRules}
              fileRules={this.props.lessonContent.fileRules}
              selectedDefinitionTemplate={this.state.selectedDefinitionTemplate}
              selectDefinitionTemplate={this.selectDefinitionTemplate}
          />,
          "onSelect": this.loadSelectedDefinition
        });

        sections.push({
          "sectionTitle": "Ejemplos",
          "sectionContent": <CreateLessonEjemplosSection
              isVr={this.props.lessonContent.lessonAddress === "1"}
              parentStateHandle={this.onChange}
              state={this.props.lessonContent.lessonExample}
              selectedTemplates={this.props.lessonContent.lessonTemplates ? this.props.lessonContent.lessonTemplates : []}
              language={this.props.language}
              isValidated={this.props.lessonContent.validateLessonExample}
              lessonGrade={this.props.lessonContent.lessonGrade}
              lessonSubject={this.props.lessonContent.lessonSubject}
              lessonRules={this.props.lessonContent.lessonRules}
              fileRules={this.props.lessonContent.fileRules}
              selectedExampleTemplate={this.state.selectedExampleTemplate}
              selectExampleTemplate={this.selectExampleTemplate}
          />,
          "onSelect": this.loadSelectedExample
        });

        sections.push({
          "sectionTitle": "Evaluaciones",
          "sectionContent": <CreateLessonPruebasSection
              parentStateHandle={this.onChange}
              state={this.props.lessonContent}
              language={this.props.language}
              isValidated={false}
              isVr={this.props.lessonContent.lessonAddress === "1"}
              lessonRules={this.props.lessonContent.lessonRules}
              fileRules={this.props.lessonContent.fileRules}
              comments={this.state.comments}
          />
        });

        sections.push({
          "sectionTitle": "Material Adicional",
          "sectionContent": <LessonAdditionalMaterialSection
              language={this.props.language}
             lessonId={this.props.lessonContent.lessonId}
          />,
          "onSelect": this.loadAdditionalMaterial
        });

        sections.push({
          "sectionTitle": "Comentarios",
          "sectionContent": <CreateLessonCommentsSection
              parentStateHandle={this.onChange}
              state={this.props.lessonContent}
              language={this.props.language}
              messagesSent={this.state.MessagesSent}
              messagesReceived={this.state.MessagesReceived}
              loadComments={this.loadComments}
          />,
          "onSelect": this.loadComments
        })
      }
    }

    return(
      <div style={{padding: "10px"}} className="border-left-colNine">
      <hr/>
        <TabNavigator
          sections={sections}
          defaultActive="Info"
        />
      </div>
    );
  }
}

export default LessonDetailsDisplay;