import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


class ModalCommentRead extends Component{


  render(){


    return(
      <div>
        <Modal show={this.props.modalOpen} onHide={this.props.closeModal}>
          <Modal.Title style={{marginLeft: "10px"}}><FormattedMessage  id="cs-comment"/></Modal.Title>
          <Modal.Body>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div>
                <p><FormattedMessage id="cs-for"/>{this.props.messageModel ? this.props.messageModel.to : ""}</p>
                <p><FormattedMessage id="cs-from"/>{this.props.messageModel ? this.props.messageModel.from : ""}</p>
              </div>
            </div>
            <div style={{backgroundColor: "rgba(0,0,0,0.2)", height: "200px", padding: "20px"}}>
              {this.props.messageModel ? this.props.messageModel.comment : ""}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.closeModal}>
              <FormattedMessage id="option-close"/>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ModalCommentRead;