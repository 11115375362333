import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import axios from "../../server/interceptors";
import {
    apiExpectationModify,
    apiGetExpectationById,
    DEFAULT_LANGUAGE
} from '../../config';
import {Button, Container} from 'react-bootstrap';
import './edit-expectation-page-style.css';
import SessionManager from "../../utils/authHandling/session-manager";
import ExpectationDisplayView from "../../components/expectationDisplayView";

class EditExpectationPage extends Component {

    constructor(props){
        super(props);
        this.state={
            loading: false,
            id: sessionStorage.getItem('selected-expectation-id'),
            code: "",
            name: "",
            languageSP: "",
            languageEN: "",
            selectedStandardId: "",
            selectedSubjects: [],
            selectedGrade: [],
            redirect: false,
        };
        this.onCreateCall = this.onCreateCall.bind(this);
        this.onChange = this.onChange.bind(this);
        this.redirectToEdit = this.redirectToEdit.bind(this);
    }

    onChange(field, value){
        this.setState({[field]: value});
    }

    componentDidMount() {
        this.onDataCall();
    }

    onDataCall(){

        axios.get(`${apiGetExpectationById}/${this.state.id}`, {
            headers: {
                'Content-Type': 'application/json',                
            }}).then((response)=>{
            this.setState({
                code: response.data[0].CodeMainModel.Code,
                languageSP: response.data[0].CodeMainLanguageModels.find(x => x.LessonLangCode === "es").Desc,
                languageEN: response.data[0].CodeMainLanguageModels.find(x => x.LessonLangCode === "en").Desc,
                selectedStandardId: response.data[0].CodeMainModel.StandardId,
            });

            let selectedGrades = [];
            selectedGrades.push(response.data[0].CodeMainModel.LevelCode);
            this.setState({
                selectedGrade: selectedGrades,
            });

        }).catch(error => {
            console.log(error.response.data.Messages);
        });
    }


    onCreateCall(){

        // Validate
        if(this.state.code === ""){
            alert("Error: The standard code is required. / El código estándar es requerido.");
            return;
        }
        if(this.state.selectedGrade.length === 0){
            alert("Error: The level (grade) is required./El nivel (grado) es requerido.");
            return;
        }
        if(this.state.languageSP.length === 0){
            alert("Error: The description in Spanish is required./La descripción en español es requerida.");
            return;
        }
        if(this.state.languageEN.length === 0){
            alert("Error: The description in English is required./La descripción en inglés es requerida.");
            return;
        }
        if(this.state.selectedStandardId === ''){
            alert("Error: The standard id is required./El estándar es requerido.");
            return;
        }

        this.setState({loading: true}, ()=>{

            // Call the API to insert
            let params = {
                CodeMainModel: {
                    Id: this.state.id,
                    LevelCode: this.state.selectedGrade[0],
                    StandardId: this.state.selectedStandardId,
                    Code: this.state.code,
                    Desc: this.state.languageSP,
                },
                CodeMainLanguageModels:[
                    {
                        LessonLangCode: "es",
                        Desc: this.state.languageSP,
                    },
                    {
                        LessonLangCode: "en",
                        Desc: this.state.languageEN,
                    }
                ]
            };
            axios.post(apiExpectationModify, params,{
                headers: {
                    'Content-Type': 'application/json',                    
                }}).then((response)=>{

                    console.log(response);
                if(response.status === 201 || response.status === 200)
                {
                    alert("Successfully updated./Actualizado satisfactoriamente.");
                    this.setState({
                        redirect: true,
                    })
                }
                else
                {
                    alert("Validate the fields. If the error continues, contact the administrator./Valide los campos. Si el error persiste, contacte al administrador.");
                }
                this.setState({loading: false});
            }).catch(error => {

                if(error.response.status === 400)
                {
                    if(typeof error.response.data !== 'undefined' && typeof error.response.data.Messages !== 'undefined' && error.response.data.Messages.length > 0)
                    {
                        let message = "";
                        for(let i=0;i<  error.response.data.Messages.length; i++)
                        {
                            message += error.response.data.Messages[i];
                        }
                        alert(message);
                    }
                }
                else {
                    alert(`Error: ${error}`);
                }
                this.setState({loading: false});
            });
        });
    }

    redirectToEdit(){
        this.setState({redirectToEdit: true})
    }

    render(){
        if(typeof this.state.id === 'undefined' || this.state.id === null || this.state.id === ""){
            return <Redirect to='/view-expectations' />
        }

        return(
            <Container fluid className="card-alt">
                <div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <h2><FormattedMessage id="std-expectation"/></h2>
                        {this.state.loading ? <Button disabled variant="success">
                                <FormattedMessage id="std-modify"/></Button> :
                            <Button variant="success" onClick={this.onCreateCall}>
                                <FormattedMessage id="std-modify"/>
                            </Button>}
                    </div>

                    <ExpectationDisplayView
                        isCreation={false}
                        language={this.props.language}
                        dataContent={this.state}
                        onChange={this.onChange}
                    />
                </div>
            </Container>
        );
    }
}

export default EditExpectationPage;