import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import axios from "../../server/interceptors";
import {
    apiSubjectUrl,
    DEFAULT_LANGUAGE, apiSubjectModifyUrl
} from '../../config';
import {Button, Container} from 'react-bootstrap';
import './edit-subject-page-style.css';
import SubjectDisplayView from "../../components/subjectDisplayView";
import SessionManager from "../../utils/authHandling/session-manager";

class EditSubjectPage extends Component {

    constructor(props){
        super(props);
        this.state={
            loading: false,
            id: sessionStorage.lastSubject,
            code: "",
            langCode: "",
            name: "",
            redirect: false,
        };
        this.saveData = this.saveData.bind(this);
        this.onChange = this.onChange.bind(this);
        this.redirect = this.redirect.bind(this);
    }

    onChange(field, value){
        //Trick to add to state from multiple inputs with same function
        this.setState({[field]: value});
    }

    componentDidMount() {
        this.onDataCall();
    }

    onDataCall(){

        axios.get(`${apiSubjectUrl}/${this.state.id}`, {
            headers: {
                'Content-Type': 'application/json',                
            }}).then((response)=>{
            this.setState({
                code: response.data[0].Code,
                langCode: response.data[0].LangCode,
                name: response.data[0].Name,
            });

        }).catch(error => {
            console.log(error.response.data.Messages);
        });
    }

    saveData(){

        // Validate
        if(this.state.code === ""){
            alert("Error: Code is required./El código es requerido.");
            return;
        }
        if(this.state.langCode === ""){
            alert("Error: The language code of the subject required. (en for English, es for Spanish)/El código de lenguaje de la materia es requerido. (en para inglés, es para español).");
            return;
        }
        if(this.state.name === "" || this.state.name === ""){
            alert("Error: The name is required./El nombre  es requerido.");
            return;
        }

        this.setState({loading: true}, ()=>{
            // Call the API to insert
            let params = {
                Id: this.state.id,
                Code: this.state.code,
                LangCode: this.state.langCode,
                Name: this.state.name,
            };

            axios.post(apiSubjectModifyUrl, params,{
                headers: {
                    'Content-Type': 'application/json',                    
                }}).then((response)=>{

                    if(response.status === 200)
                    {
                        alert("Subject successfully saved./Materia guardada satisfactoriamente.");
                    }
                    else
                    {
                        alert("Validate the fields. If the error continues, contact the administrator./Valide los campos. Si el error persiste, contacte al administrador.");
                    }
                this.setState({loading: false});
            }).catch(error => {
                alert("Error: " + error);
                this.setState({loading: false});
            });
        });
    }


    redirect(){
        this.setState({redirect: true})
    }

    render(){

        if (this.state.redirect) {
            return <Redirect to='/view-subjects' />
        }
        return(
            <Container fluid className="card-alt">
              <div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <h2><FormattedMessage id="sub-subject"/></h2>
                            {this.state.loading ?
                                <div>
                                    <Button disabled variant="success">
                                        <FormattedMessage id="am-save"/>
                                    </Button>
                                </div>:
                                <div>
                                    <Button variant="success" onClick={this.saveData}>
                                        <FormattedMessage id="am-save"/>
                                    </Button>
                                </div>
                            }
                        </div>

                        <SubjectDisplayView
                            isCreation={false}
                            language={this.props.language}
                            additionalMaterialContent={this.state}
                            onChange={this.onChange}
                        />
                    </div>
            </Container>
        );
    }
}

export default EditSubjectPage;