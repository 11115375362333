export const quizValidator = function(quizParams, rules){
  let messages = [];

  //ExtraInfo 
  if(quizParams.QuizRequestModel.QuizExtraInfoModel.ExtraInfo !== null && quizParams.QuizRequestModel.QuizExtraInfoModel.ExtraInfo !== undefined){
    if(quizParams.QuizRequestModel.QuizExtraInfoModel.ExtraInfo.length > rules.ExtraInfoMaxLength){
      messages.push("Extra info is too long");
    }
  }

  //Instructions
  if(quizParams.QuizRequestModel.QuizModel.Instruction !== null && quizParams.QuizRequestModel.QuizModel.Instruction !== undefined){
    if(quizParams.QuizRequestModel.QuizModel.Instruction.length > rules.InstructionMaxLength){
      messages.push("Instructions is too long");
    }
  }

  //Max Questions
  if(quizParams.QuizRequestModel.QuestionRequestModelList !== null && quizParams.QuizRequestModel.QuestionRequestModelList !== undefined){
    if(quizParams.QuizRequestModel.QuestionRequestModelList.length > rules.MaxQuestions){
      messages.push("Quiz has too many questions");
    }
  }

  //Max Title length
  if(quizParams.QuizRequestModel.QuizModel.Title !== null && quizParams.QuizRequestModel.QuizModel.Title !== undefined){
    if(quizParams.QuizRequestModel.QuizModel.Title.length > rules.TitleMaxLength){
      messages.push("Quiz title is too long");
    }
  }

  //Max length of each question
  quizParams.QuizRequestModel.QuestionRequestModelList.forEach((element, index) => {
    if(element.QuizQuestionModel.Desc !== null && element.QuizQuestionModel.Desc !== undefined){
      //This flow is to remove image tags from the question, so that they dont count on the length of the question
      let pureQuestion = element.QuizQuestionModel.Desc.replace(/<img .*?>/g,"");
      if(pureQuestion.length > rules.QuestionMaxLength){
        let questionNum = index + 1;
        messages.push("Question #" + questionNum + " is too long");
      }
    }
  });

  //Max length of each answer
  quizParams.QuizRequestModel.QuestionRequestModelList.forEach((element, qIndex) => {
    element.QuizQuestionResponseModelList.forEach((elementx, rIndex) => {
      if(elementx.Response !== null && elementx.Response !== undefined){
        let pureAnswer = elementx.Response.replace(/<img .*?>/g,"");
        if(pureAnswer.length > rules.ResponseMaxLength){
          let responseNum = rIndex + 1;
          let questionNum = qIndex + 1;
          messages.push("Response #" + responseNum + " in question #" + questionNum + " is too long");
        }      
      }
    });
  });

  return messages;
};