import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import {Redirect} from 'react-router-dom';
import {FaChalkboard} from 'react-icons/fa'
import './view-expectation-page.css';
import RadioButtonSubjects from "../../components/radioButtonSubjects";
import RadioButtonGrades from "../../components/radioButtonGrades";
import StandardCodeSelector from "../../components/standardCodeSelector";

class ViewExpectationPage extends Component{

    constructor(props){
        super(props);
        this.state={
            selectedSubject: [],
            selectedGrade:[],
            selectedStandardId: "",
            selectedExpectationId: "",
            selectedSubCodeId: "",
            selectedSubMainSubCodeId : "",
            redirect: false,
        };
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onChange(field, value){
        this.setState({[field]: value});
    }

    onClick(){
        sessionStorage.setItem("selected-expectation-id", this.state.selectedExpectationId);
        this.setState({
            redirect: true
        })
    }

    render(){

        if(this.state.redirect){
          return(<Redirect to="/edit-expectation"/>)
        }

        return(
            <Container fluid className="card-alt">
                <Row>
                    <Col xs={12}>
                        <h3>
                            <FaChalkboard/>{' '}
                            <FormattedMessage id="std-expectations"/>{' '}
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr/>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <b><FormattedMessage id="std-select-following-details"/></b>
                    </Col>
                </Row>
                <Row style={{marginTop: 40}}>
                    <Col>
                        <RadioButtonSubjects
                            textName="selectedSubject"
                            onChange={this.onChange}
                            content={this.state.selectedSubject}
                            language={this.props.language}
                            isValidated={null}
                        />
                        {<RadioButtonGrades
                            textName="selectedGrade"
                            onChange={this.onChange}
                            content={this.state.selectedGrade}
                            language={this.props.language}
                            isValidated={null}
                        />}
                        <StandardCodeSelector
                            onChange={this.onChange}
                            subjectCode={this.state.selectedSubject}
                            grade={this.state.selectedGrade}
                            language={this.props.language}
                            selectedStandardId={this.state.selectedStandardId}
                            selectedExpectationId={this.state.selectedExpectationId}
                            selectedSubCodeId={this.state.selectedSubCodeId}
                            selectedSubMainSubCodeId={this.state.selectedSubMainSubCodeId}
                            loading={this.state.loadingLessonData}
                            showExpectation={true}
                            showIndicator={false}
                            showSubIndicator={false}
                            loadingFromServer
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={this.onClick} disabled={this.state.selectedExpectationId !== "" ? "": "disabled"}>
                            <FormattedMessage id="std-modify"/>
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default ViewExpectationPage;