import axios from "../interceptors";
import { apiGradeUrl } from "../../config";
import { logoutUser } from "../../utils/authHandling/logout-user";

export const onGradeCall = async function (language) {
  try {
    const response = await axios.get(apiGradeUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200 || response.status === 201) {
      return response.data.map((item) => ({
        title: item.Name,
        code: item.Code,
      }));
    } else if (response.status === 401) {
      //session in not valid, user is sent to login
      alert("Session is not valid.");
      logoutUser();
    }
  } catch (error) {
    console.log(error.response.data.Messages);
  }
};
