import axios from "../interceptors";
import { apiLessonsTableUrl, apiLessonsReportUrl } from "../../config";

const headers = {
  "Content-Type": "application/json",
};

export const getLessonsQuery = async (params) => {
  const response = await axios.post(`${apiLessonsTableUrl}`, params, {
    headers: headers,
  });
  return response.data;
};

export const getLessonsReport = async (params) => {
  const response = await axios.post(`${apiLessonsReportUrl}`, params, {
    headers: headers,
  });
  return response.data;
};
