import axios from "../interceptors";
import { apiGetTemplateCodes } from "../../config";
import { logoutUser } from "../../utils/authHandling/logout-user";

export const getTemplateCodes = async function (language) {
  try {
    const response = await axios.get(apiGetTemplateCodes, {
      headers: {
        "Content-Type": "application/json",        
      },
    });
    if (response.status === 200 || response.status === 201) {
      // Session and transaction is valid, return the values
      return response.data.TemplateCodesModelList;
    } else if (response.status === 401) {
      //session is not valid, user is sent to login
      alert("Session is not valid");
      logoutUser();
    }
  } catch (error) {
    console.log(error.response.data.Messages);
  }
};
