class SessionManager {
    /**
     * Sets the session
     * @param sessionDetails the session details of the connected user.
     */
    static setSession(sessionDetails)
    {
        localStorage.setItem('auth', JSON.stringify({
            "InvitationCode": sessionDetails.InvitationCode,
            "Roles": sessionDetails.Roles,
            "Token": sessionDetails.Token,
            "Name": sessionDetails.Name,
            "Username": sessionDetails.Username,
        }));
    }

    /**
     * Returns the session
     * @returns {*}
     */
    static getSession(){
        try {
            let session = localStorage.getItem('auth');
            if (session != null) {
                return JSON.parse(session).Token;
            }
            else
            {
                return null;
            }
        }
        catch (e) {
            return null;
        }
    }

    static getSessionDetails(){
        try {
            let session = localStorage.getItem('auth');
            if (session != null)
                return JSON.parse(session);
            else
                return  null;
        }
        catch (e) {
            return null;
        }
    }

    /**
     * Evaluates if session is active or not
     * @returns {boolean}
     */
    static evaluateSession(){
        return (SessionManager.getSession() != null && SessionManager.getSession().length > 0);
    }

    /**
     * Deletes a session
     */
    static deleteSession(){
        localStorage.removeItem('auth');
    }
}

export default SessionManager;
