import React from "react";
import Table from "react-bootstrap/Table";
import { FormattedMessage } from "react-intl";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";

const DynamicTable = ({ data }) => {
  return (
    <div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="lev-id" />
            </th>
            <th>
              <FormattedMessage id="lev-type" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Spinner animation="grow" />
            </div>
          ) : (
            data.map((item, index) => {
              return (
                <tr key={index}>
                  <td key={(index + "ID").toString}>{item.id}</td>
                  <td key={index + "TYPE"}>{item.type}</td>
                  <td key={index + "generar"}>
                    <Link to={`/create-type?id=${item.id}`}>
                      <FormattedMessage id="unit-table-colum-opt-edit" />
                    </Link>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
};
export default DynamicTable;
