import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';
import TextInput from '../textInput';
import TextAreaInput from '../textAreaInput';
import TinyMCE from '../tinyMCE';
import {Button, Col, Row} from 'react-bootstrap';
import FileDropzone from '../dropzoneImplementation';
import {FaTrash, FaUpload} from 'react-icons/fa';


class AddedTextAndField extends Component{

  constructor(props){
    super(props);
    this.state={
      showUpload: false
    };
    this.toggleUpload = this.toggleUpload.bind(this);
  }

  toggleUpload(){
    this.setState({showUpload: !this.state.showUpload});
  }

  render(){
    const { index, name, desc, onChangeDesc, onChangeName, labelId, removeField, limitFromRules } = this.props;
    return(
      <Row>
          <Col xs={12}>
              <Row>
                  <Col xs={11}>
                      &bull;{' '}<b><FormattedMessage id={labelId}/> {index >= 0 ? index + 1 : ""}</b>
                      {' '}
                      <Button
                          variant="danger"
                          onClick={() => removeField(index)}>
                          <FaTrash/>{' '}
                      </Button>
                  </Col>
                  <Col xs={1} style={{textAlign:'right'}}>

                  </Col>
              </Row>
              <Row>
                  <Col xs={12} style={{textAlign:'right'}}>
                      {this.state.showUpload ?
                          <div>
                              <Button onClick={this.toggleUpload}><FaUpload/>{' '}<FormattedMessage id="button-toggle-hide"/></Button>
                              <FileDropzone
                                  parentStateKey={index}
                                  content={desc}
                                  onChange={onChangeDesc}
                                  grade={this.props.lessonGrade}
                                  subject={this.props.lessonSubject}
                                  fileRules={this.props.fileRules}
                              />
                          </div>
                          :
                          <div>
                              <Button onClick={this.toggleUpload}> <FaUpload/>{' '}<FormattedMessage id="button-toggle-show"/></Button>
                          </div>
                      }
                  </Col>
              </Row>
          </Col>
          <Col xs={12}>
            <TextInput
                labelId={labelId}
              textName={index}
              textType="text"
              index={index}
              content={name}
              onChange={onChangeName}
            />

          </Col>
        {
          this.props.isVr 
          ? <Col xs={12}><TextAreaInput
              isForVR
              textType="textarea" 
              parentStateKey={index}
              onChange={onChangeDesc} 
              content={desc}
              /></Col>
          :
          <Col xs={12}>
            <TinyMCE 
              parentStateKey={index} 
              content={desc}
              onChange={onChangeDesc}
              size={this.props.size}
            />
              {
                  limitFromRules ? <small style={ desc ? desc.length > limitFromRules ? {color: "red"} : {} : {}}>{desc ? desc.length : 0} / {limitFromRules}</small> : ""
              }
              {' '}

          </Col>
        }
      </Row>
    );
  }
}

export default AddedTextAndField;