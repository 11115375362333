import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from "../../server/interceptors";
import { apiAdditionalMaterialSearchUrl, DEFAULT_LANGUAGE} from '../../config';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import DynamicChecklist  from '../../components/dynamicChecklist';
import DynamicTable from '../../components/dynamicTableMaterials';
import { onSubjectCall } from '../../server/getLessonSubjectCall/lesson-subject-call';
import { onGradeCall } from '../../server/getLessonGradesCall/lesson-grade-call';
import {FaSearch, FaBook, FaBookOpen} from 'react-icons/fa'
import './view-additional-material.css';
import SpinnerLoader from '../../components/spinnerLoader/spinner-loader';
import SessionManager from "../../utils/authHandling/session-manager";

class ViewAdditionalMaterialPage extends Component{

    constructor(props){
        super(props);
        this.state={
            loading: false,
            subjectsFromServer: [],
            gradesFromServer: [],
            statusFromServer: [],
            selectedGrades: [],
            selectedAddresses: [],
            selectedSubjects: [],
            selectedStatus: [],
            tableData: [{}],
            tablePage: 1,
            totalItems: 0,
            orderBy: "ASC",
            nextButtonStatus: false,
            previousButtonStatus: false,
            selectedStandardId: "",
            selectedExpectationId: "",
            selectedSubCodeId: "",
            selectedSubMainSubCodeId: "",
            showSelect: typeof props.showSelect !== 'undefined' ? props.showSelect : false,
            lessonId: typeof props.lessonId !== 'undefined' ? props.lessonId : null,
            pageLessonAdditionalMaterial: "",
            subjectOpen: false,
            gradeOpen: false,
            statusOpen: false,
            codeOpen: false,
            projectOpen: false
        }
        this.onChange = this.onChange.bind(this);
        this.onChangeOrderAscending = this.onChangeOrderAscending.bind(this);
        this.onChangeOrderDescending = this.onChangeOrderDescending.bind(this);
        this.onDataCall = this.onDataCall.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.buttonStatusRefresh = this.buttonStatusRefresh.bind(this);
        this.toggleSubject = this.toggleSubject.bind(this);
        this.toggleGrade = this.toggleGrade.bind(this);
        this.selectAdditionalMaterial = this.selectAdditionalMaterial.bind(this);
        this.handlePagesSpecifiedLessonAdditionalMaterial = this.handlePagesSpecifiedLessonAdditionalMaterial.bind(this);
    }

    onChange(field, value){
        //Trick to add to state from multiple inputs with same function
        this.setState({[field]: value});
    }

    toggleSubject(){
        this.setState({subjectOpen: !this.state.subjectOpen});
    }

    toggleGrade(){
        this.setState({gradeOpen: !this.state.gradeOpen});
    }

    onChangeOrderAscending() {
        this.setState({
            orderBy: "ASC"
        }, function(){
            this.onDataCall();
        })
    }
    onChangeOrderDescending() {
        this.setState({
            orderBy: "DESC"
        }, function(){
            this.onDataCall();
        })
    }

    onDataCall(e){
        e.preventDefault();
        this.setState({loading: true}, function(){
            let self = this;
            const {
                selectedSubjects,
                selectedGrades,
                } = this.state;
            const params = {
                "SubjectCodes": selectedSubjects,
                "LevelCodes": selectedGrades,
                "PageNumber": 1,
                "Limit": 10,
            };

            axios.post(apiAdditionalMaterialSearchUrl, params, {
                headers: {
                    'Content-Type': 'application/json',                    
                }}).then(function (response){

                 if(typeof response.data !== 'undefined' && response.date !== null &&
                     typeof response.data.AdditionalMaterials !== undefined &&
                     response.data.AdditionalMaterials !== null &&
                     response.data.AdditionalMaterials.length > 0) {

                        const additionalMaterials = response.data.AdditionalMaterials.map((item) => ({ "id": item.AdditionalMaterialModel.Id,
                            "title": item.AdditionalMaterialModel.Title,
                            "subject": item.AdditionalMaterialModel.Subject,
                            "level": item.AdditionalMaterialModel.Level,
                            "url": item.AdditionalMaterialModel.Url,
                            "linkItems": [
                                {
                                    "materialId": item.AdditionalMaterialModel.Id,
                                    "link": "./edit-additional-material"
                                }
                            ]}));
                        self.setState({
                            tableData: additionalMaterials,
                            tablePage: 1,
                            totalItems: response.data.TotalItems,
                            loading: false
                        }, function(){
                            self.buttonStatusRefresh();
                        });
                    }
                 else {
                     self.setState({
                         tableData: [],
                         tablePage: 1,
                         totalItems: 0,
                         loading: false
                     })
                 }
            }).catch(error => {
                console.log(error.response.data.Messages);
            });//Refreshing button statuses
        });
    }

    onChangePageCall(){
        this.setState({loading: true}, function(){
            let self = this;
            const {
                selectedSubjects,
                selectedGrades,
                tablePage
            } = this.state;

            const params = {
                "SubjectCodes": selectedSubjects,
                "LevelCodes": selectedGrades,
                "FromPage": tablePage,
                "Limit": 10,
            };
            axios.post(apiAdditionalMaterialSearchUrl, params, {
                headers: {
                    'Content-Type': 'application/json',                    
                }}).then(function (response){

                if(typeof response.data !== 'undefined' && response.date !== null &&
                    typeof response.data.AdditionalMaterials !== undefined &&
                    response.data.AdditionalMaterials !== null &&
                    response.data.AdditionalMaterials.length > 0) {

                    const additionalMaterials = response.data.AdditionalMaterials.map((item) => ({ "id": item.AdditionalMaterialModel.Id,
                        "title": item.AdditionalMaterialModel.Title,
                        "subject": item.AdditionalMaterialModel.Subject,
                        "level": item.AdditionalMaterialModel.Level,
                        "url": item.AdditionalMaterialModel.Url,
                        "linkItems": [
                            {
                                "materialId": item.AdditionalMaterialModel.Id,
                                "link": "./edit-additional-material"
                            }
                        ]}));
                    self.setState({
                        tableData: additionalMaterials,
                        totalItems: response.data.TotalItems,
                        loading: false
                    });
                }
                else {
                    self.setState({
                        tableData: [],
                        tablePage: 1,
                        totalItems: 0,
                        loading: false
                    })
                }
            }).catch(error => {
                console.log(error.response.data.Messages);
            });
        });
    }

    nextPage(){
        this.setState({
            tablePage: this.state.tablePage + 1
        }, function() {
            this.onChangePageCall();
            this.buttonStatusRefresh();
        });
    }

    previousPage(){
        this.setState({
            tablePage: this.state.tablePage - 1
        }, function(){
            this.onChangePageCall();
            this.buttonStatusRefresh();
        });
    }

    buttonStatusRefresh = () => {
        // Checking if back button can go back
        if(this.state.tablePage === 1){
            this.setState({
                previousButtonStatus: true
            });
        } else{
            this.setState({
                previousButtonStatus: false
            });
        }
        if(this.getLastPage() === this.state.tablePage){
            this.setState({
                nextButtonStatus: true
            });
        } else{
            this.setState({
                nextButtonStatus: false
            });
        }
    }

    //Getting the last possible page
    getLastPage = () => {
        if(this.state.totalItems%10 > 0){
            return Math.floor(this.state.totalItems/10) + 1;
        } else {
            return this.state.totalItems/10;
        }
    }

    componentDidMount(){

        onSubjectCall(this.props.language).then(data => {
            this.setState({subjectsFromServer: data});
        });
        onGradeCall(this.props.language).then(data => {
            this.setState({gradesFromServer: data});
        });
        this.buttonStatusRefresh();
    }

    handlePagesSpecifiedLessonAdditionalMaterial(e){
        this.setState({
            pageLessonAdditionalMaterial: e.target.value,
        })
    }

    selectAdditionalMaterial(e){
        // send the selected id.
        this.props.updateParent(e, this.state.pageLessonAdditionalMaterial);
    }

    render(){

        return(
            <Container fluid className="card-alt">
                <Row>
                    <Col xs={12}>
                        <h3>
                            <FaBookOpen/><FormattedMessage id="am-title"/>{' '}
                        </h3>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col md="auto">
                        <h4><FormattedMessage id="vl-filters"/></h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            variant={this.state.subjectOpen ? "primary" : "outline-primary"}
                            onClick={this.toggleSubject}
                            aria-controls="set-subject-collapse"
                            aria-expanded={this.state.subjectOpen}>
                            <FormattedMessage id="vl-subject"/>
                        </Button>
                        {' '}
                        <Button
                            variant={this.state.gradeOpen ? "primary" : "outline-primary"}
                            onClick={this.toggleGrade}
                            aria-controls="set-grade-collapse"
                            aria-expanded={this.state.gradeOpen}>
                            <FormattedMessage id="vl-grade"/>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Collapse in={this.state.subjectOpen}>
                        <div id="set-subject-collapse" className='mt-3'>
                            <b><FormattedMessage id="vl-choose-subject"/></b>
                            <DynamicChecklist
                                items={this.state.subjectsFromServer}
                                parentState={this.state.selectedSubjects}
                                stateName="selectedSubjects"
                                onChange={this.onChange}
                                backgroundColor="#007bff"
                            />
                        </div>
                    </Collapse>
                    <Collapse in={this.state.gradeOpen}>
                        <div id="set-grade-collapse">
                            <b><FormattedMessage id="vl-choose-grade"/></b>
                            <DynamicChecklist
                                items={this.state.gradesFromServer}
                                parentState={this.state.selectedGrades}
                                stateName="selectedGrades"
                                onChange={this.onChange}
                                backgroundColor="#007bff"
                            />
                        </div>
                    </Collapse>
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col>
                        <Button variant="primary" type="submit" onClick={this.onDataCall}> <FaSearch/>{' '}<FormattedMessage id="vl-search"/></Button>
                    </Col>
                </Row>
                {this.state.showSelect ?
                    <div>
                        <FormattedMessage id="am-attach-lesson-page-instruction"/>
                    <Form.Control
                        name={"pages-lesson-additional-material"}
                        onChange={this.handlePagesSpecifiedLessonAdditionalMaterial}
                        value={this.state.pageLessonAdditionalMaterial}
                        type="text"
                    /></div> : null
                }
                {/* If there are entries for the table, display it */}
                {this.state.loading ? <div style={{marginTop: "40px"}}><SpinnerLoader/></div>
                    :
                    <div>
                        {this.state.totalItems > 0 &&
                        <div>
                            <hr/>
                            <div className="tableControls">
                                <p style={{textAlign: 'right'}}>
                                    <FormattedMessage id="vl-results"/> :  {this.state.totalItems}
                                </p>
                            </div>
                            <DynamicTable
                                data={this.state.tableData}
                                itemsPerPage={10}
                                language={this.props.language}
                                pageNumber={this.state.tablePage}
                                changePage={(direction) => {this.onChangePage(direction)}}
                                resultQuantity={this.state.totalItems}
                                showSelect={this.state.showSelect}
                                updateParent={this.selectAdditionalMaterial}
                            />
                            <div className="pageControls">
                                {/* Next page buttons */}
                                <Button disabled={this.state.previousButtonStatus} onClick={this.previousPage}>{'<'}</Button>
                                {this.state.tablePage}
                                <Button disabled={this.state.nextButtonStatus} onClick={this.nextPage}>{'>'}</Button>
                            </div>
                        </div>
                        }
                    </div>
                }
            </Container>
        );
    }
}

export default ViewAdditionalMaterialPage;