import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import DynamicRadioChecklist from '../dynamicRadioCheckList';
import { onAddressCall } from '../../server/getLessonAddressCall/lesson-address-call';


class RadioButtonAddress extends Component{
  constructor(props){
    super(props);
    this.state={
      selectedAddress: this.props.content,
      addressFromServer: []
    }
  }

  componentDidMount(){
    onAddressCall().then(data => {
      this.setState({addressFromServer: data})
    })
  }

  render(){
    return(
      <div>
        <b><FormattedMessage id="cl-directed-input"/></b>
        {this.props.isValidated ? <p style={{ color: "red"}}><FormattedMessage id="va-lesson-radios"/></p> : ""}
        <DynamicRadioChecklist 
          items={this.state.addressFromServer}
          parentState={this.state.selectedAddress}
          stateName={this.props.textName}
          onChange={this.props.onChange}
          content={this.props.content}
        />
      </div>
    );
  }
}

export default RadioButtonAddress;