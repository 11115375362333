import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import axios from "../../server/interceptors";
import {apiGradeCreateUrl, DEFAULT_LANGUAGE} from '../../config';
import {Button, Container} from 'react-bootstrap';
import './create-level-page-style.css';
import LevelDisplayView from "../../components/levelDisplayView";
import SessionManager from "../../utils/authHandling/session-manager";
import {FaSortNumericUp} from 'react-icons/fa'

class CreateLevelPage extends Component {

    constructor(props){
        super(props);
        this.state={
            loading: false,
            id: "",
            code: "",
            name: "",
            redirect: false,
        };
        this.onCreateCall = this.onCreateCall.bind(this);
        this.onChange = this.onChange.bind(this);
        this.redirectToEdit = this.redirectToEdit.bind(this);
    }

    onChange(field, value){
        this.setState({[field]: value});
    }

    onCreateCall(){

        // Validate
        if(this.state.code === ""){
            alert("Error: Level code is required (Example: K, 1, 2, 3...)./El código de nivel es requerido (Ejemplo: K, 1, 2, 3...).");
            return;
        }
        if(this.state.name === ""){
            alert("Error: The name is required./El nombre es requerido.");
            return;
        }

        this.setState({loading: true}, ()=>{
            // Call the API to insert
            let params = {
                Code: this.state.code,
                Name: this.state.name
            };

            axios.post(apiGradeCreateUrl, params,{
                headers: {
                    'Content-Type': 'application/json',                    
                }}).then((response)=>{

                    if(response.status === 201)
                    {
                        alert("Level successfully created./Nivel creado satisfactoriamente.");
                        this.setState({
                            redirect: true,
                        })
                    }
                    else
                    {
                        alert("Validate the fields. If the error continues, contact the administrator./Valide los campos. Si el error persiste, contacte al administrador.");
                    }
                this.setState({loading: false});
            }).catch(error => {

                if(error.response.status === 400)
                {
                    if(typeof error.response.data !== 'undefined' && typeof error.response.data.Messages !== 'undefined' && error.response.data.Messages.length > 0)
                    {
                        let message = "";
                        for(let i=0;i<  error.response.data.Messages.length; i++)
                        {
                            message += error.response.data.Messages[i];
                        }
                        alert(message);
                    }
                }
                else {
                    alert(`Error: ${error}`);
                }
                this.setState({loading: false});
            });
        });
    }

    redirectToEdit(){
        this.setState({redirectToEdit: true})
    }

    render(){
        if(this.state.redirect){
            return <Redirect to='/view-levels' />
        }

        return(
            <Container fluid className="card-alt">
              <div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <h3><FaSortNumericUp/><FormattedMessage id="lev-level"/></h3>
                            {this.state.loading ? <Button disabled variant="success">
                                <FormattedMessage id="lev-create"/></Button> :
                                <Button variant="success" onClick={this.onCreateCall}>
                                    <FormattedMessage id="lev-create"/>
                                </Button>}
                        </div>

                        <LevelDisplayView
                            isCreation={true}
                            language={this.props.language}
                            additionalMaterialContent={this.state}
                            onChange={this.onChange}
                        />
                    </div>
            </Container>
        );
    }
}

export default CreateLevelPage;