import React, { Component } from 'react';

import AudioPlayer from 'react-h5-audio-player';
import Dropzone from 'react-dropzone-uploader';
import { FormattedMessage, injectIntl } from 'react-intl';

import {DEFAULT_LANGUAGE, uploadLink} from '../../../../config';

//bootstrap
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import SessionManager from "../../../../utils/authHandling/session-manager";

class AudioUploader extends Component{
  constructor(props){
    super(props);
    this.state={
      audioSrc: "",
      uploadType: "audio",
      reuploadDisplay: false,
      askForReplace: false,
      audioHeaders: []
    }
    this.validateFiles = this.validateFiles.bind(this);
  }

  getUploadParams =  (headers) => {
    //In order to get the response URL: headers.xhr.response NOTE: this object is available when the call ends, it is async
    this.setState({dropzoneResponseObject: headers});
    let newState = this.state.audioHeaders;
    newState.push(headers);
    this.setState({audioHeaders: newState});
    return { url : uploadLink
      + "subject=html"
      + "&level="
      + "&uploadType=" + this.state.uploadType 
      + "&langCode=" + this.props.language,
      headers: {
        'Token': SessionManager.getSession(),
        'Accept-Language': localStorage.getItem('i18nextLng') == null ? DEFAULT_LANGUAGE : localStorage.getItem('i18nextLng')
      }
    } 
  }

  validateFiles(droppedFile){
    let appliedRules = {};
    let messages = [];
    if(this.props.fileRules === null || this.props.fileRules === undefined){
      alert("File rules are undefined");
    } else{
      this.props.fileRules.forEach((item) => {
        let typeString = item.Classification + "/" + item.Format;
        if(typeString === droppedFile.meta.type){
          appliedRules = item;
        } 
      })
      if(droppedFile.meta.size > appliedRules.MaxSize){
        messages.push("File size is too large");
      }
      if(messages.length > 0){
        droppedFile.remove();
        alert(messages);
      }
    }
  }

  handleSubmit = (files, allFiles) => {
    let response = JSON.parse(this.state.dropzoneResponseObject.xhr.responseText);
    this.setState({reuploadDisplay: false});
    allFiles.forEach(f => f.remove());
    this.props.changeParentState(this.props.stateName, response.Messages[0]);
  };


  render(){ 
    const { intl } = this.props;
    const baseStyle = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out'
    };
    return(
      <div>
        <div className='FixLabel'>{this.props.require === "1" ?  <span style={{color: "#f00"}}>*</span> : ""}<FormattedMessage id={this.props.label}/></div>
        {this.props.content ? 
          <div>
            <AudioPlayer
              // substitute for this.props.src
              src={this.props.content}
            />
            <Button 
              style={{marginTop: "10px"}} 
              onClick={() => {this.setState({reuploadDisplay: !this.state.reuploadDisplay, askForReplace: true})}}
              aria-controls="dropzone-area"
              aria-expanded={this.state.reuploadDisplay}
            >
                <FormattedMessage id="cl-lesson-intro-upload"/>
            </Button>
            <Collapse in={this.state.reuploadDisplay}>
              <div id="dropzone-area">
                {this.state.askForReplace ? 
                  <div style={{textAlign: "center"}}>
                    <p><FormattedMessage id="cl-lesson-audio-rewrite-warning"/></p>
                    <Button 
                      onClick={() => {this.setState({askForReplace: false})}}
                      style={{margin: "5px"}}><FormattedMessage id="cl-yes"/></Button>
                    <Button
                      onClick={() => {this.setState({reuploadDisplay: !this.state.reuploadDisplay})}}
                      style={{margin: "5px"}}
                      aria-controls="dropzone-area"
                      aria-expanded={this.state.reuploadDisplay}><FormattedMessage id="cl-no"/></Button>
                  </div> :
                  <Dropzone
                    accept={"audio/" + this.props.audioFormat}
                    key="Dropzone View"
                    validate={this.validateFiles}
                    getUploadParams={this.getUploadParams}
                    // onChangeStatus={this.handleChangeStatus}
                    onSubmit={this.handleSubmit}
                    styles={{ dropzone: baseStyle }}
                    inputContent={intl.formatMessage({id: "ct-audio-upload"}) + this.props.audioFormat + "  "}
                  />
                }
              </div>
            </Collapse>
          </div> : 
          <div>
            <Dropzone
              accept={"audio/" + this.props.audioFormat}
              key="Dropzone View"
              validate={this.validateFiles}
              getUploadParams={this.getUploadParams}
              // onChangeStatus={this.handleChangeStatus}
              onSubmit={this.handleSubmit}
              styles={{ dropzone: baseStyle }}
              inputContent={intl.formatMessage({id: "ct-audio-upload"}) + this.props.audioFormat + "  "}
            />
          </div>}
      </div>
    );
  }
}

export default injectIntl(AudioUploader);