import React, { Component} from 'react';
import Spinner from 'react-bootstrap/Spinner';

class SpinnerLoader extends Component{
  render(){
    return(
      <div 
        style={{
          display: "flex", 
          flexDirection: "row", 
          justifyContent: "center"
        }}
      >
        <Spinner animation="border" role="status"/>
      </div>
    );
  }
}

export default SpinnerLoader;