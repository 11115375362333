import React, { Component } from 'react';

import { getTemplateCodes } from '../../../../server/getTemplateCodeCall/template-code-call';
import { FormattedMessage } from 'react-intl';
import Image from "react-bootstrap/Image";
import Select from 'react-select';

class TemplateCodeSelector extends Component{

  constructor(props){
    super(props);
    this.state={
      templateCodesFromServer: [],
    };
    this.selectCode = this.selectCode.bind(this);
  }

  selectCode(codeObject){
    let templateCodeImage;
    for(let i = 0; i < this.state.templateCodesFromServer.length; i++){
      if(this.state.templateCodesFromServer[i].Id === codeObject.value){
        templateCodeImage = this.state.templateCodesFromServer[i].ImgTemplateRequirements;
      }
    }

    let templateCodeDesc; 
    for(let i = 0; i < this.state.templateCodesFromServer.length; i++){
      if(this.state.templateCodesFromServer[i].Id === codeObject.value){
        templateCodeDesc = this.state.templateCodesFromServer[i].Desc;
      } 
    }

    //This sets the validation Rules
    for(let i = 0; i < this.state.templateCodesFromServer.length; i++){
      if(this.state.templateCodesFromServer[i].Id === codeObject.value){
        this.props.setValidationRules(this.state.templateCodesFromServer[i]);
      }
    }
    

    this.setState({
      selectedCode: codeObject,
      templateImage: templateCodeImage,
      templateDesc: templateCodeDesc
    });
    this.props.changeParentState(this.props.stateName, codeObject.value);
  }

  componentDidMount(){
    getTemplateCodes(this.props.language).then(data => {
      let options = data.map((item) => {return {label: item.Name, value: item.Id}});
      this.setState({
        templateCodesFromServer: data,
        optionsForSelect: options
      });
    });
  }


  UNSAFE_componentWillUpdate(nextProps){
    if(!this.state.selectedCode){
      if(this.state.optionsForSelect){
        this.state.optionsForSelect.map((item) => {
          if(item.value === nextProps.content){
            this.selectCode(item);
          }
          return true; //For warning? arrow functions must return something
        });
      }
    }
  }

  render(){
    return(
      <div>
        <div className='FixLabel mb-3'>Template Code</div>
        <div style={{
          padding: "20px",
          backgroundColor: "#f2f6fb",
          borderRadius: "5px"}}
        >
          {/* Selector for the code */}
          <Select 
            value={this.state.selectedCode}
            onChange={this.selectCode}
            options={this.state.optionsForSelect}
          />
          {/* Image of the selected code */}
          <div style={{marginTop: "10px"}}>
            {this.state.templateImage ?
              <Image src={this.state.templateImage} fluid/>
            : 
            ""}
          </div>
          {/* Template code description */}
          <div>
            {this.state.templateDesc ? 
              <div style={{marginTop: "10px"}}>
                <h6><FormattedMessage id="ct-template-code-desc" /></h6>
                <p>{this.state.templateDesc}</p>
              </div>
            :
              ""
            }
          </div>
        </div>
      </div>
    );
  }
}

export default TemplateCodeSelector;