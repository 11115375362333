import React from 'react';
import {
    Card,
    Col,
    Container,
    Row,
    Button,
    FormLabel,
} from 'react-bootstrap';
import SelectorPermission from "../../components/rolesPermissionModule/selector-permission";
import axios from "../../server/interceptors";
import {apiGetPermissions, apiCreatePermissions, DEFAULT_LANGUAGE, apiModifyPermissions} from "../../config";
import SessionManager from "../../utils/authHandling/session-manager";
import {logoutUser} from "../../utils/authHandling/logout-user";
import {FormattedMessage} from "react-intl";
/**
 * The component (window) to handle permissions.
 */
class PermissionManager extends React.Component {

    constructor(props) {
        // Super is always called when defining the constructor of a subclass
        // All React component classes that have a constructor, should start it with a super(props) call.
        super(props);
        this.state = {
            id: null,
            loading: false,
            isEdit: false,
            addNew: false,
            resetBox: false,
            redirect: false,
            redoSearch: false,
            name: '',
            desc: '',
        };

        this.handleNew = this.handleNew.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    handleSelection(e) {
        this.setState({
            id: e.value,
            isEdit: true,
            resetBox: false,
            redoSearch: false
        },()=>{
            this.readData();
        });
    }

    readData(){
        if(this.state.id !== null)
        {
            this.setState({loading:true},()=> {
                this.setState({
                    loading: true,
                });
                let self = this;
                axios.get(`${apiGetPermissions}/${this.state.id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    }}).then((response)=>{

                    if(response.status === 401)
                    {
                        logoutUser();
                    }
                    if(response.data !== null && response.data.length > 0) {
                        this.setState({
                            name: response.data[0].Name,
                            desc: response.data[0].Desc
                        });
                    }
                }).catch(error => {
                    alert(error);
                });
            });
        }
    }

    saveChanges(){
        // Create
        if(this.state.id === null)
        {
            this.setState({loading: true}, ()=>{
                // Call the API to insert
                let params = {
                    Name: this.state.name,
                    Desc: this.state.desc,
                };

                axios.post(apiCreatePermissions, params,{
                    headers: {
                        'Content-Type': 'application/json',                        
                    }}).then((response)=>{

                    if(response.status === 201 ||  response.status === 200)
                    {
                        this.setState({
                            redoSearch: true,
                            id: null,
                            name: '',
                            desc:  '',
                            controller: '',
                            resetBox: true,
                        });
                        alert("Successfully created./La transacción fue procesada exitosamente.");
                    }
                    else
                    {
                        alert("Validate the fields. If the error continues, contact the administrator./Valide los campos. Si el error persiste, contacte al administrador.");
                    }
                    this.setState({loading: false});
                }).catch(error => {
                    if(error.response.status === 400)
                    {
                        if(typeof error.response.data !== 'undefined' && typeof error.response.data.Messages !== 'undefined' && error.response.data.Messages.length > 0)
                        {
                            let message = "";
                            for(let i=0;i<  error.response.data.Messages.length; i++)
                            {
                                message += error.response.data.Messages[i];
                            }
                            alert(message);
                        }
                    }
                    else {
                        alert(`Error: ${error}`);
                    }
                    this.setState({loading: false});
                });
            });
        }
        else
        {
            this.setState({loading: true}, ()=>{
                // Call the API to insert
                let params = {
                    Id: this.state.id,
                    Name: this.state.name,
                    Desc: this.state.desc,
                };

                axios.post(apiModifyPermissions, params,{
                    headers: {
                        'Content-Type': 'application/json',                        
                    }}).then((response)=>{
                    if(response.status === 201 ||  response.status === 200)
                    {
                        this.setState({
                            redoSearch: true,
                            id: null,
                            name: '',
                            desc:  '',
                            controller: '',
                            resetBox: true,
                        });
                        alert("Successful transaction./La transacción fue procesada exitosamente.");
                    }
                    else
                    {
                        alert("Validate the fields. If the error continues, contact the administrator./Valide los campos. Si el error persiste, contacte al administrador.");
                    }
                    this.setState({loading: false});
                }).catch(error => {
                    if(error.response.status === 400)
                    {
                        if(typeof error.response.data !== 'undefined' && typeof error.response.data.Messages !== 'undefined' && error.response.data.Messages.length > 0)
                        {
                            let message = "";
                            for(let i=0;i<  error.response.data.Messages.length; i++)
                            {
                                message += error.response.data.Messages[i];
                            }
                            alert(message);
                        }
                    }
                    else {
                        alert(`Error: ${error}`);
                    }
                    this.setState({loading: false});
                });
            });

        }

    }

    handleNew(){
        this.setState({
            addNew: true,
            id: null,
            name: '',
            desc:  '',
            resetBox: true,
        });
    }

    handleNameChange(e){
        this.setState({name: e.target.value, redoSearch: false});
    }

    handleDescriptionChange(e){
        this.setState({desc: e.target.value, redoSearch: false});
    }

    /**
     * Renders the main page
     * @returns {XML}
     */
    render() {

        return (
                <div className="container-padding animated delay-05s fadeIn">
                    <Container className="container-wrapper">
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <h2><FormattedMessage id="rol-permissions"/></h2>
                                        <small><FormattedMessage id="rol-permissions-subtitle"/></small>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>

                                            <Col xs={12} md={6}>
                                                <FormLabel>
                                                    <FormattedMessage id="rol-select-to-edit"/>
                                                </FormLabel>
                                                <SelectorPermission resetBox={this.state.resetBox} redoSearch={this.state.redoSearch} disabled={this.state.addNew} updateParent={this.handleSelection}/>
                                            </Col>

                                            <Col>
                                                <FormLabel/>
                                                <br/>
                                                <button className="btn-transparent font-weight-bold text-color-dark-blue" onClick={this.handleNew}>
                                                    <FormattedMessage id="rol-add-new"/>
                                                </button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <FormLabel>
                                                    * <FormattedMessage id="rol-name"/>
                                                </FormLabel>
                                                <input className="form-control" maxLength="45" value={this.state.name} type="text" name="name" disabled={!this.state.isEdit && !this.state.addNew ? "disabled" : ""}
                                                       id="name" onChange={this.handleNameChange}/>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} md={6}>
                                                <FormLabel>
                                                    * <FormattedMessage id="rol-description"/>
                                                </FormLabel>
                                                <textarea  className="form-control" value={this.state.desc} name="name" disabled={!this.state.isEdit && !this.state.addNew ? "disabled" : ""}
                                                        id="name" onChange={this.handleDescriptionChange}/>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="text-right">
                                                <Button color="success" disabled={this.state.desc === "" || this.state.name === "" ? "disabled" : ""} onClick={this.saveChanges}>
                                                    <FormattedMessage id="rol-submit"/>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <hr/>
                    </Container>
            </div>
        )
    };
}

export default PermissionManager;