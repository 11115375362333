import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import QuizInstructions from './subComponents/quizInstructions';
import ExtraContent from './subComponents/extraContent';
import TextInput from '../../../../components/textInput';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class QuizInfo extends Component{
  constructor(props){
    super(props);
    this.state={
      isExam: null,
      quizType: "",
      quizLevel: "",
      quizInstructions: "",
      hasExtraContent: false,
      extraContent: ""
    }
    this.onChange = this.onChange.bind(this);
    this.changeIsExam = this.changeIsExam.bind(this);
    this.changeQuizType = this.changeQuizType.bind(this);
    this.changeLevel = this.changeLevel.bind(this);
    this.changeInstructions = this.changeInstructions.bind(this);
    this.extraContentToggle = this.extraContentToggle.bind(this);
    this.changeExtraContent = this.changeExtraContent.bind(this);
  }

  onChange(field, value){
    this.setState({[field]: value});
    this.props.onChange(field, value);
  }

  changeIsExam(content){
    this.props.onChange("quizIsExam", content);
  }
  changeQuizType(content){
    this.props.onChange("quizType", content);
  }
  changeLevel(content){
    this.props.onChange("quizLevelOfKnowledge", content);
  }
  changeInstructions(content){
    this.props.onChange("quizInstructions", content);
  }
  extraContentToggle(){
    this.setState({
      hasExtraContent: !this.state.hasExtraContent
    }, function() {
      if(!this.state.hasExtraContent){
        this.setState({
          extraContent: ""
        });
      }
    });
  }
  changeExtraContent(index, content){
    this.props.onChange("quizExtraInfo", content);
  }

  getQuizType(typeInt){
    switch(typeInt){
      case '1':
        return <b><FormattedMessage id="qp-isMultipleChoice"/></b>
      case '2': 
        return <b><FormattedMessage id="qp-isDragAndDrop"/></b>
      case '3':
        return <b><FormattedMessage id="qp-isFreeText"/></b>
      default:
        return typeInt
    }
  }

  getIsExamType(isExam){
    switch(isExam){
      case '0':
        return <b><FormattedMessage id="qp-isPractice"/></b>
      case '1':
        return <b><FormattedMessage id="qp-isExam"/></b>
      case '2':
        return <b><FormattedMessage id="qp-isPreExam"/></b>
      case '3':
        return <b><FormattedMessage id="qp-isPostExam"/></b>
      default:
        return isExam
      }
  }

  render(){
    return(
      <div className="card-alt-2">
        <h4>{this.props.state.lessonTitle} ({this.props.state.lessonId})</h4>
        {/* <ReadyToPublishFlag
          onChange={this.onChange}
          ready={this.props.state.quizReadyToPublish}
        />*/}
        <TextInput 
          onChange={this.props.onChange} 
          labelID="qp-title-input" 
          textName="quizTitle"
          content={this.props.state.quizTitle}
          limitFromRules={this.props.state.quizRules ? this.props.state.quizRules.TitleMaxLength : null}
        />
        <div style={{backgroundColor: "#eee", padding: "5px", margin: "10px"}}>
          <Container>
            <Row>
              <Col>
                <FormattedMessage id="qp-evaluation"/>:
              </Col>
              <Col>
                  {this.getIsExamType(this.props.state.quizIsExam)}
              </Col>
            </Row>
            <Row>
              <Col>
                <FormattedMessage id="qp-level-of-knowledge"/>:
              </Col>
              <Col>
                <b>{this.props.state.selectedLevelOfKnowledgeString}</b>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormattedMessage id="qp-type"/>:
              </Col>
              <Col>
                {this.getQuizType(this.props.state.quizType)}
              </Col>
            </Row>
          </Container>
        </div>
        <QuizInstructions
          onChange={this.changeInstructions}
          lessonGrade={this.props.state.lessonGrade}
          lessonSubject={this.props.state.lessonSubject}
          content={this.props.state.quizInstructions}
          language={this.props.language}
          limitFromRules={this.props.state.quizRules ? this.props.state.quizRules.InstructionMaxLength : null}
          fileRules={this.props.fileRules}
        />
        <ExtraContent
          extraContentToggle={this.extraContentToggle}
          lessonSubject={this.props.state.lessonSubject}
          lessonGrade={this.props.state.lessonGrade}
          hasExtraContent={this.state.hasExtraContent}
          changeExtraContent={this.changeExtraContent}
          content={this.props.state.quizExtraInfo}
          language={this.props.language}          
          limitFromRules={this.props.state.quizRules ? this.props.state.quizRules.ExtraInfoMaxLength : null}
          fileRules={this.props.fileRules}
        />
      </div>
    );
  }
}

export default QuizInfo;