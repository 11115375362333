import React, { Component } from 'react';
import ValidatedTextInput from '../../validatedTextField';
import {Col, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {onSubjectCall} from "../../../server/getLessonSubjectCall/lesson-subject-call";
import RadioButtonSubjects from "../../radioButtonSubjects";
import RadioButtonGrades from "../../radioButtonGrades";
import StandardCodeSelector from "../../standardCodeSelector";
import axios from "../../../server/interceptors";
import {apiGetExpectationById, DEFAULT_LANGUAGE} from "../../../config";
import SessionManager from "../../../utils/authHandling/session-manager";
import Button from "react-bootstrap/Button";

class CreateSection extends Component{

    constructor(props){
        super(props);
        this.state = {
            dataState: this.props.state,
            displayModify: typeof this.props.isCreation !== 'undefined' && this.props.isCreation != null ? this.props.isCreation : false,
            subjectsFromServer: [],
            selectedGrade: [],
            code: "",
            languageSP: "",
            languageEN: "",
            selectedSubject: [],
            isCreation: typeof this.props.isCreation !== 'undefined' && this.props.isCreation != null ? this.props.isCreation : false,
            infoValidate: typeof this.props.infoValidate !== 'undefined' && this.props.infoValidate != null ? this.props.infoValidate : false
        };
        this.onChange = this.onChange.bind(this);
        this.displayModify = this.displayModify.bind(this);
    }

    onChange(field, value){
        this.setState({[field]: value}, () => { this.props.parentStateHandle(field, value) });
    }

    callExpectation(idToFind){

        axios.get(`${apiGetExpectationById}/${idToFind}`, {
            headers: {
                'Content-Type': 'application/json',                
            }}).then((response)=>{
            this.setState({
                expectationCode: response.data[0].CodeMainModel.Code,
                expectationDesc: response.data[0].CodeMainLanguageModels.find(x => x.LessonLangCode === "es").Desc + "/" + response.data[0].CodeMainLanguageModels.find(x => x.LessonLangCode === "en").Desc
            });
        }).catch(error => {
            console.log(error.response.data.Messages);
        });
    }

    componentWillReceiveProps(nextProps){
        let initExpectation = this.state.selectedExpectationId;
        this.setState({
            selectedSubject: nextProps.state.selectedSubject,
            selectedGrade: nextProps.state.selectedGrade,
            code: nextProps.state.code,
            languageSP: nextProps.state.languageSP ? nextProps.state.languageSP : "",
            languageEN: nextProps.state.languageEN ? nextProps.state.languageEN : "",
            selectedStandardId: nextProps.state.selectedStandardId ? nextProps.state.selectedStandardId : "",
            selectedExpectationId: nextProps.state.selectedExpectationId ? nextProps.state.selectedExpectationId : "",
            selectedSubCodeId: nextProps.state.selectedSubCodeId ? nextProps.state.selectedSubCodeId : "",
            selectedSubMainSubCodeId : nextProps.state.selectedSubMainSubCodeId ? nextProps.state.selectedSubMainSubCodeId : "",
        }, function() {
            if(typeof nextProps.state.selectedExpectationId !== 'undefined' && nextProps.state.selectedExpectationId !== "" && initExpectation !== nextProps.state.selectedExpectationId){
                this.callExpectation(nextProps.state.selectedExpectationId);
            }
        });
    }

    componentDidMount() {
        onSubjectCall(this.props.language).then(data => {
            this.setState({subjectsFromServer: data});
        });
    }


    displayModify(){
        this.setState({
            displayModify: true,
        })
    }

    render(){
        const { state } = this.props;
        return(
            <div className="card-alt-2">
                {
                    !this.state.isCreation ?
                        <Row>
                            <Col xs={3} md={2}>
                                <b><FormattedMessage id="std-code"/></b>
                            </Col>
                            <Col xs={9} md={10}>
                                <b>{this.state.code}</b>
                            </Col>
                        </Row>:
                        <ValidatedTextInput
                            labelID="std-code"
                            textName="code"
                            onChange={this.onChange}
                            content={state.code}
                            isValidated={state.infoValidate}
                            limitFromRules={4}
                        />
                }
                <Row>
                    <Col>
                        <hr/>
                    </Col>
                </Row>

                {!this.state.isCreation && !this.state.displayModify?
                    <>
                        <Row>
                            <Col xs={3} md={2}>
                                <b><FormattedMessage id="std-expectation"/></b>
                            </Col>
                            <Col xs={9} md={10}>
                                <b>{this.state.expectationCode}</b>
                                <br/>
                                {this.state.expectationDesc}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} md={2}/>
                            <Col>
                                <Button className="info" onClick={this.displayModify}>
                                    <small>
                                        <FormattedMessage id="std-modify-expectation"/>
                                    </small>
                                </Button>
                            </Col>

                        </Row>
                    </>: null
                }

                {this.state.displayModify ?
                    <>
                        <Row>
                            <Col xs={3} md={2}>
                                <b><FormattedMessage id="std-select-following-details"/></b>
                            </Col>
                            <Col>
                                <RadioButtonSubjects
                                    textName="selectedSubject"
                                    onChange={this.onChange}
                                    content={this.props.state.selectedSubject}
                                    language={this.props.language}
                                    isValidated={null}
                                />
                                <RadioButtonGrades
                                    textName="selectedGrade"
                                    onChange={this.onChange}
                                    content={this.props.state.selectedGrade}
                                    language={this.props.language}
                                    isValidated={null}
                                />
                                <StandardCodeSelector
                                    onChange={this.onChange}
                                    subjectCode={this.props.state.selectedSubject}
                                    grade={this.props.state.selectedGrade}
                                    language={this.props.language}
                                    selectedStandardId={this.state.selectedStandardId}
                                    selectedExpectationId={this.state.selectedExpectationId}
                                    selectedSubCodeId={this.state.selectedSubCodeId}
                                    selectedSubMainSubCodeId={this.state.selectedSubMainSubCodeId}
                                    loading={this.props.state.loadingLessonData}
                                    showExpectation={true}
                                    showIndicator={false}
                                    showSubIndicator={false}
                                    loadingFromServer
                                />
                            </Col>
                        </Row>
                    </> : null}

                <Row>
                    <Col>
                        <hr/>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <ValidatedTextInput
                            labelID="std-description-en"
                            textName="languageEN"
                            onChange={this.onChange}
                            content={this.state.languageEN}
                            isValidated={state.infoValidate}
                            limitFromRules={null}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ValidatedTextInput
                            labelID="std-description-sp"
                            textName="languageSP"
                            onChange={this.onChange}
                            content={this.state.languageSP}
                            isValidated={state.infoValidate}
                            limitFromRules={null}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CreateSection;