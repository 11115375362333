import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

/** Edited by POR 2019.08.08 **/
class DynamicUserTable extends Component{

  constructor(props){
    super(props);
    this.saveUserId = this.saveUserId.bind(this);
  }

  saveUserId(id){
    sessionStorage.setItem("lastUser", id);
  }

  render(){
    const { data } = this.props;
    return(
      <div>
        <Table>
          <thead>
            <tr>
              <th><FormattedMessage id="u-id"/></th>
              <th><FormattedMessage id="u-username"/></th>
              <th><FormattedMessage id="u-first-name"/></th>
              <th><FormattedMessage id="u-last-name"/></th>
              <th><FormattedMessage id="u-last-session"/></th>
              <th><FormattedMessage id="u-date-created"/></th>
              <th><FormattedMessage id="u-options"/></th>
            </tr>
          </thead>
          <tbody>
            {
              data.length === 0 ? <tr>
                <td><Spinner animation="grow"/></td>
                <td><Spinner animation="grow"/></td>
                <td><Spinner animation="grow"/></td>
                <td><Spinner animation="grow"/></td>
                <td><Spinner animation="grow"/></td>
                <td><Spinner animation="grow"/></td>
                <td><Spinner animation="grow"/></td>
              </tr> :
              data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td id={index + "ID"}>{item.userId}</td>
                    <td id={index + "USERNAME"}>{item.userName}</td>
                    <td id={index + "FIRSTNAME"}>{item.firstName}</td>
                    <td id={index + "LASTNAME"}>{item.lastName}</td>
                    <td id={index + "LASTSESSION"}>{item.lastSession}</td>
                    <td id={index + "DATECREATED"}>{item.dateCreated}</td>
                    <td>
                      <Link  
                        key={index} 
                        onClick={() => this.saveUserId(item.userId)} 
                        to={{pathname: "user", data: { userId: item.userId } }}>
                          <FormattedMessage id="u-view-user"/>
                      </Link> 
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>
    );
  }
}

export default DynamicUserTable;