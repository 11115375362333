import axios from "../interceptors";
import { apiGetTemplateDetails } from "../../config";
import { logoutUser } from "../../utils/authHandling/logout-user";

export const getTemplateById = async function (language, id, type) {
  try {
    const response = await axios.get(
      apiGetTemplateDetails + "/" + id + "/" + type,
      {
        headers: {
          "Content-Type": "application/json",          
        },
      }
    );
    if (response.status === 200 || response.status === 201) {
      return response.data.TemplateModifierRequestModel;
    } else if (response.status === 401) {
      alert("Session is not valid");
      logoutUser();
    }
  } catch (error) {
    console.log(error.response.data.Messages);
  }
};
