export const getDescContent = function(data){
  let descContent = []
  data.forEach(element => {
    descContent.push({Desc: element.Desc});
  });
  return descContent;
}

export const getDescAndNameContent = function(data){
  let descAndNameContent = []
  data.forEach(element => {
    if(element.Frames !== null || element.Frames !== undefined){
      descAndNameContent.push({Desc: element.Desc, Name: element.Name, Frames: element.Frames});
    } else{
      descAndNameContent.push({Desc: element.Desc, Name: element.Name});
    }
  });
  return descAndNameContent;
}

export const getTaxVerbIds = function(data){
  let taxVerbContent = []
  data.forEach(element => {
    taxVerbContent.push(element.TaxVerbId);
  })
  return taxVerbContent;
} 

export const getUnityIds = function(data){
  let unityIds = []
  data.forEach(element => {
    unityIds.push(element.UnityId);
  })
  return unityIds
}

export const setUnityIds = function(data){
  let thing = []
  data.forEach(item => {
    thing.push({"UnityId": item});
  })
  return thing
}

export const setTaxVerbIds = function(data){
  let arr = [];
  data.forEach(item => {
    arr.push({"TaxVerbId": item});
  })
  return arr;
}