import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';
import Form from 'react-bootstrap/Form';

class LabelAndEditor extends Component{
  constructor(props){
    super(props);
    this.onTextChange = this.onTextChange.bind(this);
  }

  onTextChange(event){
    this.props.changeParentState(event.target.name, event.target.value);
  }
  
  
  render(){
    return(
      <div>
        <div className='FixLabel'>{this.props.require === "1" ?  <span style={{color: "#f00"}}>*</span> : ""}<FormattedMessage id={this.props.label}/></div>
        <div>
          <Form.Control
            type="text"
            name={this.props.stateName}
            onChange={this.onTextChange}
            value={this.props.content === null ? "" : this.props.content}
          />
        </div>
      </div>
    );
  }
}

export default LabelAndEditor;