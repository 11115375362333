import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import axios from "../../server/interceptors";
import {apiAdditionalMaterialCreateUrl, DEFAULT_LANGUAGE} from '../../config';
import {Button, Container} from 'react-bootstrap';
import './create-additional-material-page-style.css';
import AdditionalMaterialDisplayView from "../../components/additionalMaterialDisplayView";
import SessionManager from "../../utils/authHandling/session-manager";
import {FaBookOpen} from 'react-icons/fa'

class CreateAdditionalMaterialPage extends Component {

    constructor(props){
        super(props);
        this.state={
            loading: false,
            additionalMaterialSubject: "",
            additionalMaterialGrade: "",
            additionalMaterialTitle: "",
            additionalMaterialDescription: "",
            additionalMaterialCoverURL: "",
            additionalMaterialURL: "",
            additionalMaterialCoverURLSrc: "",
            additionalMaterialForDownload: "0", /* DEFAULT */
            redirect: false,
        };
        this.onCreateAdditionalMaterialCall = this.onCreateAdditionalMaterialCall.bind(this);
        this.onChange = this.onChange.bind(this);
        this.redirectToEdit = this.redirectToEdit.bind(this);
    }

    onChange(field, value){
        //Trick to add to state from multiple inputs with same function
        if(field === "additionalMaterialURLSrc")
        {
            this.setState({additionalMaterialURL: value})
        }
        if(field === "additionalMaterialCoverURLSrc")
        {
            this.setState({additionalMaterialCoverURL: value})
        }
        this.setState({[field]: value});
    }

    onCreateAdditionalMaterialCall(){

        // Validate
        if(this.state.additionalMaterialSubject === ""){
            alert("Error: Subject is required./La materia es requerida.");
            return;
        }
        if(this.state.additionalMaterialGrade === ""){
            alert("Error: The level is required./El grado es requerido.");
            return;
        }
        if(this.state.additionalMaterialTitle === "" || this.state.additionalMaterialDescription === ""){
            alert("Error: The title and the description is required./El título y la descripción son requeridos.");
            return;
        }
        if(this.state.additionalMaterialURL === ""){
            alert("Error: The link of the material is required./El enlace del material es requerido.");
            return;
        }

        this.setState({loading: true}, ()=>{
            // Call the API to insert
            let params = {
                Url: this.state.additionalMaterialURL,
                UrlCover: this.state.additionalMaterialCoverURL,
                Subject: this.state.additionalMaterialSubject,
                Level: this.state.additionalMaterialGrade,
                Title: this.state.additionalMaterialTitle,
                Description: this.state.additionalMaterialDescription,
                ForDownload: this.state.additionalMaterialForDownload
            };

            axios.post(apiAdditionalMaterialCreateUrl, params,{
                headers: {
                    'Content-Type': 'application/json',                    
                }}).then((response)=>{

                    if(response.status === 201)
                    {
                        alert("Material successfully created./Material creado satisfactoriamente.");
                        this.setState({
                            redirect: true,
                        })
                    }
                    else
                    {
                        alert("Validate the fields. If the error continues, contact the administrator./Valide los campos. Si el error persiste, contacte al administrador.");
                    }
                this.setState({loading: false});
            }).catch(error => {
                alert(`Error: ${error}`);
                this.setState({loading: false});
            });
        });
    }

    redirectToEdit(){
        this.setState({redirect: true})
    }

    render(){
        if(this.state.redirect){
            return <Redirect to='/additional-material' />
        }
        return(
            <Container fluid className="card-alt">
              <div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <h3><FaBookOpen/><FormattedMessage id="am-title"/></h3>
                            {this.state.loading ? <Button disabled variant="success">
                                <FormattedMessage id="am-create"/></Button> :
                                <Button variant="success" onClick={this.onCreateAdditionalMaterialCall}>
                                    <FormattedMessage id="am-create"/>
                                </Button>}
                        </div>

                        <AdditionalMaterialDisplayView
                            isCreation={true}
                            language={this.props.language}
                            additionalMaterialContent={this.state}
                            onChange={this.onChange}
                        />
                    </div>
            </Container>
        );
    }
}

export default CreateAdditionalMaterialPage;