import React, { Component } from 'react';
import ValidatedTextInput from '../../validatedTextField';
import {Col, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {onSubjectCall} from "../../../server/getLessonSubjectCall/lesson-subject-call";
import { Multiselect } from 'multiselect-react-dropdown';

class CreateSection extends Component{
  
  constructor(props){
    super(props);
    this.state = {
        dataState: this.props.state,
        subjectsFromServer: [],
        selectedSubjectsAlt:[],
        code: "",
        name: "",
        year: "",
        languageSP: "",
        languageEN: "",
        selectedSubjects: [],
        isCreation: typeof this.props.isCreation !== 'undefined' && this.props.isCreation != null ? this.props.isCreation : false,
        infoValidate: typeof this.props.infoValidate !== 'undefined' && this.props.infoValidate != null ? this.props.infoValidate : false,

    };
    this.onChange = this.onChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }
  
  onChange(field, value){
    this.setState({[field]: value}, () => { this.props.parentStateHandle(field, value) });
  }

    onSelectChange(selectedList, selectedItem) {
        let selectedSubjects = [];
        for(let i =0; i < selectedList.length; i++)
        {
            selectedSubjects.push(selectedList[i].id);
        }
        this.setState({
            selectedSubjects: selectedSubjects
        },()=>{
            this.props.parentStateHandle("selectedSubjects", selectedSubjects)
        });
    }

    onRemove(selectedList, removedItem) {
        let selectedSubjects = [];
        for(let i =0; i < selectedList.length; i++)
        {
            selectedSubjects.push(selectedList[i].id);
        }
        this.setState({
            selectedSubjects: selectedSubjects,
            selectedSubjectsAlt: selectedList,
        },()=>{
            this.props.parentStateHandle("selectedSubjects", selectedSubjects)
        });
    }
  
  componentWillReceiveProps(nextProps){
    this.setState({
        selectedSubjects: nextProps.state.selectedSubjects,
        code: nextProps.state.code,
        name: nextProps.state.name ? nextProps.state.name : "",
        year: nextProps.state.year ? nextProps.state.year : "",
        languageSP: nextProps.state.languageSP ? nextProps.state.languageSP : "",
        languageEN: nextProps.state.languageEN ? nextProps.state.languageEN : "",
    });
  }

  componentDidMount() {
      onSubjectCall(this.props.language).then(data => {
          let options = [];
          for(let i=0; i < data.length; i++){
              options.push({
                  name: data[i].title, id: data[i].code});
          }
          this.setState({subjectsFromServer: data, subjectsFromServerAlt: options});
          if(typeof this.state.selectedSubjects !== 'undefined' && this.state.selectedSubjects !== null && this.state.selectedSubjects.length > 0)
          {
              let selectedSubjectsAlt = [];
              for(let i=0; i < this.state.selectedSubjects.length;i++)
              {
                  selectedSubjectsAlt.push(options.find(x => x.id === this.state.selectedSubjects[i]));
              }
              this.setState({
                  selectedSubjectsAlt: selectedSubjectsAlt,
              });
          }
      });
  }

    render(){
    const { state } = this.props;
    return(
      <div className="card-alt-2">
          {
              !this.state.isCreation ?
                  <Row>
                  <Col xs={3} md={2}>
                      <b><FormattedMessage id="std-code"/></b>
                  </Col>
                  <Col xs={9} md={10}>
                  <b>{this.state.code}</b>
                  </Col>
                  </Row>:
                  <ValidatedTextInput
                      labelID="std-code"
                      textName="code"
                      onChange={this.onChange}
                      content={state.code}
                      isValidated={state.infoValidate}
                      limitFromRules={4}
                  />
          }
          <Row>
              <Col>
                  <ValidatedTextInput
                      labelID="std-name"
                      textName="name"
                      onChange={this.onChange}
                      content={state.name}
                      isValidated={state.infoValidate}
                      limitFromRules={40}
                  />
              </Col>
          </Row>
          <Row>
            <Col>
                <ValidatedTextInput
                    labelID="std-year"
                    textName="year"
                    onChange={this.onChange}
                    content={state.year}
                    isValidated={state.infoValidate}
                    limitFromRules={4}
                />
            </Col>
          </Row>
          <Row>
              <hr/>
          </Row>
          <Row>
              <Col xs={3} md={2}>
                  <b><FormattedMessage id="std-select-subjects"/></b>
              </Col>
              <Col>
                  <Multiselect
                      options={this.state.subjectsFromServerAlt} // Options to display in the dropdown
                      selectedValues={this.state.selectedSubjectsAlt} // Preselected value to persist in dropdown
                      onRemove={this.onRemove}
                      onSelect={this.onSelectChange} // Function will trigger on select event
                      stateName="selectedSubjects"
                      displayValue="name" // Property name to display in the dropdown options
                  />
              </Col>
          </Row>
          <Row>
              <hr/>
          </Row>
            <Row>
                <Col>
                    <ValidatedTextInput
                        labelID="std-description-en"
                        textName="languageEN"
                        onChange={this.onChange}
                        content={this.state.languageEN}
                        isValidated={state.infoValidate}
                        limitFromRules={null}
                    />
                </Col>
            </Row>
          <Row>
              <Col>
                  <ValidatedTextInput
                      labelID="std-description-sp"
                      textName="languageSP"
                      onChange={this.onChange}
                      content={this.state.languageSP}
                      isValidated={state.infoValidate}
                      limitFromRules={null}
                  />
              </Col>
          </Row>
      </div>
    );
  }
}

export default CreateSection;