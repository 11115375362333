import React, { Component } from 'react';
import axios from "../../server/interceptors";
import { FormattedMessage } from 'react-intl';
import {apiUnitsUrl, DEFAULT_LANGUAGE} from '../../config';
import SessionManager from "../../utils/authHandling/session-manager";
import DynamicChecklist from '../dynamicChecklist';
import {Col, Row} from "react-bootstrap";

class RadioButtonUnits extends Component{
  constructor(props){
    super(props);
    this.state = {
      unitsFromServer: [],
      selectedUnit: this.props.content
    };
    this.onUnitsCall = this.onUnitsCall.bind(this);
  }

  onUnitsCall(){
    const self = this;
    this.setState({unitsFromServer: []}, function(){
      axios.get(apiUnitsUrl + "/" + this.state.levelCode + "/" + this.state.subjectCode, {
        headers: {
          'Content-Type': 'application/json',          
        }}).then(function (response){
        if(response.status === 200 || response.status === 201){
          const unitsStrings = response.data.map((item) => ({title: item.Name, code: item.Id}));
          self.setState({
            unitsFromServer: unitsStrings
          }, function(){
            if(!self.state.hasLoaded){
              self.setState({hasLoaded: true});
            } else{
              self.props.onChange(this.props.textName, []);
            }
          });
        } else{
          alert("Call Error: " + response.status);
        }
      }).catch(error => {
        console.log(error.response.data.Messages);
      });
    });
  }

  componentWillReceiveProps(){
    if(this.props.levelCode !== "" && this.props.subjectCode !== ""){
      if(this.props.levelCode !== this.state.levelCode || this.props.subjectCode !== this.state.subjectCode){
        this.setState({levelCode: this.props.levelCode, subjectCode: this.props.subjectCode}, () => { this.onUnitsCall(); });
      }
    }
  }
  render(){
    return(
       <Row>
         <Col xs={3} md={2}>
        {this.state.unitsFromServer.length > 0 ? <b><FormattedMessage id="cl-unit-input"/></b> : ""}
        </Col>
         <Col xs={9} md={10}>
         <DynamicChecklist
          dontLoad
          items={this.state.unitsFromServer}
          parentState={this.props.content}
          stateName={this.props.textName}
          onChange={this.props.onChange}
          content={this.props.content}
        />
         </Col>
      </Row>
    );
  }
}

export default RadioButtonUnits;