import axios from "../interceptors";
import { apiGetPdfLink } from "../../config";
import { logoutUser } from "../../utils/authHandling/logout-user";

export const getLessonPdfLink = async function (language, lessonId) {
  try {
    const response = await axios.get(apiGetPdfLink + lessonId, {
      headers: {
        "Content-Type": "application/json",        
      },
    });
    if (response.status === 200 || response.status === 201) {
      //Session and transaction is valid, return the values
      return response.data;
    } else if (response.status === 401) {
      alert("Session is not valid.");
      logoutUser();
    } else {
      alert(response.data.Messages);
    }
  } catch (error) {
    console.log(error.response.data.Messages);
  }
};
