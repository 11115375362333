import React, { Component } from 'react';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavLink from 'react-bootstrap/NavLink';

class TabNavigator extends Component{
  render(){
    const { sections, defaultActive } = this.props;

    return(
      <div>
        <Tab.Container id="left-tabs" defaultActiveKey={defaultActive}>
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                {sections.map((item) => {
                  return (
                    <Nav.Item key={item.sectionTitle}>
                      <NavLink
                        style={item.isValidated ? {color: "red"} : {} }
                        eventKey={item.sectionTitle}
                        onSelect={item.onSelect ? item.onSelect : ""}>
                          {item.sectionTitle}
                      </NavLink>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                {sections.map((item) => {
                  return (
                    <Tab.Pane key={item.sectionTitle} eventKey={item.sectionTitle}>
                      {item.sectionContent}
                    </Tab.Pane>
                  );
                })}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
}

export default TabNavigator;