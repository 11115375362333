import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from "../../server/interceptors";
import {apiAuditUrl, DEFAULT_LANGUAGE} from '../../config';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {FaSearch} from 'react-icons/fa'
import SpinnerLoader from '../../components/spinnerLoader/spinner-loader';
import SessionManager from "../../utils/authHandling/session-manager";

class ViewAuditPage extends Component{

    constructor(props){
        super(props);
        this.state={
            loading: false,
            searchForId: null,
            downloadLink: null,
        };
        this.onChange = this.onChange.bind(this);
        this.onChangeId = this.onChangeId.bind(this);
        this.onLessonsCall = this.onLessonsCall.bind(this);
    }

    onChange(field, value){
        //Trick to add to state from multiple inputs with same function
        this.setState({[field]: value});
    }

    onChangeId(event){
        this.setState({ searchForId: event.target.value });
    }


    onLessonsCall(e){
        e.preventDefault();
        let self = this;
        this.setState({loading: true, downloadLink: null}, function(){
            const {
                searchForId} = this.state;

            axios.get(apiAuditUrl + "/" + searchForId,
                {
                    headers: {                        
                        'Content-Type': 'application/json',                        
                    }
                }
                ).then(function (response){
                self.setState({
                    loading: false,
                    downloadLink: response.data.Location,
                });
            }).catch(error => {
                alert(error.response.statusText);
                this.setState({
                    loading: false,
                });
            });//Refreshing button statuses
        });
    }

    componentDidMount(){

    }

    render(){
        return(
            <Container fluid className="card-alt">
                <Row>
                    <Col xs={12}>
                        <h3>
                            <FaSearch/>
                            <FormattedMessage id="au-title"/>{' '}
                        </h3>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col md="auto">
                        <small><FormattedMessage id="au-instructions"/></small>
                    </Col>
                </Row>
                <hr/>
                {/* Search field for id */}
                <div>
                    <hr/>
                    <div>
                        <b><FormattedMessage id="vl-inputId"/></b>
                        <Form onSubmit={this.onLessonsCall}>
                            <Row className='form-group-search'>
                                <Col>
                                    <Form.Control placeholder="ID" onChange={this.onChangeId} />
                                </Col>
                                <Col>
                                    <Button variant="primary" type="submit"> <FaSearch/>{' '}<FormattedMessage id="vl-search"/></Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <hr/>
                {/* If there are entries for the table, display it */}
                {this.state.loading ? <div style={{marginTop: "40px"}}><SpinnerLoader/></div>
                    :
                    <div>
                        {this.state.downloadLink != null ?
                        <div>
                            {/* Display here the results */}
                            <a className="btn btn-warning" href={this.state.downloadLink} target="_blank"><FormattedMessage id="au-download-link"/> </a>

                        </div> : null
                        }
                    </div>
                }
            </Container>
        );
    }
}

export default ViewAuditPage;