import React, { Component } from 'react';

import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import RadioCheckItem from '../radioCheckItem';

class DynamicRadioChecklist extends Component{

  constructor(props){
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect = (item) => {
    this.props.onChange(this.props.stateName, item);
  };

  render(){
    return(
      <Form className="checklist">
        <Form.Group>
          <fieldset>
            {this.props.items.length === 0 && !this.props.dontLoad ? 
              <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}><Spinner animation="border" role="status"/></div> 
            :
              this.props.items.map((item) => {
                return <RadioCheckItem 
                  key={item.title} 
                  text={item.title}
                  code={item.code} 
                  handleSelect={this.handleSelect}
                  content={this.props.content}
                /> 
              })
            }
          </fieldset>
        </Form.Group>
      </Form>
    );
  }
}

export default DynamicRadioChecklist;