import React, { Component } from 'react';
import TextAreaInput from '../../../../../../components/textAreaInput';
import { FormattedMessage } from 'react-intl';

class QuizInstructions extends Component{
  render(){
    const {limitFromRules, content } = this.props;
    return(
      <div>
        <h4><FormattedMessage id="qp-instructions"/></h4>
        <TextAreaInput
          // label="Instructions"
          textType="textarea"
          textName="instructions"
          content={this.props.content}
          onChange={this.props.onChange}
        />
        {
          limitFromRules ? <p style={ content ? content.length > limitFromRules ? {color: "red"} : {} : {}}>{content ? content.length : 0} / {limitFromRules}</p> : ""
        }
      </div>
    );
  }
}

export default QuizInstructions;