import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import axios from "../../server/interceptors";
import {apiSubCodesCreate, DEFAULT_LANGUAGE} from '../../config';
import {Button, Container} from 'react-bootstrap';
import './create-indicator-page-style.css';
import SessionManager from "../../utils/authHandling/session-manager";
import IndicatorDisplayView from "../../components/indicatorDisplayView";

class CreateIndicatorPage extends Component {

    constructor(props){
        super(props);
        this.state={
            loading: false,
            id: "",
            code: "",
            languageSP: "",
            languageEN: "",
            selectedStandardId: "",
            selectedExpectationId: "",
            selectedSubjects: [],
            selectedGrade: [],
            redirect: false,
        };
        this.onCreateCall = this.onCreateCall.bind(this);
        this.onChange = this.onChange.bind(this);
        this.redirectToEdit = this.redirectToEdit.bind(this);
    }

    onChange(field, value){
        this.setState({[field]: value});
    }

    onCreateCall(){

        // Validate
        if(this.state.code === ""){
            alert("Error: The standard code is required. / El código estándar es requerido.");
            return;
        }
        if(this.state.languageSP.length === 0){
            alert("Error: The description in Spanish is required./La descripción en español es requerida.");
            return;
        }
        if(this.state.languageEN.length === 0){
            alert("Error: The description in English is required./La descripción en inglés es requerida.");
            return;
        }
        if(this.state.selectedExpectationId === ''){
            alert("Error: The expectation id is required./La expectativa es requerida.");
            return;
        }

        this.setState({loading: true}, ()=>{
            // Call the API to insert
            let params = {
                CodeSubmainModel: {
                    CodeMainId: this.state.selectedExpectationId,
                    Code: this.state.code,
                    Desc: this.state.languageSP,
                },
                CodeSubmainLanguageModels:[
                    {
                        LessonLangCode: "es",
                        Desc: this.state.languageSP,
                    },
                    {
                        LessonLangCode: "en",
                        Desc: this.state.languageEN,
                    }
                ]
            };

            axios.post(apiSubCodesCreate, params,{
                headers: {
                    'Content-Type': 'application/json',                    
                }}).then((response)=>{

                if(response.status === 201)
                {
                    alert("Successfully created./Creado satisfactoriamente.");
                    this.setState({
                        redirect: true,
                    })
                }
                else
                {
                    alert("Validate the fields. If the error continues, contact the administrator./Valide los campos. Si el error persiste, contacte al administrador.");
                }
                this.setState({loading: false});
            }).catch(error => {

                if(error.response.status === 400)
                {
                    if(typeof error.response.data !== 'undefined' && typeof error.response.data.Messages !== 'undefined' && error.response.data.Messages.length > 0)
                    {
                        let message = "";
                        for(let i=0;i<  error.response.data.Messages.length; i++)
                        {
                            message += error.response.data.Messages[i];
                        }
                        alert(message);
                    }
                }
                else {
                    alert(`Error: ${error}`);
                }
                this.setState({loading: false});
            });
        });
    }

    redirectToEdit(){
        this.setState({redirectToEdit: true})
    }

    render(){
        if(this.state.redirect){
            return <Redirect to='/view-indicators' />
        }

        return(
            <Container fluid className="card-alt">
                <div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <h2><FormattedMessage id="std-indicator"/></h2>
                        {this.state.loading ? <Button disabled variant="success">
                                <FormattedMessage id="std-create-indicator"/></Button> :
                            <Button variant="success" onClick={this.onCreateCall}>
                                <FormattedMessage id="std-create-indicator"/>
                            </Button>}
                    </div>

                    <IndicatorDisplayView
                        isCreation={true}
                        language={this.props.language}
                        dataContent={this.state}
                        onChange={this.onChange}
                    />
                </div>
            </Container>
        );
    }
}

export default CreateIndicatorPage;