import React, { Component } from 'react';
import ValidatedTextInput from '../../validatedTextField';
import {Col, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {onSubjectCall} from "../../../server/getLessonSubjectCall/lesson-subject-call";
import RadioButtonSubjects from "../../radioButtonSubjects";
import RadioButtonGrades from "../../radioButtonGrades";
import Button from "react-bootstrap/Button";
import StandardCodeSelector from "../../standardCodeSelector";
import axios from "../../../server/interceptors";
import {apiGetStandardById, DEFAULT_LANGUAGE} from "../../../config";
import SessionManager from "../../../utils/authHandling/session-manager";

class CreateSection extends Component{
  
  constructor(props){
    super(props);
    this.state = {
        editActualGrade: "",
        displayModify: typeof this.props.isCreation !== 'undefined' && this.props.isCreation != null ? this.props.isCreation : false,
        dataState: this.props.state,
        subjectsFromServer: [],
        selectedGrade: [],
        standardIdToFind: "",
        code: "",
        languageSP: "",
        languageEN: "",
        selectedSubject: [],
        standardName: "",
        standardCode: "",
        standardDesc: "",
        isCreation: typeof this.props.isCreation !== 'undefined' && this.props.isCreation != null ? this.props.isCreation : false,
        infoValidate: typeof this.props.infoValidate !== 'undefined' && this.props.infoValidate != null ? this.props.infoValidate : false
    };
    this.onChange = this.onChange.bind(this);
    this.displayModify = this.displayModify.bind(this);
  }
  
  onChange(field, value){
    this.setState({[field]: value}, () => { this.props.parentStateHandle(field, value) });
  }

  callStandard(initStandardToFind){

      axios.get(`${apiGetStandardById}/${initStandardToFind}`, {
          headers: {
              'Content-Type': 'application/json',              
          }}).then((response)=>{
          this.setState({
              standardCode: response.data[0].StandardModel.Code,
              standardName: response.data[0].StandardModel.Name,
              standardDesc: response.data[0].StandardLanguageModels.find(x => x.LessonLangCode === "es").Desc + "/" + response.data[0].StandardLanguageModels.find(x => x.LessonLangCode === "en").Desc
          });
      }).catch(error => {
          console.log(error.response.data.Messages);
      });
  }
  
  componentWillReceiveProps(nextProps){

      let initStandardId = this.state.standardIdToFind;
    this.setState({
        selectedSubject: nextProps.state.selectedSubject,
        selectedGrade: nextProps.state.selectedGrade,
        editActualGrade: typeof nextProps.state.selectedGrade !== 'undefined' && nextProps.state.selectedGrade.length > 0 ? nextProps.state.selectedGrade : "",
        code: nextProps.state.code,
        name: nextProps.state.name ? nextProps.state.name : "",
        year: nextProps.state.year ? nextProps.state.year : "",
        languageSP: nextProps.state.languageSP ? nextProps.state.languageSP : "",
        languageEN: nextProps.state.languageEN ? nextProps.state.languageEN : "",
        selectedStandardId: nextProps.state.selectedStandardId ? nextProps.state.selectedStandardId : "",
        selectedExpectationId: nextProps.state.selectedExpectationId ? nextProps.state.selectedExpectationId : "",
        selectedSubCodeId: nextProps.state.selectedSubCodeId ? nextProps.state.selectedSubCodeId : "",
        selectedSubMainSubCodeId : nextProps.state.selectedSubMainSubCodeId ? nextProps.state.selectedSubMainSubCodeId : "",
        standardIdToFind: nextProps.state.selectedStandardId,
    },()=>{
        if(typeof nextProps.state.selectedStandardId !== 'undefined' && nextProps.state.selectedStandardId !== "" && initStandardId !== nextProps.state.selectedStandardId){
            this.callStandard(nextProps.state.selectedStandardId);
        }
    });
  }

  componentDidMount() {
      onSubjectCall(this.props.language).then(data => {
          this.setState({subjectsFromServer: data});
      });
  }

  displayModify(){
      this.setState({
          displayModify: true,
      })
  }

    render(){
    const { state } = this.props;
    return(
      <div className="card-alt-2">
          {
              !this.state.isCreation ?
                  <Row>
                      <Col xs={3} md={2}>
                          <b><FormattedMessage id="std-code"/></b>
                      </Col>
                      <Col xs={9} md={10}>
                      <b>{this.state.code}</b>
                      </Col>
                  </Row>:
                  <ValidatedTextInput
                      labelID="std-code"
                      textName="code"
                      onChange={this.onChange}
                      content={state.code}
                      isValidated={state.infoValidate}
                      limitFromRules={4}
                  />
          }
          <Row>
              <Col>
                  <hr/>
              </Col>
          </Row>
          {!this.state.isCreation && !this.state.displayModify?
              <>
              <Row>
                    <Col xs={3} md={2}>
                        <b><FormattedMessage id="std-grade"/></b>
                    </Col>
                    <Col xs={9} md={10}>
                        {this.state.selectedGrade}
                    </Col>
              </Row>
                  <Row>
                      <Col xs={3} md={2}>
                          <b><FormattedMessage id="std-standard-code"/></b>
                      </Col>
                      <Col xs={9} md={10}>
                          <b>{this.state.standardCode}</b>
                          <br/>
                          {this.state.standardName}
                          <br/>
                          {this.state.standardDesc}
                      </Col>
                  </Row>
                  <Row>
                          <Col xs={3} md={2}/>
                          <Col>
                              <Button className="info" onClick={this.displayModify}>
                                  <small>
                                      <FormattedMessage id="std-modify-standard"/>
                                  </small>
                              </Button>
                          </Col>

                  </Row>
              </>: null
          }
          {this.state.displayModify ?
          <Row>
              <Col xs={3} md={2}>
                  <b><FormattedMessage id="std-select-following-details"/></b>
              </Col>
              <Col>
                  <RadioButtonSubjects
                      textName="selectedSubject"
                      onChange={this.onChange}
                      content={this.props.state.selectedSubject}
                      language={this.props.language}
                      isValidated={null}
                  />
                  <RadioButtonGrades
                      textName="selectedGrade"
                      onChange={this.onChange}
                      content={this.props.state.selectedGrade}
                      language={this.props.language}
                      isValidated={null}
                  />

                  <StandardCodeSelector
                      onChange={this.onChange}
                      subjectCode={this.props.state.selectedSubject}
                      grade={this.props.state.selectedGrade}
                      language={this.props.language}
                      selectedStandardId={this.state.selectedStandardId}
                      selectedExpectationId={this.state.selectedExpectationId}
                      selectedSubCodeId={this.state.selectedSubCodeId}
                      selectedSubMainSubCodeId={this.state.selectedSubMainSubCodeId}
                      loading={this.props.state.loadingLessonData}
                      showExpectation={false}
                      showIndicator={false}
                      showSubIndicator={false}
                      loadingFromServer
                  />
              </Col>
          </Row>: null}

          <Row>
              <Col>
                  <hr/>
              </Col>
          </Row>

            <Row>
                <Col>
                    <ValidatedTextInput
                        labelID="std-description-en"
                        textName="languageEN"
                        onChange={this.onChange}
                        content={this.state.languageEN}
                        isValidated={state.infoValidate}
                        limitFromRules={null}
                    />
                </Col>
            </Row>
          <Row>
              <Col>
                  <ValidatedTextInput
                      labelID="std-description-sp"
                      textName="languageSP"
                      onChange={this.onChange}
                      content={this.state.languageSP}
                      isValidated={state.infoValidate}
                      limitFromRules={null}
                  />
              </Col>
          </Row>
      </div>
    );
  }
}

export default CreateSection;