import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import axios from "../../server/interceptors";
import {apiGetUsersUrl, apiGetUserRoles, DEFAULT_LANGUAGE} from '../../config';

import DynamicUserTable from '../../components/dynamicUserTable';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import {logoutUser} from "../../utils/authHandling/logout-user";
import SessionManager from "../../utils/authHandling/session-manager";
import { FaUsers } from 'react-icons/fa';

/** Edited by POR 2019.08.08 **/
class ViewUsersPage extends Component{

  constructor(props){
    super(props);
    this.state={
      usersFromServer: [], // Users
      roles: [], // Roles
      selectedRole: null, // Selected role Id
      selectedRoleName: null, // Selected role name
      tablePage: 1 //Current page on display

    };
    this.getUsers = this.getUsers.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.onChangeSearchUser = this.onChangeSearchUser.bind(this);
    this.selectRole = this.selectRole.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  /** To get the users from server **/
  getUsers(){
    let self = this;
    const params = {
      "PageNumber": this.state.tablePage,
      "Limit": 10,
      "OrderBy": "ASC",
      "Username": this.state.username ? this.state.username : null,
      "RoleId": this.state.selectedRole ? this.state.selectedRole : null
    };


    axios.post(apiGetUsersUrl, params, {
      headers: {
        'Content-Type': 'application/json',
      }}).then(function(response){

      if(response.status === 401)
      {
        logoutUser();
      }
        let users = null;
        if(response.data.UserInfo !== null) {
            users = response.data.UserInfo.map((item) => ({
            "userId": item.UserId,
            "userName": item.Username,
            "firstName": item.FullName.GivenName,
            "lastName": item.FullName.Surname,
            "lastSession": item.LastSession,
            "dateCreated": item.CreatedAt
          }));
        }
        self.setState({
          usersFromServer: users,
          pageTotals: response.data.PageTotals
        });
    }).catch(error => {
      alert(error);
    });
  }

  /** To get the roles from server **/
  getRoles(){
    let self = this;

    axios.get(apiGetUserRoles, {
      headers: {
        'Content-Type': 'application/json',
      }}).then(function(response){
      if(response.status === 200 || response.status === 201){
        let roles = [];
        response.data.map((item) => {
          return roles.push({id: item.Id, name: item.Name});
        });
        self.setState({roles: roles});
      }
    });
  }

  /** Action when selecting a role **/
  selectRole(id, name){
    this.setState({
      selectedRole: id,
      selectedRoleName: name
    });
  }

  /** Action when typing a username to search **/
  onChangeSearchUser(event){
    this.setState({
      username: event.target.value
    });
  }

  /** Changing to previous page **/
  previousPage(){
    this.setState({
      tablePage: this.state.tablePage - 1
    }, function(){
      this.getUsers();
    });
  }

  /** Changing to next page **/
  nextPage(){
    this.setState({
      tablePage: this.state.tablePage + 1
    }, function(){
      this.getUsers();
    });
  }
  
  componentDidMount(){
    this.getUsers();
    this.getRoles();
  }

  render(){
    return(
      <div className="card-alt">
        
        <div >
          <h3><FaUsers/><FormattedMessage id="u-title"/></h3>
          
          <div className='wrapper-user-filter' style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px"}}>
            <div style={{display: "flex", justifyContent: "space-evenly", }}>
              <Form.Control
                type="text" 
                name="username" 
                onChange={this.onChangeSearchUser}
              />
            </div>
            <DropdownButton className='mt-1' variant="outline-secondary" id="dropdown-basic-button" title={<FormattedMessage id="u-select-role"/>}>
              {
                this.state.roles.map((item) => {
                  return <Dropdown.Item
                      key={item.id} onClick={() => this.selectRole(item.id, item.name)}>{item.name}</Dropdown.Item>
                })
              }
            </DropdownButton>
            {this.state.selectedRoleName}
            <Button onClick={this.getUsers}><FormattedMessage id="vl-search"/></Button>
          </div>
        
        </div>

        {this.state.usersFromServer !== null ?
          <DynamicUserTable data={this.state.usersFromServer}/> : null
        }
        <div className="pageControls">
          {/* Next page buttons */}
          <Button disabled={this.state.tablePage  === 1} onClick={this.previousPage}>{'<'}</Button>
            {this.state.tablePage}
          <Button disabled={this.state.pageTotals === this.state.tablePage} onClick={this.nextPage}>{'>'}</Button>
        </div>
      </div>
    );
  }
}

export default ViewUsersPage;