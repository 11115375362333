/** ./src/routes/AppRouter.js
 * This file handles what view will be shown in the webpage.
 * All of the views will be imported as they are being created.
 * These views are entires pages constructed from smaller components.
 * 
 * A page-component is a parent/container component that will display its children
 */
import React from "react";

//Navigation Components
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "../utils/authHandling/private-route-component";

//Regular Components
import NavBar from '../components/navBar';

//Pages
import CreateLessonPage from '../pages/createLessonPage';
import CreateTemplatePage from '../pages/createTemplatepage';
import EditQuizPage from '../pages/editQuizPage';
import EditTemplatePage from '../pages/editTemplatePage';
import LoginPage from "../pages/loginPage";
import NotFoundPage from '../pages/404Page';
import QuizPage from '../pages/createQuizPage';
import UserPage from '../pages/userPage';
import ViewLessonsPage from '../pages/viewLessonsPage';
import ViewLessonPage from '../pages/editLessonPage';
import ViewTemplatesPage from '../pages/viewTemplatesPage';
import ViewUsersPage from "../pages/viewUsersPage";
import ViewAuditPage from "../pages/auditPage";
import ViewRulesPage from "../pages/viewRulesPage";


import CreateCodePage from "../pages/createCodePage/create-code-page";
//import CreateCodePage2 from '../pages/createCodePage2';
import CreateTypePage from "../pages/createTypePage";

import ComponentTest from '../pages/componentTestPage';

import "../../node_modules/video-react/dist/video-react.css";


//Translations
import { IntlProvider } from "react-intl";
import messages_en from '../translations/en';
import messages_es from '../translations/es';
//Pages
import ViewAdditionalMaterialPage from "../pages/viewAdditionalMaterialPage";
import CreateAdditionalMaterialPage from "../pages/createAdditionalMaterialPage/create-additional-material-page";
import EditAdditionalMaterialPage from "../pages/editAdditionalMaterialPage/edit-additional-material-page";
import ViewSubjectsPage from "../pages/viewSubjectsPage";
import ViewLevelPage from "../pages/viewLevelsPage";
import EditSubjectPage from "../pages/editSubjectPage/edit-subject-page";
import EditLevelPage from "../pages/editLevelPage/edit-level-page";
import CreateLevelPage from "../pages/createLevelPage/create-level-page";
import CreateSubjectPage from "../pages/createSubjectPage/create-subject-page";
import CreateStandardPage from "../pages/createStandardPage/create-standard-page";
import CreateUnitPage from "../pages/createUnitPage/create-unit-page";
import ViewUnitPage from "../pages/viewUnitPage/view-unit-page";
import CreateExpectationPage from "../pages/createExpectationPage/create-expectation-page";
import CreateIndicatorPage from "../pages/createIndicatorPage/create-indicator-page";
import CreateSubindicatorPage from "../pages/createSubIndicatorPage/create-subindicator-page";
import ViewStandardPage from "../pages/viewStandardsPage";
import ViewExpectationPage from "../pages/viewExpectationsPage";
import ViewIndicatorPage from "../pages/viewIndicatorPage";
import ViewSubIndicatorPage from "../pages/viewSubIndicatorPage";
import EditStandardPage from "../pages/editStandardPage/edit-standard-page";
import EditExpectationPage from "../pages/editExpectationPage/edit-expectation-page";
import EditIndicatorPage from "../pages/editIndicatorPage/edit-indicator-page";
import EditSubIndicatorPage from "../pages/editSubIndicatorPage/edit-sub-indicator-page";
import PermissionManager from "../pages/permissionsPage";
import RolesPage from "../pages/rolesPage/roles-page";
import ApiKeyPage from "../pages/apiKeyPage";
import LessonPage from "../pages/viewLessonPdfPage/view-lesson-pdf-page";

import ViewCodePage from "../pages/viewCodesPage";
import ViewTypePage from "../pages/viewTypesPage";


const messages = {
  'en': messages_en,
  'es': messages_es
};

//Main Class
class AppRouter extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      language: "es",
      isLoggedIn: true
    }
  }

  onChangeStateToEN = () => {
    localStorage.setItem('i18nextLng', 'en');
    this.setState({
      language: "en"
    });
  };
  onChangeStateToES = () => {
    localStorage.setItem('i18nextLng', 'es');
    this.setState({
      language: "es"
    });
  };

  rerender = () => {
    this.forceUpdate();
  };

  render(){
    return (
        <IntlProvider locale={this.state.language} messages={messages[this.state.language]}>
          <Router>
            <div style={{ padding: "20px", marginTop: '90px'}}>
              <Switch>
                <Route exact path="/login" language={this.state.language} component={LoginPage}/>
                <>
                <NavBar
                    changeToEN={this.onChangeStateToEN.bind(this)}
                    changeToES={this.onChangeStateToES.bind(this)}
                    refresh={this.rerender}
                />
                <PrivateRoute exact path="/audits" language={this.state.language} component={ViewAuditPage}/>
                <PrivateRoute exact path="/create-lesson" language={this.state.language} component={CreateLessonPage}/>
                <PrivateRoute exact path="/view-lessons" language={this.state.language} component={ViewLessonsPage}/>
                <PrivateRoute exact path="/lesson" language={this.state.language} component={ViewLessonPage}/>
                <PrivateRoute exact path="/quiz" language={this.state.language} component={QuizPage}/>
                <PrivateRoute exact path="/quiz-edit" language={this.state.language} component={EditQuizPage}/>
                <PrivateRoute exact path="/view-users" language={this.state.language} component={ViewUsersPage}/>
                <PrivateRoute exact path="/user" language={this.state.language} component={UserPage}/>
                <PrivateRoute exact path="/create-users" language={this.state.language} component={UserPage} />
                <PrivateRoute exact path="/create-template" language={this.state.language} component={CreateTemplatePage}/>
                <PrivateRoute exact path="/view-templates" language={this.state.language} component={ViewTemplatesPage}/>
                <PrivateRoute exact path="/edit-template" language={this.state.language} component={EditTemplatePage}/>
                <PrivateRoute exact path="/test" language={this.state.language} component={ComponentTest}/>
                <PrivateRoute exact path="/rules" language={this.state.language} component={ViewRulesPage}/>
                <PrivateRoute exact path="/" language={this.state.language} component={ViewLessonsPage}/>
                <PrivateRoute exact path="/additional-material" language={this.state.language} component={ViewAdditionalMaterialPage}/>
                <PrivateRoute exact path="/create-additional-material" language={this.state.language} component={CreateAdditionalMaterialPage}/>
                <PrivateRoute exact path="/edit-additional-material" language={this.state.language} component={EditAdditionalMaterialPage}/>
                <PrivateRoute exact path="/view-subjects" language={this.state.language} component={ViewSubjectsPage}/>
                <PrivateRoute exact path="/view-levels" language={this.state.language} component={ViewLevelPage}/>
                <PrivateRoute exact path="/create-subject" language={this.state.language} component={CreateSubjectPage}/>
                <PrivateRoute exact path="/create-level" language={this.state.language} component={CreateLevelPage}/>
                <PrivateRoute exact path="/edit-subject" language={this.state.language} component={EditSubjectPage}/>
                <PrivateRoute exact path="/edit-level" language={this.state.language} component={EditLevelPage}/>
                <PrivateRoute exact path="/create-standard" language={this.state.language} component={CreateStandardPage}/>
                <PrivateRoute exact path="/create-units" language={this.state.language} component={CreateUnitPage}/>
                <PrivateRoute exact path="/view-units" language={this.state.language} component={ViewUnitPage}/>
                <PrivateRoute exact path="/view-standards" language={this.state.language} component={ViewStandardPage}/>
                <PrivateRoute exact path="/create-expectation" language={this.state.language} component={CreateExpectationPage}/>
                <PrivateRoute exact path="/view-expectations" language={this.state.language} component={ViewExpectationPage}/>
                <PrivateRoute exact path="/create-indicator" language={this.state.language} component={CreateIndicatorPage}/>
                <PrivateRoute exact path="/view-indicators" language={this.state.language} component={ViewIndicatorPage}/>
                <PrivateRoute exact path="/create-sub-indicator" language={this.state.language} component={CreateSubindicatorPage}/>
                <PrivateRoute exact path="/view-sub-indicators" language={this.state.language} component={ViewSubIndicatorPage}/>
                <PrivateRoute exact path="/edit-standard" language={this.state.language} component={EditStandardPage}/>
                <PrivateRoute exact path="/edit-expectation" language={this.state.language} component={EditExpectationPage}/>
                <PrivateRoute exact path="/edit-indicator" language={this.state.language} component={EditIndicatorPage}/>
                <PrivateRoute exact path="/edit-sub-indicator" language={this.state.language} component={EditSubIndicatorPage}/>
                <PrivateRoute exact path="/permissions" language={this.state.language} component={PermissionManager}/>
                <PrivateRoute exact path="/roles" language={this.state.language} component={RolesPage}/>
                <PrivateRoute exact path="/apis" language={this.state.language} component={ApiKeyPage}/>
                <PrivateRoute exact path="/pdf-viewer" language={this.state.language} component={LessonPage}/>                
                <PrivateRoute exact path="/create-type" language={this.state.language} component={CreateTypePage} />
                <PrivateRoute exact path="/view-types" language={this.state.language} component={ViewTypePage} />
                <PrivateRoute exact path="/create-code" language={this.state.language} component={CreateCodePage} />
                <PrivateRoute exact path="/view-codes" language={this.state.language} component={ViewCodePage} />                                
                </>
                <Route component={NotFoundPage}/>
              </Switch>            
            </div>
          </Router>
        </IntlProvider>
    );
  }
}

export default AppRouter;