import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage } from 'react-intl';

class ModalPopout extends Component{

  constructor(props){
    super(props);
    this.state={
      show: true
    }
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal(){
    this.setState({show: false}, () => {this.props.handleClose()});
  }

  render(){
    return(
      <div>
        <Modal show={this.state.show} onHide={this.closeModal}>
          <Modal.Title>{this.props.title}</Modal.Title>
          <Modal.Body>{this.props.body}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.handleClose}>
              <FormattedMessage id="option-close"/>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>  
    )
  }
}

export default ModalPopout;