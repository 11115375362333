import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import { FormattedMessage } from 'react-intl';
import QuizLevelSelection from '../createQuizPage/components/quizInfoComponent/subComponents/quizLevelSelection'; 
import QuizTypeRadioSelection from '../createQuizPage/components/quizInfoComponent/subComponents/quizTypeRadioSelection';
import QuizExamRadioSelection from '../createQuizPage/components/quizInfoComponent/subComponents/quizExamRadioSelection';
import RadioButtonAddress from '../../components/radioButtonAddress';

class PreSelectionPage extends Component{
  constructor(props){
    super(props);
    this.state = {
      isForQuiz: true
    };
    this.onChange = this.onChange.bind(this);
    this.changeLevel = this.changeLevel.bind(this);
    this.changeQuizType = this.changeQuizType.bind(this);
    this.changeIsExam = this.changeIsExam.bind(this);
    this.goToNextPage = this.goToNextPage.bind(this);
  }

  onChange(field, value){
    this.setState({[field]: value});
  }

  changeLevel(value, levelString){
    let levelStringWithNumber = levelString + " (" + value + ")";
    this.setState({option: value, levelString: levelStringWithNumber});
  }

  changeIsExam(value){
    this.setState({isExam: value});
  }

  changeQuizType(value){
    this.setState({type: value});
  }

  goToNextPage(){
    if(this.props.isForQuiz)
    {
      if(this.state.option && this.state.type && this.state.isExam)
      {
        this.props.selectOption(this.state.option, this.state.type, this.state.isExam, this.state.levelString);
      }
      else
        {
        //TODO: needs modal with translation
        alert("Must select everything");
      }
    }
    else {
      if(this.state.option)
      {
        this.props.selectOption(this.state.option);
      }
      else {
        alert("Must select everything");
      }
    }
  }

  render(){
    return(
      <div className="card-alt">
        <h3>{this.props.title ? <FormattedMessage id={this.props.title}/> : "Pre-Selection"}</h3>
        {this.props.isForQuiz ? 
          <div>
            <FormattedMessage id="pre-quiz-selection-instructions"/>
            <QuizLevelSelection
              changeLevel={this.changeLevel}
              currentSelection={this.state.option}
            />
            <QuizTypeRadioSelection
              changeQuizType={this.changeQuizType}
              currentSelection={this.state.type}
            />
            <QuizExamRadioSelection 
              changeIsExam={this.changeIsExam}
              currentSelection={this.state.isExam}
            />
          </div> : 
          <div className="card-alt">
            <FormattedMessage id="pre-lesson-selection-instructions"/>
            <hr/>
            <RadioButtonAddress 
              textName="option" 
              onChange={this.onChange} 
              content={this.state.option} 
              language={this.props.language} 
              isValidated={this.state.validateLessonAddress}
            />
          </div>
        }
        <Button onClick={this.goToNextPage}><FormattedMessage id="pre-selection-continue"/></Button>
      </div>
    );
  }
}

export default PreSelectionPage;