import React, { Component } from 'react';
import RichTextInput from '../../../../../../components/richTextInput';

import {Col, Row, Button} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { FormattedMessage, injectIntl} from 'react-intl';
import {FaTrash} from 'react-icons/fa';

class Answer extends Component{
  render(){
    const { intl } = this.props;
    return(
      <div>
            <Row>
                <Col xs={8}>
                    <h5 style={{marginRight: "10px"}}><FormattedMessage id="qp-answer-title"/> #{this.props.index + 1}</h5>
                    <Form.Check
                        inline
                        label={intl.formatMessage({id: 'qp-correct-answer'})}
                        name="checkbox"
                        checked={this.props.correctStatus === "1"}
                        onChange={() => this.props.onChangeCorrectStatus(this.props.index)}
                    />
                </Col>
                <Col xs={4} style={{textAlign: 'right'}}>
                    <Button variant="danger" onClick={() => this.props.deleteAnswer(this.props.index)}>
                        <FaTrash/>{' '}
                        <FormattedMessage id="qp-delete-answer"/>
                    </Button>
                </Col>
            </Row>
        <RichTextInput 
          isQuiz
          size={200}
          content={this.props.answerResponseContent} 
          textName={this.props.index} 
          parentStateKey={this.props.key} 
          onChange={this.props.onChangeAnswerContent}
          subject={this.props.lessonSubject}
          grade={this.props.lessonGrade}
          limitFromRules={this.props.limitFromRules}
          fileRules={this.props.fileRules}
        />
        <hr/>
      </div>
    );
  }
}

export default injectIntl(Answer);