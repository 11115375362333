import React, { Component } from 'react';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

class DropdownSelector extends Component{

  render(){
    return(
      <div>
      <ButtonToolbar>
        <DropdownButton
          variant="outline-secondary"
          drop="right"
          id="dropdown-button-drop-right"
          title={this.props.buttonTitle}
          className="scrollable-menu">
          { 
            this.props.content.map((item) => {
              return <Dropdown.Item 
                key={item.id}
                onClick={() => this.props.onChange( this.props.stateName, item.code)}>
                  { item.title }
              </Dropdown.Item>
            })
          }
        </DropdownButton>
      </ButtonToolbar>
      </div>
    );
  }
}

export default DropdownSelector;