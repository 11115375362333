import React, { Component } from 'react';
import ValidatedTextInput from '../../validatedTextField';
import {Col, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {onSubjectCall} from "../../../server/getLessonSubjectCall/lesson-subject-call";
import RadioButtonSubjects from "../../radioButtonSubjects";
import RadioButtonGrades from "../../radioButtonGrades";
import StandardCodeSelector from "../../standardCodeSelector";
import axios from "../../../server/interceptors";
import {apiGetSubCodesById, DEFAULT_LANGUAGE} from "../../../config";
import SessionManager from "../../../utils/authHandling/session-manager";
import Button from "react-bootstrap/Button";

class CreateSection extends Component{

    constructor(props){
        super(props);
        this.state = {
            dataState: this.props.state,
            subjectsFromServer: [],
            displayModify: typeof this.props.isCreation !== 'undefined' && this.props.isCreation != null ? this.props.isCreation : false,
            selectedGrade: [],
            code: "",
            languageSP: "",
            languageEN: "",
            selectedSubject: [],
            isCreation: typeof this.props.isCreation !== 'undefined' && this.props.isCreation != null ? this.props.isCreation : false,
            infoValidate: typeof this.props.infoValidate !== 'undefined' && this.props.infoValidate != null ? this.props.infoValidate : false
        };
        this.onChange = this.onChange.bind(this);
        this.displayModify = this.displayModify.bind(this);
    }

    onChange(field, value){
        this.setState({[field]: value}, () => { this.props.parentStateHandle(field, value) });
    }

    callIndicator(idToFind){

        axios.get(`${apiGetSubCodesById}/${idToFind}`, {
            headers: {
                'Content-Type': 'application/json',                
            }}).then((response)=>{
                let descByLanguage = "";
                try {
                    if (typeof response.data !== 'undefined' && response.data.length > 0 && typeof response.data[0].CodeSubmainLanguageModels !== 'undefined' && response.data[0].CodeSubmainLanguageModels.length > 0) {
                        descByLanguage += response.data[0].CodeSubmainLanguageModels.find(x => x.LessonLangCode === "es").Desc;
                        descByLanguage += response.data[0].CodeSubmainLanguageModels.find(x => x.LessonLangCode === "en").Desc
                    }
                }
                catch (e) {
                    descByLanguage = "";
                }
            this.setState({
                indicatorCode: response.data[0].CodeSubmainModel.Code,
                indicatorDesc: descByLanguage
            });
        }).catch(error => {
            console.log(error.response.data.Messages);
        });
    }

    componentWillReceiveProps(nextProps){
        let initIndicator = this.state.selectedSubCodeId;
        this.setState({
            selectedSubject: nextProps.state.selectedSubject,
            selectedGrade: nextProps.state.selectedGrade,
            code: nextProps.state.code,
            name: nextProps.state.name ? nextProps.state.name : "",
            year: nextProps.state.year ? nextProps.state.year : "",
            languageSP: nextProps.state.languageSP ? nextProps.state.languageSP : "",
            languageEN: nextProps.state.languageEN ? nextProps.state.languageEN : "",
            selectedStandardId: nextProps.state.selectedStandardId ? nextProps.state.selectedStandardId : "",
            selectedExpectationId: nextProps.state.selectedExpectationId ? nextProps.state.selectedExpectationId : "",
            selectedSubCodeId: nextProps.state.selectedSubCodeId ? nextProps.state.selectedSubCodeId : "",
            selectedSubMainSubCodeId : nextProps.state.selectedSubMainSubCodeId ? nextProps.state.selectedSubMainSubCodeId : "",
        }, function() {
            if(typeof nextProps.state.selectedSubCodeId !== 'undefined' && nextProps.state.selectedSubCodeId !== "" && initIndicator !== nextProps.state.selectedSubCodeId){
                this.callIndicator(nextProps.state.selectedSubCodeId);
            }
        });
    }

    componentDidMount() {
        onSubjectCall(this.props.language).then(data => {
            this.setState({subjectsFromServer: data});
        });
    }

    displayModify(){
        this.setState({
            displayModify: true,
        })
    }

    render(){
        const { state } = this.props;
        return(
            <div className="card-alt-2">
                {
                    !this.state.isCreation ?
                        <Row>
                            <Col xs={3} md={2}>
                                <b><FormattedMessage id="std-code"/></b>
                            </Col>
                            <Col xs={9} md={10}>
                                <b>{this.state.code}</b>
                            </Col>
                        </Row>:
                        <ValidatedTextInput
                            labelID="std-code"
                            textName="code"
                            onChange={this.onChange}
                            content={state.code}
                            isValidated={state.infoValidate}
                            limitFromRules={4}
                        />
                }
                <Row>
                    <Col>
                        <hr/>
                    </Col>
                </Row>
                {!this.state.isCreation && !this.state.displayModify?
                    <>
                        <Row>
                            <Col xs={3} md={2}>
                                <b><FormattedMessage id="std-indicator"/></b>
                            </Col>
                            <Col xs={9} md={10}>
                                <b>{this.state.indicatorCode}</b>
                                <br/>
                                {this.state.indicatorDesc}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} md={2}/>
                            <Col>
                                <Button className="info" onClick={this.displayModify}>
                                    <small>
                                        <FormattedMessage id="std-modify-indicator"/>
                                    </small>
                                </Button>
                            </Col>

                        </Row>
                    </>: null
                }
                {this.state.displayModify ?
                    <>
                <Row>
                    <Col xs={3} md={2}>
                        <b><FormattedMessage id="std-select-following-details"/></b>
                    </Col>
                    <Col>
                        <RadioButtonSubjects
                            textName="selectedSubject"
                            onChange={this.onChange}
                            content={this.props.state.selectedSubject}
                            language={this.props.language}
                            isValidated={null}
                        />
                        <RadioButtonGrades
                            textName="selectedGrade"
                            onChange={this.onChange}
                            content={this.props.state.selectedGrade}
                            language={this.props.language}
                            isValidated={null}
                        />
                        <StandardCodeSelector
                            onChange={this.onChange}
                            subjectCode={this.props.state.selectedSubject}
                            grade={this.props.state.selectedGrade}
                            language={this.props.language}
                            selectedStandardId={this.state.selectedStandardId}
                            selectedExpectationId={this.state.selectedExpectationId}
                            selectedSubCodeId={this.state.selectedSubCodeId}
                            selectedSubMainSubCodeId={this.state.selectedSubMainSubCodeId}
                            loading={this.props.state.loadingLessonData}
                            showExpectation={true}
                            showIndicator={true}
                            showSubIndicator={false}
                            loadingFromServer
                        />
                    </Col>
                </Row> </> : null
                }

                <Row>
                    <Col>
                        <hr/>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <ValidatedTextInput
                            labelID="std-description-en"
                            textName="languageEN"
                            onChange={this.onChange}
                            content={this.state.languageEN}
                            isValidated={state.infoValidate}
                            limitFromRules={null}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ValidatedTextInput
                            labelID="std-description-sp"
                            textName="languageSP"
                            onChange={this.onChange}
                            content={this.state.languageSP}
                            isValidated={state.infoValidate}
                            limitFromRules={null}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CreateSection;