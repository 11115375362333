import React, { Component } from 'react';

import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

import ModalCommentRead from './modal-comment-read';
import ModalCommentReadRespond from './modal-comment-read-respond';

import { postCommentRead } from '../../server/postCommentRead/post-comment-read';
import { postCommentCompleted } from '../../server/postCommentCompleted/post-comment-completed';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheckSquare} from '@fortawesome/free-solid-svg-icons';


class CommentTableInstance extends Component{

  constructor(props){
    super(props);
    this.state={
      respondSelected: false,
      messageOpen: false,
      messageId: null
    }

    this.closeCreateModal = this.closeCreateModal.bind(this);
    this.closeReadModal = this.closeReadModal.bind(this);
    this.closeReadRespondModal = this.closeReadRespondModal.bind(this);
    this.openMesssage = this.openMessage.bind(this);
    this.completeComment = this.completeComment.bind(this);
  }

  openMessage(id){
    if(this.props.commentType === "received"){
      this.setState({
        modalReadRespondOpen: true,
        messageId: id,
        messageModel: this.props.data[id]
      });
      if(this.props.data[id].read === "0"){
        //Mark as read
        postCommentRead(this.props.language, {Id: this.props.data[id].id});
        this.props.updateRead(id);
      }
    }
    else if(this.props.commentType === "sent"){
      this.setState({
        modalReadOpen: true,
        messageId: id,
        messageModel: this.props.data[id]
      });
    }
  }

  completeComment(id){
    postCommentCompleted(this.props.language, {Id: this.props.data[id].id, Completed: "1"}).then(() => {
      this.props.loadComments();
    });
    this.props.updateCompleted(id)
  }

  closeCreateModal(){
    this.setState({modalCreateOpen: false});
  }
  closeReadModal(){
    this.setState({modalReadOpen: false, messageId: null});
  }
  closeReadRespondModal(){
    this.setState({modalReadRespondOpen: false, messageId: null});
  }


  render(){
    return(
      <div>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>From</th>
              <th>To</th>
              <th>Comment</th>
              <th>Date</th>
              <th>Completed</th>
            </tr>
          </thead>
          <tbody>
            {
              !this.props.data ? <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}><Spinner animation="grow"/></div> :
              this.props.data.map((item, index) => {
              return (
                <tr key={index} onClick={() => {this.openMessage(index)}}>
                  <td key={(index) + "ID"}><span style={item.read === "0" ? {fontWeight: "bold"} : {}}>{item.from}</span></td>
                  <td key={(index) + "TO"}><span style={item.read === "0" ? {fontWeight: "bold"} : {}}>{item.to}</span></td>
                  <td key={(index) + "Comment"}><span style={item.read === "0" ? {fontWeight: "bold"} : {}}>{item.comment.substring(0, 10) + "..."}</span></td>
                  <td key={(index) + "CODE"}><span style={item.read === "0" ? {fontWeight: "bold"} : {}}>{item.date}</span></td>
                  <td key={(index) + "SUBJECT"}><span style={item.read === "0" ? {fontWeight: "bold"} : {}}>{item.completed === "1" ? <FontAwesomeIcon icon={faCheckSquare}/> : <FontAwesomeIcon icon={faSquare}/>}</span></td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <ModalCommentRead
          modalOpen={this.state.modalReadOpen}
          messageId={this.state.messageId}
          closeModal={this.closeReadModal}
          messageModel={this.state.messageModel}
        />
        <ModalCommentReadRespond 
          modalOpen={this.state.modalReadRespondOpen}
          messageId={this.state.messageId}
          closeModal={this.closeReadRespondModal}
          messageModel={this.state.messageModel}
          updateCompleted={this.completeComment}
          language={this.props.language}
          entityId={this.props.entityId}
          entityType={this.props.entityType}
          loadComments={this.props.loadComments}
        />

      </div>
    );
  }
}

export default CommentTableInstance;