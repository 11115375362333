import axios from "../interceptors";
import { apiSubjectUrl } from "../../config";
import { logoutUser } from "../../utils/authHandling/logout-user";

export const onSubjectCall = async function () {
  try {
    const response = await axios.get(apiSubjectUrl, {
      headers: {
        "Content-Type": "application/json",        
      },
    });
    if (response.status === 401) {
      logoutUser();
    }
    return response.data.map((item) => ({ title: item.Name, code: item.Code }));
  } catch (error) {
    alert(error);
  }
};
