import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link,useHistory } from 'react-router-dom';

import { getLessonPdfLink } from '../../server/getLessonPdfLinkCall/get-lesson-pdf-link';
import SessionManager from "../../utils/authHandling/session-manager";
import {ROLE_VIEW} from "../../config";

class LessonTableLinks extends Component {

  constructor(props){
    super(props);
    this.state={
      roles: SessionManager.getSession() !== null ? SessionManager.getSessionDetails().Roles : [],
    }
  }

  saveLessonId(id){
    sessionStorage.setItem("lastSearch", id);
  }
  openWindowWithPDF(id){
    getLessonPdfLink(this.props.language, id).then((response) =>{
      window.open(response, "_blank");
    }).catch((error) => {
      console.log(error.response.data.Messages);
    });
  }

  render(){
    const { links } = this.props;
    return(
      <div style={{display: "flex", flexDirection: "column"}}>
        {links.map((item, index) => {
          
          return (
            item.linkText ? 
             <Link
              key={index + "_pdf"}
              onClick={()=> this.saveLessonId(item.lessonId)}
              to={{pathname: "./pdf-viewer",lessonId: item.lessonId}}>
              {item.linkText ? item.linkText : <FormattedMessage id="lt-view-lesson"/>}
             </Link>
            :
             this.state.roles.indexOf(ROLE_VIEW) <= -1 ?
            <Link  
              key={index} 
              onClick={() => this.saveLessonId(item.lessonId)} 
              to={{pathname: item.link, lessonId: item.lessonId}}>
                {item.linkText ? item.linkText : <FormattedMessage id="lt-view-lesson"/>}
            </Link> : null
          );
        })}
      </div>
    );
  }
}

export default LessonTableLinks;