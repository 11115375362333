/**
 * Created by Luis Diaz
 * 2019-2020
 * Modified by: Pedro Ortiz
 * Changes: UI improvement, plus user can change the level of knowledge
 */
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {Alert, Button} from 'react-bootstrap';
import QuizInstructions from './subComponents/quizInstructions';
import ExtraContent from './subComponents/extraContent';
import TextInput from '../../../../components/textInput';
import QuizLevelSelection from "../../../createQuizPage/components/quizInfoComponent/subComponents/quizLevelSelection";

class QuizInfo extends Component{
  constructor(props){
    super(props);
    this.state={
      isExam: null,
      quizType: "",
      quizLevel: "",
      quizInstructions: "",
      hasExtraContent: false,
      extraContent: "",
      levelSelected: false,
      showLevelOfKnowledgeFields: false
    }
    this.onChange = this.onChange.bind(this);
    this.changeIsExam = this.changeIsExam.bind(this);
    this.changeQuizType = this.changeQuizType.bind(this);
    this.changeLevel = this.changeLevel.bind(this);
    this.changeInstructions = this.changeInstructions.bind(this);
    this.extraContentToggle = this.extraContentToggle.bind(this);
    this.changeExtraContent = this.changeExtraContent.bind(this);
    this.toggleQuizLevel = this.toggleQuizLevel.bind(this);
  }

  onChange(field, value){
    this.setState({[field]: value});
    this.props.onChange(field, value);
  }

  changeIsExam(content){
    this.props.onChange("quizIsExam", content);
  }
  changeQuizType(content){
    this.props.onChange("quizType", content);
  }
  changeLevel(content){
    this.setState({option: content,});
    this.props.onChange("quizLevelOfKnowledge", content);
  }
  changeInstructions(content){
    this.props.onChange("quizInstructions", content);
  }
  extraContentToggle(){
    this.setState({
      hasExtraContent: !this.state.hasExtraContent
    }, function() {
      if(!this.state.hasExtraContent){
        this.setState({
          extraContent: ""
        });
      }
    });
  }
  toggleQuizLevel(){
    this.setState({
      showLevelOfKnowledgeFields: !this.state.showLevelOfKnowledgeFields,
    })
  }
  changeExtraContent(index, content){
    this.props.onChange("quizExtraInfo", content);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.state.quizExtraInfo){
      this.setState({
        hasExtraContent: true
      });
    }
  }


  render(){
    return(
      <div className="card-alt-2">
        {/*<ReadyToPublishFlag
          onChange={this.onChange}
          ready={this.props.state.quizReadyToPublish}
        />*/}
        <br/>
        <TextInput 
          onChange={this.props.onChange} 
          labelID="qp-title-input" 
          textName="quizTitle"
          content={this.props.state.quizTitle}
          limitFromRules={this.props.state.quizRules ? this.props.state.quizRules.TitleMaxLength : null}
        />
        <br/>
        <Button variant="warning" onClick={this.toggleQuizLevel}>
          <FormattedMessage id="qp-modify-level"/>
        </Button>
        {this.state.showLevelOfKnowledgeFields ?
        <div style={{marginTop: 10}} className="card-alt">
          <Alert variant="danger">
            <FormattedMessage id="qp-modify-level-alert"/>
          </Alert>
        <QuizLevelSelection
            changeLevel={this.changeLevel}
            currentSelection={this.state.option}
        />
        </div> : null
        }
        <QuizInstructions
          onChange={this.changeInstructions}
          content={this.props.state.quizInstructions}
          language={this.props.language}
          lessonGrade={this.props.state.lessonGrade}
          lessonSubject={this.props.state.lessonSubject}
          limitFromRules={this.props.state.quizRules ? this.props.state.quizRules.InstructionMaxLength : null}
        />
        <ExtraContent
          extraContentToggle={this.extraContentToggle}
          hasExtraContent={this.state.hasExtraContent}
          changeExtraContent={this.changeExtraContent}
          extraContent={this.props.state.quizExtraInfo ? this.props.state.quizExtraInfo : "" }
          lessonGrade={this.props.state.lessonGrade}
          lessonSubject={this.props.state.lessonSubject}
          language={this.props.language}
          limitFromRules={this.props.state.quizRules ? this.props.state.quizRules.ExtraInfoMaxLength : null}
          fileRules={this.props.fileRules}
        />
      </div>
    );
  }
}

export default QuizInfo;