import React, { Component } from "react";
import ValidatedTextInput from "../../../validatedTextField";
import RichTextInput from "../../../richTextInput";
import RadioButtonSubjects from "../../../radioButtonSubjects";
import RadioButtonGrades from "../../../radioButtonGrades";
import LessonIntroAudio from "../../../lessonIntroAudio/lesson-intro-audio";
import RadioButtonUnits from "../../../radioButtonUnits/radio-button-units-view";
import TextInput from "../../../textInput";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { withRouter } from "react-router-dom";
import ModalConstructorCode from "../../../../pages/viewUnitPage/modal-constructor-code";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

class CreateLessonInfoSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lessonDataState: this.props.state,
      lessonTitle: "",
      lessonDescription: "",
      lessonSubject: "",
      lessonGrade: "",
      lessonCode: "",
      lessonUnit: [],
      lessonAddress: "",
      lessonStandardCode: "",
      lessonInternalCode: {},
      lessonInternalCodeString: "",
      ConstructedCodes: [],
      currentEditingId: 0,
      currentItemCode: null,
    };

    this.onChange = this.onChange.bind(this);
    this.addCodeField = this.addCodeField.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  onChange(field, value) {
    this.setState({ [field]: value }, () => {
      this.props.parentStateHandle(field, value);
    });
  }

  componentWillReceiveProps = (nextProps) => {
    if (!this.state.showModal) {
      this.setState({
        subjectCode: nextProps.state.lessonSubject,
        lessonGrade: nextProps.state.lessonGrade
          ? nextProps.state.lessonGrade
          : "",
        selectedStandardId: nextProps.state.selectedStandardId
          ? nextProps.state.selectedStandardId
          : "",
        selectedExpectationId: nextProps.state.selectedExpectationId
          ? nextProps.state.selectedExpectationId
          : "",
        selectedSubCodeId: nextProps.state.selectedSubCodeId
          ? nextProps.state.selectedSubCodeId
          : "",
        selectedSubMainSubCodeId: nextProps.state.selectedSubMainSubCodeId
          ? nextProps.state.selectedSubMainSubCodeId
          : "",
        lessonStandardCode: nextProps.state.lessonStandardCode
          ? nextProps.state.lessonStandardCode
          : "",
      });
      if (
        this.props.state.ConstructedCodes !== nextProps.state.ConstructedCodes
      ) {
        const constructedCodesNew = nextProps.state.ConstructedCodes.map(
          (item) => {
            return {
              ...item,
              rowCode: item.ConstructedCodeCodeModels.map(
                (x) => x.Code + (x.DotAfter === "1" ? "." : "")
              ).join(""),
            };
          }
        );
        this.setState({
          ConstructedCodes: constructedCodesNew,
        });
      }
    }
  };

  addCodeField = () => {
    const rowConstructedCodes = [...this.state.ConstructedCodes];
    if (rowConstructedCodes.length > 0) {
      const lastElement = rowConstructedCodes[rowConstructedCodes.length - 1];
      if (lastElement && lastElement.rowCode) {
        rowConstructedCodes.push({
          Id: 0,
          MainCode: "0",
          rowCode: "",
          ConstructedCodeCodeModels: [],
        });
      }
    } else {
      rowConstructedCodes.push({
        Id: 0,
        MainCode: "0",
        rowCode: "",
        ConstructedCodeCodeModels: [],
      });
    }
    this.setState({ ConstructedCodes: rowConstructedCodes });
  };

  handleShow = (i) => {
    const currentItemCode = this.state.ConstructedCodes[i];
    this.setState({
      showModal: true,
      currentEditingId: i,
      currentItemCode,
    });
  };

  handleClose() {
    this.setState({ showModal: false });
  }

  handleUpdateCode = (vectorCode) => {
    let i = this.state.currentEditingId;
    let textCode = vectorCode
      .map((x) => x.Code + (x.DotAfter === "1" ? "." : ""))
      .join("");
    const constructedCodes = [...this.state.ConstructedCodes];
    constructedCodes[i].ConstructedCodeCodeModels = [...vectorCode];
    constructedCodes[i].rowCode = textCode;
    this.setState({
      ConstructedCodes: constructedCodes,
    });
    this.props.onDataChange(constructedCodes);
  };

  changeMainCode = (event, index) => {
    const constructedCodes = [...this.state.ConstructedCodes];
    const indexOld = constructedCodes.findIndex((item) => item.MainCode === "1");
    if (indexOld > -1) {
      constructedCodes[indexOld].MainCode = "0";
    }
    constructedCodes[index].MainCode = "1";
    this.setState({
      ConstructedCodes: constructedCodes,
    });
    this.props.onDataChange(constructedCodes);
  };

  removeItem = (index) => {
    const constructedCodes = [...this.state.ConstructedCodes];
    constructedCodes.splice(index, 1);
    this.setState({
      ConstructedCodes: constructedCodes,
    });
    this.props.onDataChange(constructedCodes);
  };

  render() {
    const { state } = this.props;
    return (
      <div className="card-alt-2">
        <RadioButtonSubjects
          textName="lessonSubject"
          onChange={this.onChange}
          content={this.props.state.lessonSubject}
          language={this.props.language}
          isValidated={state.validateLessonSubject}
        />
        <RadioButtonGrades
          textName="lessonGrade"
          onChange={this.onChange}
          content={this.props.state.lessonGrade}
          language={this.props.language}
          isValidated={state.validateLessonGrade}
        />
        <RadioButtonUnits
          labelID="cl-unit-input"
          textName="lessonUnit"
          onChange={this.onChange}
          content={this.props.state.lessonUnit}
          subjectCode={this.state.subjectCode}
          levelCode={this.state.lessonGrade}
          language={this.props.language}
          isValidated={state.validateLessonUnit}
        />
        <Row>
          <Col xs={2} />
          <Col xs={9}>
            <Button onClick={this.addCodeField}>
              + <FormattedMessage id="cl-add-code" />
            </Button>
          </Col>
        </Row>

        <hr />
        <>
          {this.state.ConstructedCodes.map((item, i) => (
            <div key={i}>
              <Row className="space-radioButton Region-AddCode">
                <Col xs={2.5}>
                  <Form.Check
                    checked={item.MainCode === "1"}
                    name="radioButton"
                    type="radio"
                    label={<FormattedMessage id="cl-code-input-main-code" />}
                    onClick={(e) => this.changeMainCode(e, i)}
                  />
                </Col>
                <Col className="Region-AddCode--desc">
                  <TextInput
                    index={i}
                    labelID={"cl-code-input-intern"}
                    textName="lessonStandardCode"
                    readOnly={true}
                    content={item.rowCode}
                    onChange={this.onChange}
                  />
                </Col>
                <hr />
                <Col xs={8} md={4}>
                  <Button onClick={() => this.handleShow(i)}>
                    <FormattedMessage id="cl-btn-build-code" />
                  </Button>
                  <Button
                    className="btn-trash"
                    onClick={() => this.removeItem(i)}
                    variant="light"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
        </>
        <hr />
        {/*Titulo*/}
        <ValidatedTextInput
          labelID="cl-title-input"
          textName="lessonTitle"
          onChange={this.onChange}
          content={state.lessonTitle}
          isValidated={state.validateLessonTitle}
          limitFromRules={
            this.props.lessonRules
              ? this.props.lessonRules.TitleMaxLength
              : null
          }
        />
        {/*Numero de Leccion*/}
        <ValidatedTextInput
          labelID="cl-number-input"
          textName="lessonNo"
          onChange={this.onChange}
          content={state.lessonNo}
          isValidated={state.validateLessonNo}
          textType="number"
        />
        <hr />
        {/*Descripcion*/}
        <RichTextInput
          isVR={this.props.state.lessonAddress === "1"}
          labelID="cl-description-input"
          textName="lessonDescription"
          onChange={this.onChange}
          content={state.lessonDescription.replace(/&amp;/g, '&')}
          isValidated={state.validateLessonDescription}
          language={this.props.language}
          subject={this.props.state.lessonSubject}
          grade={this.props.state.lessonGrade}
          fileRules={this.props.fileRules}
        />
        {/*Audio de Introduccion*/}
        <LessonIntroAudio
          src={this.props.state.lessonAudioSrc}
          lessonSubject={this.props.state.lessonSubject}
          lessonGrade={this.props.state.lessonGrade}
          language={this.props.language}
          onChange={this.onChange}
          fileRules={this.props.fileRules}
        />
        {/*Concepto*/}
        <RichTextInput
          isVR={this.props.state.lessonAddress === "1"}
          labelID="el-concept"
          textName="lessonConcept"
          onChange={this.onChange}
          content={state.lessonConcept.replace(/&amp;/g, '&')}
          isValidated={state.validateLessonConcept}
          language={this.props.language}
          subject={this.props.state.lessonSubject}
          grade={this.props.state.lessonGrade}
          limitFromRules={
            this.props.lessonRules
              ? this.props.lessonRules.ConceptMaxLength
              : null
          }
          fileRules={this.props.fileRules}
        />
        <b className="title-block mt-4">
          <FormattedMessage id="cl-audio-recorder" />
        </b>
        <p>
          <FormattedMessage id="cl-lesson-audio-recording" />
        </p>
        {/*Grabador de Audio*/}
        <Form>
          <Form.Check
            inline
            label={<FormattedMessage id="cl-yes" />}
            checked={this.props.state.lessonHasRecorder}
            onClick={() => {
              this.onChange("lessonHasRecorder", true);
            }}
          />
          <Form.Check
            inline
            label={<FormattedMessage id="cl-no" />}
            checked={!this.props.state.lessonHasRecorder}
            onClick={() => {
              this.onChange("lessonHasRecorder", false);
            }}
          />
        </Form>
        {this.state.showModal && (
          <Modal show={this.state.showModal} onHide={this.handleClose}>
            <Modal.Header closeButton className="w-auto"></Modal.Header>
            <ModalConstructorCode
              handleUpdateCode={this.handleUpdateCode}
              currentItemCode={this.state.currentItemCode}
              handleClose={this.handleClose}
              showInputCode={true}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default withRouter(CreateLessonInfoSection);
