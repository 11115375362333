export function decodeHTMLEntities (str) {
  let result;
  if(str && typeof str === 'string') {
    // strip script/html tags
    result = str.split('&aacute;').join('á');
    result = result.split('&eacute;').join('é');
    result = result.split('&iacute;').join('í');
    result = result.split('&oacute;').join('ó');
    result = result.split('&uacute;').join('ú');
    result = result.split('&Aacute;').join('Á');
    result = result.split('&Eacute;').join('É');
    result = result.split('&Iacute;').join('Í');
    result = result.split('&Oacute;').join('Ó');
    result = result.split('&Uacute;').join('Ú');
    result = result.split('&iquest;').join('¿');
    result = result.split('&iexcl;').join('¡');
    result = result.split('&ntilde;').join('ñ');
    result = result.split('&Ntilde;').join('Ñ');
    result = result.split('&uuml;').join('ü');
    result = result.split('&Uuml;').join('Ü');
    result = result.split('&hellip;').join('...');
    result = result.split('&gt;').join('>');
    result = result.split('&lt;').join('<');
    result = result.split('&nbsp;').join(' ');
    result = result.split('&deg;').join('°');
    result = result.split('&#36;').join('$');
    result = result.split('&#125;').join('}');
    result = result.split('&#123;').join('{');
    result = result.split('&#40;').join('(');
    result = result.split('&#41;').join(')');
    result = result.split('&#91;').join('[');
    result = result.split('&#93;').join(']');
    result = result.split('&cent;').join('¢');
    result = result.split('&divide;').join('÷');
    result = result.split('&sup2;').join('²');
    result = result.split('&frac12;').join('½');
    result = result.split('&frac14;').join('¼');
    result = result.split('&le;').join('≤');
    result = result.split('&ge;').join('≥');
    result = result.split('&ldquo;').join('“');
    result = result.split('&rdquo;').join('”');
    result = result.split('&ndash;').join('-');
  }
  return result;
}