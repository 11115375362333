import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  apiUpdateQuizUrl,
  apiGetQuizUrl,
  apiGetQuizRules,
  apiLessonGetContent,
  apiDeleteQuizUrl,
  DEFAULT_LANGUAGE
} from '../../config';
import axios from "../../server/interceptors";
import { logoutUser } from '../../utils/authHandling/logout-user';
import { onRulesCall } from '../../server/getFileUploadRulesCall/file-upload-rules-call';
import { quizValidator } from '../../utils/quizValidator';
import { decodeHTMLEntities } from '../../utils/htmlEntityDecoder';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import LessonHTMLViewer from '../../components/lessonHTMLViewer';
import TabNavigator from '../../components/tabNavigator';

//Local Components
import QuizInfo from './components/quizInfoComponent';
import QuizQuestions from './components/quizQuestionsComponent';
import QuizCommentsSection from './components/commentsSection/comments-section-quiz';
import { getComments } from '../../server/getComments/get-comments';
import { getCommentsSent } from '../../server/getCommentsSent/get-comments-sent';
import {FaSave, FaArrowLeft, FaTrash} from 'react-icons/fa';
import SessionManager from "../../utils/authHandling/session-manager";

class EditQuizPage extends Component{
  constructor(props){
    super(props);
    this.state={
      //Quiz Model
      quizId: "",
      quizReadyToPublish: "",
      quizLessonId: "",
      quizTitle: "",
      quizInstructions: "",
      quizLevelOfKnowledge: "",
      quizIsExam: "",
      quizType: "",
      quizExtraInfo: "",
      //For questions
      quizQuestionModel: [],
      quizQuestionRubricModelList: [],
      quizQuestionResponseModelList: [],
      loading: false
    }
    this.onChange = this.onChange.bind(this);
    this.updateQuiz = this.updateQuiz.bind(this);
    this.getQuiz = this.getQuiz.bind(this);
    this.deleteQuiz = this.deleteQuiz.bind(this);
    this.getQuizRules = this.getQuizRules.bind(this);
    this.getLessonGradeAndSubject = this.getLessonGradeAndSubject.bind(this);
    this.removePreviewId = this.removePreviewId.bind(this);
    this.loadComments = this.loadComments.bind(this);
    this.getQuizType = this.getQuizType.bind(this);
  }
  onChange(field, value){
    this.setState({[field]: value});
  }
  getQuiz(){
    let self = this;

    axios.get(apiGetQuizUrl + "/" + sessionStorage.quizId + "/false", {
      headers: {
        'Content-Type': 'application/json',        
      }}).then(function(response) {
      if(response.status === 200 || response.status === 201){
        let quizQuestions = [];
        let quizRubrics = [];
        let quizResponses = [];
        response.data.QuizRequestModel.QuestionRequestModelList.map((item, index) => {
          quizQuestions.push(item.QuizQuestionModel);
          if(item.QuizQuestionRubricModelList.length === 0){
            quizRubrics.push([{Rubric: "", QuestionRubricCriterionModelList: [{Criterion: "", QuestionRubricCriterionValueModelList: [{Desc: "", Value: ""}]}]}]);
          } else{
            quizRubrics.push(item.QuizQuestionRubricModelList);
          }
          quizResponses.push(item.QuizQuestionResponseModelList);
          return true;
        });
        self.setState({
          quizId: response.data.QuizRequestModel.QuizModel.Id,
          quizReadyToPublish: response.data.QuizRequestModel.QuizModel.ReadyToPublish,
          quizLessonId: response.data.QuizRequestModel.QuizModel.LessonId,
          quizTitle: response.data.QuizRequestModel.QuizModel.Title,
          quizInstructions: response.data.QuizRequestModel.QuizModel.Instruction,
          quizExtraInfo: response.data.QuizRequestModel.QuizExtraInfoModel ? response.data.QuizRequestModel.QuizExtraInfoModel.ExtraInfo : "",
          quizLevelOfKnowledge: response.data.QuizRequestModel.QuizModel.LevelOfKnowledge,
          quizIsExam: response.data.QuizRequestModel.QuizModel.IsExam,
          quizType: response.data.QuizRequestModel.QuizModel.QuizTypeId,
          quizQuestionModel:  quizQuestions,
          quizQuestionRubricModelList: quizRubrics,
          quizQuestionResponseModelList: quizResponses,
          levelOfKnowledgeString: response.data.QuizRequestModel.TaxonomyLevel.TaxLevelName + " (" + response.data.QuizRequestModel.QuizModel.LevelOfKnowledge + ")"
        }, () => {
          self.getQuizRules();
          self.getLessonGradeAndSubject();
        });
      }
    });
  }
  updateQuiz(){
    // Function to create a quiz

    //Passing the strings through the decoder
    let questionsDecoded = this.state.quizQuestionModel;
    this.state.quizQuestionModel.forEach((element, index) => {
      questionsDecoded[index].Desc = decodeHTMLEntities(element.Desc);
    });

    let responsesDecoded = this.state.quizQuestionResponseModelList;
    this.state.quizQuestionResponseModelList.forEach((element, index) => {
      element.forEach((item, c) => {
        responsesDecoded[index][c].Response = decodeHTMLEntities(item.Response);
      });
    });
    
    let questionRequestModelGenerated = [];
    let updateQuizJSON = {};

    this.setState({loading: true, quizQuestionModel: questionsDecoded, quizQuestionResponseModelList: responsesDecoded}, function(){
      if(this.state.quizLevelOfKnowledge > 2){
        this.state.quizQuestionModel.map((item, index) => {
          return questionRequestModelGenerated.push({
            "QuizQuestionModel": this.state.quizQuestionModel[index],
            "QuizQuestionRubricModelList": this.state.quizQuestionRubricModelList[index], 
            "QuizQuestionResponseModelList": this.state.quizQuestionResponseModelList[index]
          });
        });
      } else{
        this.state.quizQuestionModel.map((item, index) => {
          return questionRequestModelGenerated.push({
            "QuizQuestionModel": this.state.quizQuestionModel[index],
            "QuizQuestionResponseModelList": this.state.quizQuestionResponseModelList[index]
          });
        });
      }
      updateQuizJSON = {
        "QuizRequestModel": {
          "QuizModel": {
            "Id": this.state.quizId,
            "LessonId": this.state.quizLessonId,
            "LevelOfKnowledge": this.state.quizLevelOfKnowledge,
            "Title": this.state.quizTitle,
            "Instruction": this.state.quizInstructions,
            "IsExam": this.state.quizIsExam,
            "QuizTypeId": this.state.quizType,
            "ReadyToPublish": this.state.quizReadyToPublish
          },
          "QuizExtraInfoModel":{
            "ExtraInfo": this.state.quizExtraInfo
          }, 
          "QuestionRequestModelList": questionRequestModelGenerated,
        },
      };

      //Validating quiz data
      let quizWarnings = quizValidator(updateQuizJSON, this.state.quizRules);
      if(quizWarnings.length > 0){
        let warningString = "";
        quizWarnings.forEach((item) => {
          warningString = warningString + item + "\n";
        });
        alert("Error: \n" + warningString);
      } else{
        let self = this;
        axios.post(apiUpdateQuizUrl, updateQuizJSON, {
          headers: {
            'Content-Type': 'application/json',            
          }}).then(function(response){

          if(response.status === 200 || response.status === 201)
          {
              alert(response.data.Messages);
              window.location.reload();
          }
          else if(response.status === 401){
            logoutUser();
          }
          else {
              let str = "";
              response.data.Messages.map((item) => {
                str = str + item + "\n";
                return true;
              });
              alert(str);
          }
          self.setState({loading: false});
        }).catch(error => {
          try {
              let str = "";
              error.response.data.Messages.map((item) => {
                str = str + item + "\n";
                return true;
              });
              alert(str);
          }
          catch (e) {
            alert(error);
          }
          self.setState({loading: false});
        });
        
      }
    });
  }

  deleteQuiz(){
    this.setState({loading: true}, function() {

      let updateQuizJSON = {
        "QuizRequestModel": {
          "QuizModel": {
            "Id": this.state.quizId,
            "Available": "0"
          },
        }
      };

      let self = this;
      axios.post(apiDeleteQuizUrl, updateQuizJSON, {
        headers: {
          'Content-Type': 'application/json',          
        }
      }).then(function (response) {
        if (response.status === 200 || response.status === 202) {
          window.history.back()
        } else if (response.status === 401) {
          logoutUser();
        } else {
          let str = "";
          response.data.Messages.map((item) => {
            str = str + item + "\n";
            return true;
          });
          alert(str);
        }
        self.setState({loading: false});
      }).catch(error => {
        alert(error);
        self.setState({loading: false});
      });
    });
  }

  getLessonGradeAndSubject(){
    let self = this;
    axios.post(apiLessonGetContent, {
      "LessonId": sessionStorage.lastSearch,
      "IsForOutsideSource": true,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }}).then(function (response){
      if(response.status === 200 || response.status === 201){
        let lessonData = response.data.LessonModifierRequestModel;
        self.setState({
          lessonId: lessonData.LessonModel.Id,
          lessonTitle: lessonData.LessonModel.LessonTitle,
          lessonGrade: lessonData.LessonModel.LevelCode,
          lessonSubject: lessonData.LessonModel.SubjectCode
        })
      }
    });
  }
  
  getQuizRules(){
    let self = this;
    axios.get(apiGetQuizRules + "/" + this.state.quizType + "/" + sessionStorage.lastSearch , {
      headers: {
        'Content-Type': 'application/json',        
      }}).then(function(response){
      if(response.status === 200 || response.status === 201){
        self.setState({
          quizRules: response.data.Rules[0]
        });
      }
    }).catch(error => {
      alert(error.response.data.Messages, "Quiz rules could not be retrieved from server.")
    })
  }

  componentDidMount(){
    this.getQuiz();
    onRulesCall().then(data => {
      this.setState({"fileRules": data});
    });
  }
  removePreviewId(){
    this.setState({previewId: null});
  }

  loadComments(){
    getComments(this.props.language, this.state.quizId, "QUI").then((data) => {
      let messagesReceived = [];
      data.CommentDetailsModelList.forEach((item, index) => {
        messagesReceived.push({to: item.RecipientName ,from: item.SenderName, comment: item.Comment, date: item.CreatedAt, completed: item.Completed, read: item.Read, id: item.Id, userFromId: item.UserId});
      });
      this.setState({MessagesReceived: messagesReceived});
    });

    getCommentsSent(this.props.language, this.state.quizId, "QUI").then((data) => {
      let messagesSent = [];
      data.CommentDetailsModelList.forEach((item, index) => {
        messagesSent.push({to: item.RecipientName, from: item.SenderName, comment: item.Comment, date: item.CreatedAt, completed: item.Completed});
      });
      this.setState({MessagesSent: messagesSent});
    })
  }

  getQuizType(typeInt){
    switch(typeInt){
      case '1':
        return  <b><FormattedMessage id="qp-isMultipleChoice"/></b>;
      case '2': 
        return  <b><FormattedMessage id="qp-isDragAndDrop"/></b>;
      case '3':
        return  <b><FormattedMessage id="qp-isFreeText"/></b>;
      default:
        return typeInt
    }
  }
  

  render(){
    const sections = [
      {
        "sectionTitle": "Info",
        "sectionContent": <QuizInfo 
                            onChange={this.onChange} 
                            state={this.state} 
                            language={this.props.language}
                            fileRules={this.state.fileRules}
                          />
      },
      {
        "sectionTitle": "Preguntas",
        "sectionContent": <QuizQuestions 
                            onChange={this.onChange} 
                            state={this.state}
                            language={this.props.language}
                            fileRules={this.state.fileRules}
                          />
      }, {
          "sectionTitle": "Comentarios",
          "sectionContent": <QuizCommentsSection
            parentStateHandle={this.onChange}
            state={this.state}
            language={this.props.language}
            messagesSent={this.state.MessagesSent}
            messagesReceived={this.state.MessagesReceived}
            loadComments={this.loadComments}
          />,
          "onSelect": this.loadComments
      }
    ];
    return(
        <Container fluid className="card-alt">
          <h4><FormattedMessage id="qp-title-edit"/></h4>
          <Row>
            <Col xs={12} md={8}>
              {this.state.loading ? <Button disabled variant="success" style={{marginBottom: "10px"}} >

                    <FormattedMessage id="qp-update-quiz"/></Button>:
                  <Button variant="success" style={{marginBottom: "10px"}} onClick={this.updateQuiz}>
                    <FaSave/> {' '}
                    <FormattedMessage id="qp-update-quiz"/>
                  </Button>}
              <Link to="lesson">

                <Button style={{marginBottom: "10px", marginLeft: "5px"}} variant="warning">
                  <FaArrowLeft/> {' '}
                  <FormattedMessage id="qp-back-to-lesson"/>
                </Button>
              </Link>
            </Col>
            <Col md={4} style={{textAlign: 'right'}}>
              <Button variant="danger" style={{marginBottom: "10px"}} onClick={this.deleteQuiz}>
                <FaTrash/> {' '}
                <FormattedMessage id="qp-delete-quiz"/>
              </Button>
            </Col>
          </Row>
        <div>

            <Row>
              <Col xs={12} md={8}>
                <h3>{this.state.lessonTitle}</h3>
              </Col>
              <Col xs={12} md={2}>
                <FormattedMessage id="qp-lesson-id"/>:{' '}
                <b>{this.state.lessonId}</b>
              </Col>
            </Row>
            <hr style={{marginTop: "-1px", marginBottom: "-1px"}}/>
            <Row>
              <Col>
                <b>{this.state.quizTitle}</b>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormattedMessage id="qp-quiz-id"/>
                <b>{this.state.quizId}</b>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormattedMessage id="qp-evaluation"/>:{' '}
                {this.state.quizIsExam === "1" ? <b><FormattedMessage id="qp-isExam"/></b> : <b><FormattedMessage id="qp-isPractice"/></b>}
              </Col>
            </Row>
            <Row>
              <Col>
                <FormattedMessage id="qp-level-of-knowledge"/>:{' '}
                <b>{this.state.levelOfKnowledgeString}</b>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormattedMessage id="qp-type"/>:{' '}
                {this.getQuizType(this.state.quizType)}
              </Col>
            </Row>

          <hr/>
        </div>
          <hr/>
        <TabNavigator 
          sections={sections} 
          defaultActive="Info"
        />
        <div 
          style={{
            bottom: "0",
            right: "0",
            position: "fixed",
            textAlign: "center",
            zIndex: "100"
          }}
        > 
          <FontAwesomeIcon
            onClick={()=>this.setState({previewId: 4})}
            icon={faEye} 
            size="4x" 
            color="#fff"
            style={{
              backgroundColor: "rgb(88, 145, 153)",
              height: "70px",
              width: "70px",
              padding: "5px", 
              borderRadius: "100%",
              margin: "10px"
            }} 
          />
          <p><FormattedMessage id="preview"/></p>
          <LessonHTMLViewer
            contentId={this.state.previewId}
            lessonId={this.state.quizId}
            language={this.props.language}
            removePreviewId={this.removePreviewId}
          />
        </div>
        </Container>
    );
  }
}

export default EditQuizPage;