import axios from "../interceptors";
import { apiUpdateTemplateUrl } from "../../config";
import { logoutUser } from "../../utils/authHandling/logout-user";

export const updateTemplateCall = async function (
  language,
  templateModifierRequestModel
) {
  let params = {
    TemplateModifierRequestModel: templateModifierRequestModel,
  };
  try {
    const response = await axios.post(apiUpdateTemplateUrl, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200 || response.status === 201) {
      //the post was saved correctly, we return false to deactivate the loading
      alert(response.data.Messages);
      return false;
    } else if (response.status === 400) {
      alert(response.data.Messages);
      return false;
    } else {
      //Session is not valid, therefore logout
      alert(response.data.Messages);
      logoutUser();
      return false;
    }
  } catch (error) {
    console.log(error.response.data.Messages);
    return false;
  }
};
