import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {Card, Row, Col} from 'react-bootstrap';
import {FaPlusCircle} from 'react-icons/fa';

class CreateLessonPruebasSection extends Component{

  constructor(props){
    super(props);
    this.state={
      quizes: this.props.state.lessonQuizes,
      exams: this.props.state.lessonExams,
      practices: this.props.state.lessonPractices,
      preExams: this.props.state.lessonPreExams,
      postExams: this.props.state.lessonPostExams,
    };
    this.goToQuiz = this.goToQuiz.bind(this);
  }

  goToQuiz(id){
    sessionStorage.setItem("quizId", id);
    sessionStorage.setItem("quizIsForVr", this.props.isVr);
  }


  render(){
    const { lessonId, lessonDirectedTo } = this.props;
    return(
      <div className="card-alt-2">
        <Row>
          <Col xs={8}>
            <h4><FormattedMessage id="vl-evaluations"/></h4>
          </Col>
          <Col xs={4} style={{textAlign:'right'}}>

            <Link
                className="btn btn-primary"
                to={{pathname: "/quiz", lessonId: lessonId, lessonDirectedTo: lessonDirectedTo}}>
              <FaPlusCircle/>{' '}
              <FormattedMessage id="cl-create-quiz"/>
            </Link>
          </Col>
        </Row>
        {this.props.isValidated ? <p style={{color: "red"}}><FormattedMessage id="va-lesson-richtext"/></p> : ""}
        {/* TODO: Dynamic links to created quizes in this lesson */}
        <div style={{display: "flex", flexDirection: "column", flexWrap: "wrap", gap: "15px"}}>

          {this.state.practices ?
            <div>
            <hr/>
              <h5><FormattedMessage id="vl-quizes"/></h5>
              <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "15px"}}>
              {this.state.practices.length > 0 ?
                this.state.practices.map( (item, index) => {
                return (
                    <Card key={index} style={{ width: '18rem', marginTop: '5px'}}>
                      <Card.Body>
                        <Card.Title>{item.QuizModel.Title}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                          <FormattedMessage id="vl-quiz"/> <br/>
                          Quiz ID: {item.QuizModel.Id} <br/>
                          <small>NPC: {item.QuizModel.LevelOfKnowledge}</small>
                        </Card.Subtitle>
                        <Card.Text className="mb-2 text-muted">
                          {/* Something we can user for some text inside the card */}
                        </Card.Text>
                          <Link
                            key={index}
                            onClick={() => this.goToQuiz(item.QuizModel.Id)}
                            to={{pathname: "quiz-edit"}}>
                              <FormattedMessage id="qp-viewOrEdit"/>
                          </Link>
                      </Card.Body>
                    </Card>
                  )
              }) : <FormattedMessage id="vl-no-quizes"/> } </div> </div> : ""}

          {/* Pre Exams */}
          {this.state.preExams ?
            <div>
            <hr></hr>
            <h5><FormattedMessage id="vl-pre"/></h5>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "15px"}}>
            {this.state.preExams.length > 0 ? this.state.preExams.map( (item, index) => {
              return (
                <Card key={index} style={{ width: '18rem', marginTop: '5px'}}>
                  <Card.Body>
                    <Card.Title>{item.QuizModel.Title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      <FormattedMessage id="vl-pre"/> <br/>
                      Quiz ID: {item.QuizModel.Id} <br/>
                      <small>NPC: {item.QuizModel.LevelOfKnowledge}</small>
                    </Card.Subtitle>
                    <Card.Text>
                      {/* Something we can user for some text inside the card */}
                    </Card.Text>
                      <Link
                        key={index}
                        onClick={() => this.goToQuiz(item.QuizModel.Id)}
                        to={{pathname: "quiz-edit"}}>
                          <FormattedMessage id="qp-viewOrEdit"/>
                      </Link>
                  </Card.Body>
                </Card>
              )
            }) : <FormattedMessage id="vl-no-quizes"/> } </div> </div> : ""}

          {/* Exam */}
          {this.state.exams ?
            <div>
            <hr></hr>
            <h5><FormattedMessage id="vl-exams"/></h5>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "15px"}}>
            {this.state.exams.length > 0 ? this.state.exams.map( (item, index) => {
              return (
                <Card key={index} style={{ width: '18rem', marginTop: '5px'}}>
                  <Card.Body>
                    <Card.Title>{item.QuizModel.Title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      <FormattedMessage id="vl-exam"/> <br/>
                      Quiz ID: {item.QuizModel.Id} <br/> 
                      <small>NPC: {item.QuizModel.LevelOfKnowledge}</small>
                    </Card.Subtitle>
                    <Card.Text>
                      {/* Something we can user for some text inside the card */}
                    </Card.Text>
                      <Link
                        key={index}
                        onClick={() => this.goToQuiz(item.QuizModel.Id)}
                        to={{pathname: "quiz-edit"}}>
                          <FormattedMessage id="qp-viewOrEdit"/>
                      </Link>
                  </Card.Body>
                </Card>
              )
            }) : <FormattedMessage id="vl-no-quizes"/> } </div> </div> : ""}

          {/* Post Exams */}
          {this.state.postExams ?
            <div>
            <hr></hr>
            <h5><FormattedMessage id="vl-post"/></h5>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "15px"}}>
            {this.state.postExams.length > 0 ? this.state.postExams.map( (item, index) => {
              return (
                <Card key={index} style={{ width: '18rem', marginTop: '5px'}}>
                  <Card.Body>
                    <Card.Title>{item.QuizModel.Title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      <FormattedMessage id="vl-post"/> <br/>
                      Quiz ID: {item.QuizModel.Id} <br/>
                      <small>NPC: {item.QuizModel.LevelOfKnowledge}</small>
                    </Card.Subtitle>
                    <Card.Text>
                      {/* Something we can user for some text inside the card */}
                    </Card.Text>
                      <Link
                        key={index}
                        onClick={() => this.goToQuiz(item.QuizModel.Id)}
                        to={{pathname: "quiz-edit"}}>
                          <FormattedMessage id="qp-viewOrEdit"/>
                      </Link>
                  </Card.Body>
                </Card>
              )
            }) : <FormattedMessage id="vl-no-quizes"/> } </div> </div> : ""}
        </div>
        <br></br>

      </div>
    );
  }
}

export default CreateLessonPruebasSection;