//This call gives a list of available templates
//Examples(templateType: "EXA") or Definitions(templateType: "DEF")

import axios from "../interceptors";
import { apiGetTemplates } from "../../config";
import { logoutUser } from "../../utils/authHandling/logout-user";

export const getTemplates = async function (language, type) {
  try {
    const response = await axios.get(apiGetTemplates + "/" + type, {
      headers: {
        "Content-Type": "application/json",        
      },
    });
    if (response.status === 200 || response.status === 201) {
      //Session and transaction is valid, return the values
      return response.data.TemplateModelList;
    } else if (response.status === 401) {
      //session is not valid, user is sent to login
      alert("Session is not valid");
      logoutUser();
    }
  } catch (error) {
    console.log(error.response.data.Messages);
  }
};
