import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import axios from "../../server/interceptors";
import {
    apiAdditionalMaterialDeleteUrl,
    apiAdditionalMaterialModifyUrl,
    apiAdditionalMaterialSpecificUrl, DEFAULT_LANGUAGE
} from '../../config';
import { onRulesCall } from '../../server/getFileUploadRulesCall/file-upload-rules-call';
import { getLessonRules } from '../../server/getLessonRulesCall/get-lesson-rules';
import {Button, Container} from 'react-bootstrap';
import './edit-additional-material-page-style.css';
import AdditionalMaterialDisplayView from "../../components/additionalMaterialDisplayView";
import SessionManager from "../../utils/authHandling/session-manager";

class EditAdditionalMaterialPage extends Component {

    constructor(props){
        super(props);
        this.state={
            loading: false,
            additionalMaterialId: sessionStorage.lastSearchAdditionalMaterial,
            additionalMaterialSubject: "",
            additionalMaterialGrade: "",
            additionalMaterialTitle: "",
            additionalMaterialDescription: "",
            additionalMaterialCoverURL: "",
            additionalMaterialURL: "",
            additionalMaterialCoverURLSrc: "",
            additionalMaterialForDownload: "",
            redirect: false,
        };
        this.onSaveAdditionalMaterialCall = this.onSaveAdditionalMaterialCall.bind(this);
        this.onDeleteAdditionalMaterialCall = this.onDeleteAdditionalMaterialCall.bind(this);
        this.onChange = this.onChange.bind(this);
        this.redirectToEdit = this.redirectToEdit.bind(this);
        this.selectOption = this.selectOption.bind(this);
    }

    onChange(field, value){
        //Trick to add to state from multiple inputs with same function
        this.setState({[field]: value});
    }

    componentDidMount() {
        this.onAdditionalMaterialCall();
    }

    onAdditionalMaterialCall(){
        axios.get(`${apiAdditionalMaterialSpecificUrl}/${this.state.additionalMaterialId}`, {
            headers: {
                'Content-Type': 'application/json',                
            }}).then((response)=>{

            this.setState({
                additionalMaterialId: response.data.Id,
                additionalMaterialSubject: response.data.Subject,
                additionalMaterialGrade: response.data.Level,
                additionalMaterialTitle: response.data.Title,
                additionalMaterialDescription: response.data.Description,
                additionalMaterialCoverURL: response.data.UrlCover,
                additionalMaterialURL: response.data.Url,
                additionalMaterialCoverURLSrc:  response.data.UrlCover,
                additionalMaterialForDownload: response.data.ForDownload
            });

        }).catch(error => {
            console.log(error.response.data.Messages);
        });
    }

    onDeleteAdditionalMaterialCall(){
        this.setState({loading: true}, ()=>{
            // Call the API to insert

            axios.post(`${apiAdditionalMaterialDeleteUrl}/${this.state.additionalMaterialId}`, null,{
                headers: {
                    'Content-Type': 'application/json',                    
                }}).then((response)=>{

                if(response.status === 200)
                {
                    alert("Material successfully deleted./Material eliminado satisfactoriamente.");
                    this.redirectToEdit();
                }
                else
                {
                    alert("Validate the fields. If the error continues, contact the administrator./Valide los campos. Si el error persiste, contacte al administrador.");
                }
                this.setState({loading: false});
            }).catch(error => {
                alert("Error: " + error);
                this.setState({loading: false});
            });
        });
    }

    onSaveAdditionalMaterialCall(){

        // Validate
        if(this.state.additionalMaterialSubject === ""){
            alert("Error: Subject is required./La materia es requerida.");
            return;
        }
        if(this.state.additionalMaterialGrade === ""){
            alert("Error: The level is required./El grado es requerido.");
            return;
        }
        if(this.state.additionalMaterialTitle === "" || this.state.additionalMaterialDescription === ""){
            alert("Error: The title and the description are required./El título y la descripción son requeridos.");
            return;
        }
        if(this.state.additionalMaterialURL === ""){
            alert("Error: The link of the material is required./El enlace del material es requerido.");
            return;
        }

        this.setState({loading: true}, ()=>{
            // Call the API to insert
            let params = {
                Id: this.state.additionalMaterialId,
                Url: this.state.additionalMaterialURL,
                UrlCover: this.state.additionalMaterialCoverURL,
                Subject: this.state.additionalMaterialSubject,
                Level: this.state.additionalMaterialGrade,
                Title: this.state.additionalMaterialTitle,
                Description: this.state.additionalMaterialDescription,
                ForDownload: this.state.additionalMaterialForDownload
            };

            axios.post(apiAdditionalMaterialModifyUrl, params,{
                headers: {
                    'Content-Type': 'application/json',                    
                }}).then((response)=>{

                    if(response.status === 200)
                    {
                        alert("Material successfully saved./Material guardado satisfactoriamente.");
                    }
                    else
                    {
                        alert("Validate the fields. If the error continues, contact the administrator./Valide los campos. Si el error persiste, contacte al administrador.");
                    }
                this.setState({loading: false});
            }).catch(error => {
                alert("Error: " + error);
                this.setState({loading: false});
            });
        });
    }

    selectOption(value){
        onRulesCall().then((data) => {
            this.setState({
                fileRules: data
            });
        });
        getLessonRules(value).then((data) => {
            this.setState({
                lessonAddress: value,
                lessonAddressSelected: true,
                lessonRules: data
            })
        })
    }

    redirectToEdit(){
        this.setState({redirectToEdit: true})
    }

    render(){

        if (this.state.redirectToEdit) {
            return <Redirect to='/additional-material' />
        }
        return(
            <Container fluid className="card-alt">
              <div>

                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <h2><FormattedMessage id="am-title"/></h2>
                            {this.state.loading ?
                                <div>
                                    <Button disabled variant="danger">
                                        <FormattedMessage id="am-delete"/>
                                    </Button>{' '}
                                    <Button disabled variant="success">
                                        <FormattedMessage id="am-save"/>
                                    </Button>
                                </div>:
                                <div>
                                <Button variant="danger" onClick={this.onDeleteAdditionalMaterialCall}>
                                    <FormattedMessage id="am-delete"/>
                                </Button>{' '}
                                    <Button variant="success" onClick={this.onSaveAdditionalMaterialCall}>
                                        <FormattedMessage id="am-save"/>
                                    </Button>
                                </div>
                            }

                        </div>

                        <AdditionalMaterialDisplayView
                            isCreation={true}
                            language={this.props.language}
                            additionalMaterialContent={this.state}
                            onChange={this.onChange}
                        />
                    </div>
            </Container>
        );
    }
}

export default EditAdditionalMaterialPage;